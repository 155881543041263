import { RoomListItemOutPutDTO } from "src/app/core/chat/usecase/room/room-get-list-use-case.service";
import { RoomEntity } from "../entity/group";

export class GetRoomListMapper{

  // @captureAndReraiseAsync('GetRoomListMapper/toDomain')
  static toDomain(inputs: RoomListItemOutPutDTO[]): RoomEntity[] {

    return inputs.map((roomData) => new RoomEntity({
      createdAt: roomData.chatRoom.createdAt,
      createdBy: roomData.chatRoom.createdBy,
      expirationDate: roomData.chatRoom.expirationDate,
      id: roomData.chatRoom.id,
      roomName: roomData.chatRoom.roomName,
      roomType: roomData.chatRoom.roomType,
      members: [ roomData.chatRoom.user2, roomData.chatRoom.user1].filter((e) => e?.wxUserId).map((b) => ({
        id: '',
        isAdmin: false,
        joinAt: '',
        user: {
          userPhoto: b.userPhoto,
          wxeMail: b.wxeMail,
          wxFullName: b.wxFullName,
          wxUserId: b.wxUserId
        }
      })),
      messages: roomData.chatRoom.messages,
    }));

  }
}
