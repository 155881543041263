import { Injectable } from '@angular/core';
import { z } from "zod";
import { IMemberRemoteRepository } from 'src/app/core/chat/repository/member/member-remote-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { isHttpResponse } from 'src/app/infra/http/http.service';

// Define the schema for the entire response
export const UserRemoveListInputDTOSchema = z.object({
  id: z.string(),
  members: z.array(z.number())
});

export type UserRemoveListInputDTO = z.infer<typeof UserRemoveListInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class RoomLeaveUseCase {

  constructor(
    private memberRemoteDataSourceService: IMemberRemoteRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) { }



  async execute(data: UserRemoveListInputDTO) {
    const result = await this.memberRemoteDataSourceService.removeMemberFromRoom(data)

    if(result.isOk()) {
      this.roomLocalDataSourceService.delete(data.id)
    } else if (isHttpResponse(result.error)) {
      if(result.error.status == 404) {
        await this.roomLocalDataSourceService.delete(data.id)

      }
      // this.httpErrorHandle.httpStatusHandle(result.error)
    }

    return result
  }
}
