import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { z } from 'zod';

const RoomDirectOnSetIdUseCaseInputSchema = z.object({
  $roomId: z.string()
})

export type RoomDirectOnSetIdUseCaseInputSchema = z.infer<typeof RoomDirectOnSetIdUseCaseInputSchema>

@Injectable({
  providedIn: 'root'
})
export class RoomDirectOnSetIdUseCaseService {

  constructor(
    private roomLocalRepository: IRoomLocalRepository
  ) { }

  execute(input: RoomDirectOnSetIdUseCaseInputSchema) {

    return this.roomLocalRepository.OnSetIdToDirectRoom().pipe(
      filter((data)=> data?.$id == input.$roomId)
    )
  }
}
