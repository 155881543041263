import { DataSourceReturn } from "src/app/services/Repositorys/type";
import { Result } from "neverthrow";
import { AddMemberToRoomInputDTO } from "src/app/core/chat/usecase/member/member-add-use-case.service";
import { MemberSetAdminDTO } from "src/app/core/chat/usecase/member/member-admin-use-case.service";
import { UserRemoveListInputDTO } from "src/app/core/chat/usecase/room/room-leave-by-id-use-case.service";

export abstract class IMemberRemoteRepository  {
  abstract addMemberToRoom(data: AddMemberToRoomInputDTO): DataSourceReturn<AddMemberToRoomInputDTO>

  abstract removeMemberFromRoom(data: UserRemoveListInputDTO): Promise<Result<any ,any>>
  abstract setAmin(data: MemberSetAdminDTO): Promise<Result<any ,any>>
}
