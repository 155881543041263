import { Injectable } from '@angular/core';
import { z } from 'zod';
import { Dexie, EntityTable, liveQuery, Observable } from 'Dexie';
import { err, ok } from 'neverthrow';
import { chatDatabase } from 'src/app/infra/database/dexie/instance/chat/service';
import { TypingTable, TypingTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/typing';
import { ITypingLocalRepository } from 'src/app/core/chat/repository/attachment/attachment-local-repository';
import { DexieRepository } from 'src/app/infra/repository/dexie/dexie-repository.service';



@Injectable({
  providedIn: 'root'
})
export class UserTypingLocalRepository extends DexieRepository<TypingTable, TypingTable> implements ITypingLocalRepository {

  constructor() { 
    super(chatDatabase.typing, TypingTableSchema)
    this.clear();
  }


  async clear() {
    try {
      const result = await chatDatabase.typing.clear()
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }

  async addUserTyping(data: TypingTable) {

    data.id = data.roomId + '@' + data.userName
    try {
      const result = await chatDatabase.typing.add(data)
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }

  async removeUserTyping(data: TypingTable) {

    const id = data.roomId + '@' + data.userName
    try {
      const result = await chatDatabase.typing.delete(id)
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }


  getUserTypingLive() {
    return liveQuery(() => chatDatabase.typing.toArray());
  }

  getUserTypingLiveByRoomId(roomId: string) {
    return liveQuery(() =>  chatDatabase.typing
                            .where('roomId')
                            .equals(roomId)
                            .sortBy('id')
    );
  }
}
