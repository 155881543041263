import { Injectable } from '@angular/core';
import { z } from 'zod';
import { AgendaDataService } from '../../data/data-source/agenda-data.service';

export const GetDraftListByProcessIdSchema = z.object({
  processId: z.number()
});
export type IGetDraftListByProcessIdSchema = z.infer<typeof GetDraftListByProcessIdSchema>

//=======================================


const UserSchema = z.object({
  wxUserId: z.number(),
  createdAt: z.string(),
});
const VersionSchema = z.object({
  versionID: z.number(),
  versionNumber: z.number(),
  createdBy: z.number(),
  editDuration: z.number(),
  content: z.string(),
  createdDate: z.string(),
  updatedDate: z.string(),
});
export const GetDraftListByProcessIdOutputSchema = z.object({
  success: z.boolean(),
  message: z.string().nullable(),
  data: z.array(z.object({
    id: z.number(),
    description: z.string(),
    path: z.string(),
    nnc: z.string().nullable(),
    status: z.boolean(),
    content: z.string(),
    wxDoctypeId: z.string().nullable(),
    ownerId: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    serialNumber: z.string().nullable(),
    folderId: z.number(),
    users: z.array(UserSchema),
    version: z.array(VersionSchema),
  })),
});

export type IGetDraftListByProcessIdOutput = z.infer<typeof GetDraftListByProcessIdOutputSchema>
@Injectable({
  providedIn: 'root'
})
export class GetDraftListByProcessIdService {

  constructor(
    private agendaDataService: AgendaDataService
  ) { }

  async execute(input: IGetDraftListByProcessIdSchema) {
    return await this.agendaDataService.getDraftListByProcessId(input)
  }
}
