export class UserForm {
  username: string;
  password: string;
  domainName: string;
  BasicAuthKey: string;
}


export class  calendarInterface {
  CalendarId: string
  CalendarName: "Oficial" | "Pessoal";
  CalendarRoleId: string;
  Id: number;
  OwnerUserId: any
}

export class UserSession {
  UserId: number;
  Authorization: string;
  Email: string
  FullName: string
  RoleDescription: string
  RoleID: number
  Password: string
  RochetChatUserId: string
  Profile: 'PR' | 'MDGPR' | 'Consultant' | 'SGGPR' | 'Unknown'  ;
  LoginPreference: 'None' | 'Password' | 'Pin' | null;
  PIN: string
  Inactivity: boolean
  UrlBeforeInactivity: string;
  UserPermissions: any;
  UserPhoto: string;
  RefreshToken: string
}