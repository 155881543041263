import { Injectable } from '@angular/core';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { z } from 'zod';

const RoomGetLocalByIdServiceInputSchema = z.object({
  $roomId: z.string()
})

export type IRoomGetLocalByIdServiceInput = z.infer<typeof RoomGetLocalByIdServiceInputSchema >
@Injectable({
  providedIn: 'root'
})
export class RoomGetLocalByIdService {

  constructor(
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) { }

  async execute(input: IRoomGetLocalByIdServiceInput) {
    return await this.roomLocalDataSourceService.findOne({$id: input.$roomId})
  }
}
