import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { UserSession } from '../models/user.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { Publication } from '../models/publication';
import { getUrl } from 'ionicons/dist/types/components/icon/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStore } from '../store/session.service';
import { ChangeProfileService } from './change-profile.service';

@Injectable({
  providedIn: 'root'
})
export class PublicationsService  {



  authheader = {};
  loggeduser: UserSession;
  headers: HttpHeaders;

  constructor(private http: HttpClient, user: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeProfileService: ChangeProfileService,) {

      this.setHeader()
      this.changeProfileService.registerCallback(() => {
        this.setHeader()
      })

  }

  setHeader () {
    this.loggeduser = SessionStore.user;
    this.headers = new HttpHeaders();;
    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  }

  GetPublicationFolderList(){
    const geturl = environment.apiURL + 'presidentialActions';
    let options = {
      headers: this.headers,
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  GetPresidentialAction(id:any){
    const geturl = environment.apiURL + 'presidentialActions/'+id;

    let options = {
      headers: this.headers,
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  UpdatePresidentialAction(body:any) {
    const geturl = environment.apiURL + 'presidentialActions';

    let options = {
      headers: this.headers,
    };
    return this.http.put<any>(`${geturl}`, body, options);
  }

  CreatePublicationFolder(body:any){
    const geturl = environment.apiURL + 'presidentialActions';
    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  UpdatePublicationFolder(body:any) {
    const geturl = environment.apiURL + 'presidentialActions';

    let options = {
      headers: this.headers,
    };

    return this.http.put<any>(`${geturl}`, body, options).toPromise().then(res =>{

    });
  }

  DeletePresidentialAction(id:any){
    const geturl = environment.apiURL + 'presidentialActions/'+id;

    let options = {
      headers: this.headers,
    };
    return this.http.delete<any>(`${geturl}`, options);
  }

  GetPublicationsList(folder:any) {
    const geturl = environment.apiURL + 'presidentialActions/'+ folder +'/posts/ids';
    let params = new HttpParams();

    params = params.set("folderId", folder);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<number[]>(`${geturl}`, options)

  }

  GetPublications(id:any){
    const geturl = environment.apiURL + 'presidentialActions/'+ id +'/posts';
    let params = new HttpParams();

    params = params.set("folderId", id);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<Publication[]>(`${geturl}`, options)
  }

  // this one too,goes to observable as a first  api call
  GetIdsPublicationsImages(id:any) {
    const geturl = environment.apiURL + 'presidentialActions/'+ id +'/posts/ids';
    let params = new HttpParams();

    params = params.set("folderId", id);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<number[]>(`${geturl}`, options)
  }


  GetPublicationById( publicationId:any){
    const geturl = environment.apiURL + 'presidentialActions/posts/'+ publicationId;
    let params = new HttpParams();

    params = params.set("id", publicationId);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  GetPublicationWithArrayOfFilesById( publicationId:any){
    const geturl = environment.apiURL + 'presidentialActions/v2/posts/'+ publicationId;
    let params = new HttpParams();

    params = params.set("id", publicationId);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

// my own tries

GetPublicationByIdNext( publicationId:any){
  let geturl = environment.apiURL + 'presidentialActions/'+ publicationId + '/posts/ids';
  let params = new HttpParams();

  params = params.set("folderId", publicationId);

  let options = {
    headers: this.headers,
    /* params: params */
  };

  const imageLoads = !!(+localStorage.getItem('loadedimage'))
  if(imageLoads){
    return true
  }else{
    const navigation = this.router.getCurrentNavigation()


    if(navigation){
      geturl = navigation.extractedUrl.toString()
    }

  this.router.navigate([URL], {queryParams: {returnto: geturl}})

  }
}

GetIdsPublicationNext(id:any){
  let geturl = environment.apiURL + 'presidentialActions/posts/' + id;
  let params = new HttpParams();

  params = params.set("id", id);

  let options = {
    headers: this.headers,
    params: params
  };

 var search = this.http.get<any>(`${geturl}`, options).subscribe(
   res => {
     res.this.activatedRoute.snapshot.queryParams.get('returnto') || '/posts'

    }

 )

 return search

}

//my last tries

  CreatePublication(folderId:any,body:any){


    console.log('body publi', body)
    const geturl = environment.apiURL + 'presidentialActions/'+folderId+'/v2/posts';
    let params = new HttpParams();
    params = params.set("folderId", folderId);
    let options = {
      headers: this.headers,
     /*  params: params */
    };

    return this.http.post<any>(`${geturl}`, body, options)
  }

  UpdatePublication(folderId:any,body:any){
    const geturl = environment.apiURL + 'presidentialActions/'+folderId+'/posts';
    let params = new HttpParams();
    params = params.set("folderId", folderId);
    let options = {
      headers: this.headers,
      /* params: params */
    };



    body.Files = body.Files.map( e => ({
      FileBase64: e.FileBase64,
      FileExtension: e.FileExtension,
      OriginalFileName: e.OriginalFileName || 'foto'
    }))

    return this.http.put<any>(`${geturl}`, body, options)
  }

  DeletePublication(folderId:any,publicationId:any){
    const geturl = environment.apiURL + 'presidentialActions/'+folderId+'/posts/'+publicationId;
    let params = new HttpParams();
    params = params.set("folderId", folderId);
    params = params.set("id", publicationId);
    let options = {
      headers: this.headers,
      /* params: params */
    };
    return this.http.delete(`${geturl}`, options)
  }



  handleError(error){
    return throwError(error || 'Server Error');
  }



}


