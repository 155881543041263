import { DataSourceReturn } from "src/app/services/Repositorys/type";
import { IMessageGetAllByRoomIdOutPut } from "../../usecase/message/message-get-all-by-room-Id";
import { CreateRoomInputDTO, RoomOutPutDTO } from "src/app/core/chat/usecase/room/room-create-use-case.service";
import { RoomByIdInputDTO, RoomByIdOutputDTO } from "src/app/core/chat/usecase/room/room-get-by-id-use-case.service";
import { RoomUpdateInputDTO, RoomUpdateOutputDTO } from "src/app/core/chat/usecase/room/room-update-by-id-use-case.service";
import { Result } from "neverthrow";
import { AddMemberToRoomInputDTO } from "src/app/core/chat/usecase/member/member-add-use-case.service";
import { RoomListItemOutPutDTO, RoomListItemSchema, RoomListOutPutDTO } from "src/app/core/chat/usecase/room/room-get-list-use-case.service";


export interface ISearchRoom {

  success: boolean,
  message: any,
  data: {
    messages: [],
    rooms: RoomListItemSchema[]
  }

}
export abstract class IRoomRemoteRepository  {
  abstract createRoom(data: CreateRoomInputDTO): DataSourceReturn<RoomOutPutDTO>
  abstract getRoomList(): Promise<DataSourceReturn<RoomListOutPutDTO>>
  abstract getRoom(id: RoomByIdInputDTO): DataSourceReturn<RoomByIdOutputDTO>
  abstract updateRoom(data: RoomUpdateInputDTO): Promise<DataSourceReturn<RoomUpdateOutputDTO>>
  abstract deleteRoom(id: string): Promise<Result<any ,any>>
  abstract addMemberToRoomSocket(data: AddMemberToRoomInputDTO): Promise<Result<any ,any>>
  abstract search(input: string): Promise<Result<RoomListItemOutPutDTO[], any>>
}
