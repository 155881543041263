import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { ThemeService } from 'src/app/services/theme.service';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { register } from 'swiper/element/bundle';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { SessionStore } from './store/session.service';
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class AppComponent {
  tabIsActive = true
  
  constructor(
    private platform: Platform,
      private statusBar: StatusBar,
      public ThemeService: ThemeService,
      private storage: Storage,
      private sanitizer: DomSanitizer,
      private screenOrientation: ScreenOrientation,
  ) {

    window["sanitizer"] = this.sanitizer
    this.initializeApp();
    this.storage.set('version', environment.version).then(() => {})

    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then(registration => {
            console.log('Service Worker registrado com sucesso:', registration);


            // Send data to the service worker
            if (registration.active) {
              registration.active.postMessage({ type: 'INIT_WEBSOCKET', url: 'wss://example.com/socket' });
            } else {
              navigator.serviceWorker.ready.then(registration => {
                registration.active.postMessage({ type: 'INIT_WEBSOCKET', url: 'wss://example.com/socket' });
              });
            }
          })
          .catch(error => {
            console.error('Erro ao registrar o Service Worker:', error);
          });
      }
    }


    window.addEventListener('focus', (event) => {
      if (!this.tabIsActive) {
        this.tabIsActive = true
        const data = SessionStore.getDataFromLocalStorage();

        if (!data?.user?.Authorization && SessionStore?.user?.Authorization) {
          window.location.reload();
        }

        if (window['all-process-gabinete']) {
          window['all-process-gabinete']()
        }
      }
    });

    window.addEventListener('blur', (event) => {
      this.tabIsActive = false
    });


  }

/*   requestPermission() {
    this.afMessaging.requestToken.subscribe(
      (token) => {
        // Save the token to your server for sending notifications
        console.log('Permission granted! Token:', token);
      },
      (error) => {
        console.error('Permission denied:', error);
      }
    );
  }

  receiveMessages() {
    this.afMessaging.messages.subscribe((message) => {
      console.log('Received message:', message);
      // Handle the received message, e.g., show a notification
    });
  } */

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      if (this.platform.is("tablet")) {
        window.screen.orientation.unlock();
      } else if( this.platform.is("mobile")) {
        if(this.platform.is('ios')){
          this.screenOrientation.lock('portrait')
        } else {
          (window.screen.orientation as any).lock('portrait');
        }
      }
    });
  }
}



window.onerror = function(message, source, lineno, colno, error) {
  if (error) message = error.stack;
  console.log('send', 'event', 'window.onerror', message, navigator.userAgent);
}
// ============================================================================
