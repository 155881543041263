/* eslint-disable */
/* tslint:disable */
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { Tokenn } from '../models/token.model';
import { AlertController, Platform } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { EventTrigger } from '../services/eventTrigger.service';
import { SessionStore } from '../store/session.service';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token, } from '@capacitor/push-notifications';
import { notificationObject } from '../models/notifications';
import { Capacitor } from '@capacitor/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { NotificationHolderService } from 'src/app/store/notification-holder.service';
import { FCM } from '@capacitor-community/fcm';
import { TracingType, XTracer, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { z } from 'zod';
import { NotificationRepositoryService } from 'src/app/module/notification/data/notification-repository.service'
const notificationDataSchema = z.object({
  Service: z.string(),
  IdObject: z.string().optional(),
  Object: z.string().optional(),
  FolderId: z.string().optional(),
});

const notificationSchema = z.object({
  notification: z.object({
    data: notificationDataSchema,
  }),
});

// import { ChatSystemService } from './chat/chat-system.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  adding: "intervenient" | "CC" = "intervenient";
  folderId: string;
  DataArray = new Array();



  callbacks: {
    [key: string]: {
      type: notificationObject,
      funx: Function
      id: string
    }
  } = {}

  active = false
  isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
  notificationReceived: EventEmitter<void> = new EventEmitter<void>();
  token = ''

  constructor(
    private http: HttpClient,
    public modalCtrl: AlertController,
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private afMessaging: AngularFireMessaging,
    public NotificationHolderService: NotificationHolderService) {

    // this.onReciveForeground();
    this.onReciveBackground();

  }

  getTokenByUserIdAndId(user, userID) {
    // const geturl = environment.apiURL + 'notifications/user/' + userID;

    // return this.http.get<Tokenn[]>(`${geturl}`);
  }

  requestPermissions() {
    console.log('init notificaton permission')

    if (this.platform.is('mobile')) {
      if (!this.isPushNotificationsAvailable) {
        return false
      }
      PushNotifications.requestPermissions().then(async (result) => {
        if (result.receive === 'granted') {
          console.log('permission granted')
          // Register with Apple / Google to receive push via APNS/FCM
          try {
            PushNotifications.register().then((value) => {
              console.log("Register device", value)
              this.getAndpostToken("")
            }) .catch((error) => {
              console.log("Register device error", error)
            })

          } catch (error) {
            console.log("Granted permission error", error)
          }
        } else {
          // Show some error
          console.log('permission notification erro')
        }
      });
    } else {
      this.getAndpostToken("")
    }
  }

  @XTracerAsync({name:'Notification/getAndpostToken', bugPrint: true, module:'notification', autoFinish: false})
  private getAndpostToken(username, tracing?: TracingType) {
    const geturl = environment.apiURL + 'notifications/token';

    if (this.platform.is('mobile')) {
      if (!this.isPushNotificationsAvailable) {
        tracing.setAttribute('notification.available', 'false')
        tracing.setAttribute('outcome', 'failed')
        return false
      }
      if (this.platform.is('ios')) {
        FCM.getToken()
          .then(r => {
            tracing.setAttribute('notification.token', 'true')
            this.postToken(r.token, geturl, tracing)
            this.token = r.token
            // alert(this.token)
          })
          .catch(err => {
            console.log(err)
            tracing.setAttribute('notification.token', 'false')
            tracing.setAttribute('outcome', 'failed')
          });
      } else {

        PushNotifications.addListener('registration',
          (token: Token) => {

            tracing.setAttribute('notification.token', 'true')
            this.postToken(token.value, geturl, tracing)
            this.token = token.value

          }
        );
      }

    } else {
      tracing.setAttribute('notification.request', 'true')
      this.afMessaging.requestToken.subscribe(
        (token) => {
          // Save the token to your server for sending notifications
          console.log('Permission granted! Token:', token);
          this.postToken(token, geturl, tracing)
          this.token = token
          tracing.setAttribute('notification.token', 'true')
          tracing.setAttribute('outcome', 'success')
        },
        (error) => {
          
          tracing.setAttribute('notification.token', 'false')
          tracing.hasError('Permission denied: request token');
        }
      );
    }
  }


  DeletePostToken() {
    if(this.token) {
      const geturl = environment.apiURL + `notifications/token?userId=${SessionStore.user.UserId}&tokenId=${this.token}`;

      this.DeleteToken(geturl)
    }
    
  }

  postToken(token, geturl, tracing: TracingType) {
    //const headers = { 'Authorization': SessionStore.user.BasicAuthKey };
    const body = {
      UserId: SessionStore.user.UserId,
      TokenId: token,
      Status: 1,
      Service: 1
    };

    tracing.setAttribute('token.data', token)
    this.http.post<Tokenn>(`${geturl}`, body, {  }).subscribe(data => {
      this.active = true
      tracing.setAttribute('outcome','success')
      tracing.finish()
    }, (error) => {
      tracing.setAttribute('postToken','failed')
      tracing.setAttribute('outcome','failed')
      tracing.finish()
    })
  }


  DeleteToken(geturl) {

    const body = {};

    this.http.delete<Tokenn>(`${geturl}`, { body }).subscribe(data => {
      this.active = true
    }, (error) => {

    })
  }


  registrationError() {
    if (!this.isPushNotificationsAvailable) {
      return false
    }

    PushNotifications.addListener('registrationError',
      (error: any) => {
        this.active = false;

        (() => {
          throw ("error" + error.error)
        })();
      }
    );
  }

  onReciveForeground() {

    if (this.platform.is('mobile')) {

      if (!this.isPushNotificationsAvailable) {
        return false
      }
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          this.active = true
          console.log('NOtification Listener', notification)
          this.storenotification(notification)
        }
      );

    } else {
      this.afMessaging.messages.subscribe((notification) => {
        console.log(notification)
        this.storenotification(notification)
        this.notificationReceived.emit();
        // this.eventtrigger.publishSomeData({
        //   notification: "recive"
        // })
        // Handle the received message, e.g., show a notification
      });
    }
  }


  storenotification(notification) {

    this.NotificationHolderService.addNotification(notification)
  }

  onReciveBackground() {

    if (this.platform.is('mobile')) {
      if (!this.isPushNotificationsAvailable) {
        return false
      }

      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          this.active = true
          console.log('NOtification Listener Backgroud', notification)

          /*  this.DataArray.push(notification.notification)

           this.storageService.store("Notifications", this.DataArray)
           this.eventtrigger.publishSomeData({
             notification: "recive"
           }) */
          this.notificatinsRoutes(notification)
          // this.runNotificationCallback(notification)
        }
      );
    } else {
      try {

        navigator.serviceWorker.onmessage = (event) => {
          console.log('Mensagem recebida do Service Worker:', event.data);
          let object = {
            notification: event.data
          }

          if (event.data.notificationClicked) {
            console.log('Notificação push do Firebase clicada em segundo plano!');
            this.notificatinsRoutes(object)
          }
        };
      } catch(e) {
        console.log(e)
      }
    }

  }

  // sendNotificationWithSend(userID, title, bodymsg, roomId) {
  //   const geturl = environment.apiURL + `notifications/send`;


  //   const message = {
  //     "Service": "chat",
  //     "IdObject": roomId
  //   }
  //   let id = 437
  //   this.http.post<Tokenn>(geturl + `?userId=${userID}&title=${title}&body=${bodymsg}`, message, {  }).subscribe(data => {
  //     this.active = true
  //   }, (error) => {

  //   })
  // }


  // ChatSendMessageNotification(userID, title, bodymsg, roomId) {
  //   const geturl = environment.apiURL + `notifications/sendbyUsername`;


  //   const message = {
  //     "Service": "chat",
  //     "IdObject": roomId
  //   }
  //   let id = 437
  //   this.http.post<Tokenn>(geturl + `?username=${userID}&title=${title}&body=${bodymsg}`, message, { headers }).subscribe(data => {
  //     this.active = true
  //   }, (error) => {

  //   })
  // }

  // ChatSendMessageNotificationGrup(usersID, title, bodymsg, roomId) {
  //   const geturl = environment.apiURL + `notifications/sendByUsernames`;

  //   const headers = { 'Authorization': SessionStore.user.BasicAuthKey };
  //   const message = {
  //     "Users": usersID,
  //     "NotificationData": {
  //       "Service": "chat",
  //       "IdObject": roomId
  //     }
  //   }

  //   this.http.post<Tokenn>(geturl + `?title=${title}&body=${bodymsg}`, message, { headers }).subscribe(data => {
  //     this.active = true
  //   }, (error) => {

  //   })
  // }

  tempClearArray(data) {
    this.DataArray = new Array;
  }


  @XTracer({name:'phone-notification/notificationRoutes', bugPrint: true, module:'notification'})
  notificatinsRoutes (notification, tracing?: TracingType) {

    try {

      console.log('BACK BACK 12', notification)

      if (notification.notification.data.service === "agenda" ) {
        const data = JSON.parse(notification.notification.data.item)
        if(data.status == 1) {
          this.zone.run(() => this.router.navigate(['/home/agenda/event-list/approve-event', notification.notification.data.idObject, 'agenda']));
        } else {
          this.zone.run(() => this.router.navigate(['/home/agenda', notification.notification.data.idObject, 'agenda']));
        }

        this.zone.run(() => this.router.navigate(['/home/agenda', notification.notification.data.idObject, 'agenda']));
      }
      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "expedientes") {
        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/expediente', notification.notification.data.idObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.service === "agenda" && notification.notification.data.object === "event-list") {
        //this.zone.run(() => this.router.navigate(['/home/gabinete-digital/event-list/approve-event',IdObject, 'agenda']));
        this.zone.run(() => this.router.navigate(['/home/agenda/event-list/approve-event', notification.notification.data.idObject, 'agenda']));

      } else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "despachos") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/despachos', notification.notification.data.idObject, 'gabinete-digital'], { replaceUrl: true }));
      }
      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "parecer") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/pedidos', notification.notification.data.idObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "deferimento") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/pedidos', notification.notification.data.idObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "despachos-pr") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/despachos-pr', notification.notification.data.idObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.service === "accoes" && notification.notification.data.object === "accao") {
        this.zone.run(() => this.router.navigate(['/home/publications', notification.notification.data.idObject]));
      }
      else if (notification.notification.data.service === "accoes" && notification.notification.data.object === "publicacao") {
        this.zone.run(() => this.router.navigate(['/home/publications/view-publications', notification.notification.data.FolderId, notification.data.IdObject]));
      }
      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "diplomas") {
        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/diplomas', notification.notification.data.idObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "diplomas-assinar") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/diplomas-assinar', notification.notification.data.idObject, 'gabinete-digital']));
      }

      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "diploma-revisao") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/diplomas', notification.notification.data.idObject, 'gabinete-digital']));
      }

      else if (notification.notification.data.service === "gabinete-digital" && notification.notification.data.object === "expedientes-pr") {
        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/expedientes-pr', notification.notification.data.idObject, 'gabinete-digital']));
      } else if (notification.notification.data.service === "chat") {

        let navigationExtras: NavigationExtras = { queryParams: { "roomId": notification.notification.data.idObject, } };

        if (window.location.search.includes("roomId")) {
          this.zone.run(() => this.router.navigate(['/home/chat']));
        }

        setTimeout(() => {
          this.zone.run(() => this.router.navigate(['/home/chat'], navigationExtras));
        }, 200);
      } // =================================================================================================================
      else if (notification.notification.data.Service === "agenda" && notification.notification.data.IdObject.length > 10) {

        this.zone.run(() => this.router.navigate(['/home/agenda', notification.notification.data.IdObject, 'agenda']));
      }
      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "expedientes") {
        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/expediente', notification.notification.data.IdObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.Service === "agenda" && notification.notification.data.Object === "event-list") {
        //this.zone.run(() => this.router.navigate(['/home/gabinete-digital/event-list/approve-event',IdObject, 'agenda']));
        this.zone.run(() => this.router.navigate(['/home/agenda/event-list/approve-event', notification.notification.data.IdObject, 'agenda']));

      } else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "despachos") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/despachos', notification.notification.data.IdObject, 'gabinete-digital'], { replaceUrl: true }));
      }
      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "parecer") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/pedidos', notification.notification.data.IdObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "deferimento") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/pedidos', notification.notification.data.IdObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "despachos-pr") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/despachos-pr', notification.notification.data.IdObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.Service === "accoes" && notification.notification.data.Object === "accao") {
        this.zone.run(() => this.router.navigate(['/home/publications', notification.notification.data.IdObject]));
      }
      else if (notification.notification.data.Service === "accoes" && notification.notification.data.Object === "publicacao") {
        this.zone.run(() => this.router.navigate(['/home/publications/view-publications', notification.notification.data.FolderId, notification.data.IdObject]));
      }
      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "diplomas") {
        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/diplomas', notification.notification.data.IdObject, 'gabinete-digital']));
      }
      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "diplomas-assinar") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/diplomas-assinar', notification.notification.data.IdObject, 'gabinete-digital']));
      }

      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "diploma-revisao") {

        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/diplomas', notification.notification.data.IdObject, 'gabinete-digital']));
      }

      else if (notification.notification.data.Service === "gabinete-digital" && notification.notification.data.Object === "expedientes-pr") {
        this.zone.run(() => this.router.navigate(['/home/gabinete-digital/expedientes-pr', notification.notification.data.IdObject, 'gabinete-digital']));
      } else if (notification.notification.data.Service === "chat") {

        let navigationExtras: NavigationExtras = { queryParams: { "roomId": notification.notification.data.IdObject, } };

        if (window.location.search.includes("roomId")) {
          this.zone.run(() => this.router.navigate(['/home/chat']));
        }

        setTimeout(() => {
          this.zone.run(() => this.router.navigate(['/home/chat'], navigationExtras));
        }, 200);
      } else {
        tracing.setAttribute('notification.route', 'false')
        tracing.setAttribute('outcome', 'failed')
        tracing.setAttribute('parameters', JSON.stringify(notification))
      }
    } catch (error) {

      const validationError = notificationSchema.safeParse(notification)

      if(!validationError.success) {
        const errors: z.ZodError<any> = (validationError as any).error;
        console.log("Validation errors:", errors.issues);
        tracing.setAttribute('validation.errors', JSON.stringify(errors.issues))
      }

      tracing.setAttribute('outcome', 'failed')
      tracing.setAttribute('parameters', JSON.stringify(notification))
      tracing.setAttribute('error', JSON.stringify(error))
    }


    // (() => {
    //   this.zone.run(() => this.NotificationHolderService.PhoneClickNotification(notification));
    // })()
  }


}
