import { HubConnection } from "@microsoft/signalr";
import { Result } from "neverthrow";
import { BehaviorSubject, Observable } from "rxjs";
import { ListenToDeleteRoomInput, RoomSocketOutPutDTO } from "src/app/module/chat/data/repository/room/room-socket-repository.service";
import { CreateRoomInputDTO } from "src/app/core/chat/usecase/room/room-create-use-case.service";
import { z } from "zod";


const SocketRoomUpdateOutPutSchema = z.object({
  id: z.string().uuid(),
  roomName: z.string().min(1),
  createdBy: z.string().nullable(),  // Allowing null for createdBy
  createdAt: z.string().datetime(),
  expirationDate: z.string().nullable().optional(),  // Allowing null and making it optional
  roomType: z.number(),
  user1: z.string().nullable().optional(),  // Allowing null for user1
  user2: z.string().nullable().optional(),  // Allowing null for user2
  messages: z.array(z.any()),  // Allowing an array of any type for messages
});

export type SocketRoomUpdateOutPut = z.infer<typeof SocketRoomUpdateOutPutSchema>

export abstract class IRoomSocketRepository {


  abstract CreateGroup(data: CreateRoomInputDTO): Promise<Result<RoomSocketOutPutDTO,any>>

  abstract listenToCreateRoom(): Observable<any>
  abstract listenToDeleteRoom(): Observable<ListenToDeleteRoomInput>
  abstract listenToRoomUpdate(): Observable<{method: string, data: SocketRoomUpdateOutPut}>


}
