import { z } from "zod";
import { base64Schema } from "src/app/utils/zod";
import { SessionStore } from "src/app/store/session.service";

export enum MessageAttachmentSource {
  Webtrix = 1,
  Device,
}

export enum MessageAttachmentFileType {
  Doc = 1,
  Image ,
  Audio ,
  Video
}

export enum IMessageType {
  normal = 1,
  information
}

export const MessageEntityAttachmentSchema = z.object({
  fileType: z.nativeEnum(MessageAttachmentFileType),
  source: z.nativeEnum(MessageAttachmentSource),
  file: base64Schema.optional(),
  fileName: z.string().optional(),
  applicationId: z.number().optional(),
  docId: z.number().optional(),
  id: z.string().optional().nullable(),
  mimeType: z.string().nullable().optional(),
  safeFile: z.any().optional(),
  description: z.string().nullable().optional(),
})

export const MessageEntitySchema = z.object({
  $id: z.any().optional(),
  id: z.string().uuid().optional(),
  roomId: z.string().optional(),
  receiverId: z.number().optional(),
  message: z.string().nullable().optional(),
  messageType: z.nativeEnum(IMessageType),
  canEdit: z.boolean(),
  oneShot: z.boolean(),
  sentAt: z.string().optional(),
  requireUnlock: z.boolean(),
  isDeleted: z.boolean().optional(),
  editedAt: z.string().nullable().optional(),
  sender: z.object({
    wxUserId: z.number(),
    wxFullName: z.string(),
    wxeMail: z.string(),
    userPhoto: z.string().nullable(),
  }).nullable(),
  reactions: z.object({
    id: z.string(),
    reactedAt: z.string(),
    reaction: z.string(),
    sender: z.object({}),
  }).array().optional(),
  info: z.array(z.object({
    memberId: z.number(),
    readAt: z.string().nullable(),
    deliverAt: z.string().nullable()
  })).optional(),
  sending: z.boolean().optional(),
  attachments: z.array(MessageEntityAttachmentSchema).optional(),
  origin: z.enum(['history', 'local', 'incoming']).optional(),
  requestId: z.string().nullable().optional(),
  sendAttemp: z.number().optional(),
  hasAttachment: z.boolean().optional()
})

export type IMessage = z.infer<typeof MessageEntitySchema>;

export class MessageEntity {

  $id?: string
  id?: string
  roomId?: string
  receiverId?: number
  message?: string
  canEdit: boolean = false
  oneShot: boolean = false
  sentAt?: string
  requireUnlock: boolean = false
  info: typeof MessageEntitySchema._type.info = []
  sender!: typeof MessageEntitySchema._type.sender
  sending: boolean = false
  sendAttemp = 0
  messageType = IMessageType.normal
  attachments: typeof MessageEntitySchema._type.attachments = []
  reactions: typeof MessageEntitySchema._type.reactions = []
  requestId!: string
  isDeleted: typeof MessageEntitySchema._type.isDeleted = false

  constructor() {}

  get messageStatus() {
    if(this.id) {
      return 'send'
    }
  }

  get hasAttachment() {
    return this.attachments.length >= 1
  }

  static haveSeen(info: typeof MessageEntitySchema._type.info) {
    return info.filter(e => typeof e.readAt == 'string' && e.memberId == SessionStore.user.UserId).length == 1
  }

  haveSeen() {
    return this.info.filter(e => typeof e.readAt == 'string' && e.memberId == SessionStore.user.UserId).length == 1
  }


  meSender() {
    return this.sender?.wxUserId == SessionStore.user.UserId
  }

}
