import { Injectable } from '@angular/core';
import { z } from 'zod';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { zodSafeValidation } from 'src/app/utils/zodValidation';

const MessageReactionInputDTOSchema = z.object({
  memberId: z.number(),
  messageId: z.string(),
  roomId: z.string(),
  reaction: z.string(),
  requestId: z.string().optional()
})

export type MessageReactionInput = z.infer< typeof MessageReactionInputDTOSchema>

@Injectable({
  providedIn: 'root'
})
export class MessageReactionUseCaseService {

  constructor(
    public repository: IMessageSocketRepository
  ) { }

  @XTracerAsync({name:'MessageReactionUseCaseService', module:'chat',  bugPrint: true, waitNThrow: 5000})
  async execute(input: MessageReactionInput, tracing?: TracingType) {

    const validation = zodSafeValidation<MessageReactionInput>(MessageReactionInputDTOSchema, input)

    if(validation.isOk()) {
      const result =  await this.repository.reactToMessageSocket(input)

      // console.log('result', result)

      return result
    } else {
      tracing.hasError('invalid input', {
        error: validation.error
      })
    }


  }
}
