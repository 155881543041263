import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Event } from 'src/app/models/event.model'
import { EventBody } from 'src/app/models/eventbody.model';
import { EventPerson } from 'src/app/models/eventperson.model';
import { DiscartExpedientModalPage } from '../../discart-expedient-modal/discart-expedient-modal.page';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { SearchPage } from 'src/app/pages/search/search.page';
import { SearchList } from 'src/app/models/search-document';
import { UserSession } from 'src/app/models/user.model';
import { AttendeesPageModal } from 'src/app/pages/events/attendees/attendees.page';
import { ToastService } from 'src/app/services/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { ThemeService } from 'src/app/services/theme.service'
import { SessionStore } from 'src/app/store/session.service';
import { RoleIdService } from 'src/app/services/role-id.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { environment } from 'src/environments/environment';
import { TaskService } from 'src/app/services/task.service'
import { Utils } from 'src/app/module/agenda/utils';
import { Observable } from 'rxjs';
import { TableSharedCalendar } from 'src/app/module/agenda/data/data-source/agenda-local-data-source.service';
import { AgendaDataRepositoryService } from 'src/app/module/agenda/data/repository/agenda-data-repository.service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { isHttpError } from 'src/app/services/http.service';
import { AgendaService } from 'src/app/module/agenda/domain/agenda.service'
import { RoleId } from 'src/app/core/agenda/entity/event';
import { map } from 'rxjs/operators';

const moment = _rollupMoment || _moment;

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}

@Component({
  selector: 'app-book-meeting-modal',
  templateUrl: './book-meeting-modal.page.html',
  styleUrls: ['./book-meeting-modal.page.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})

export class BookMeetingModalPage implements OnInit {

  public date: any;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public ionicMinDate = new Date().toISOString().slice(0, 10)

  // curent timestamp + 15 minutes
  public endMinDate = new Date(new Date().getTime() + 15 * 60000);

  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 5;

  CalendarNamesOptions = ['Oficial', 'Pessoal']

  Form: FormGroup;
  validateFrom = false
  recurringTypes = [];
  Occurrence: Date = new Date()
  EventRecurrenceType = '-1'

  showLoader = false


  @ViewChild('picker') picker: any;
  @ViewChild('fim') fim: any;
  @ViewChild('inicio') inicio: any;
  @ViewChild('picker1') picker1: any;

  task: any;
  fulltask: any;

  taskParticipants: EventPerson[] = [];
  taskParticipantsCc: EventPerson[] = [];

  taskDocId: string;
  loadedAttachments: any;
  documents: SearchList[] = [];

  adding: "intervenient" | "CC" = "intervenient";

  postData: Event;
  eventBody: EventBody;
  eventAttendees: EventPerson[];

  formLocationSatus: boolean = false;
  showAttendees = false;
  emptyTextDescription = "Selecionar intervenientes";

  sessionStore = SessionStore;
  SessionStore=SessionStore
  environment = environment
  loggeduser: UserSession;
  eventPersons: EventPerson[];
  contacts: EventPerson[];

  hasChangeCalendar = false
  selectedUserCalendar:any;
  sharedCalendar: Observable<TableSharedCalendar[]>
  allDayCheck: boolean = false;
  CalendarNameShow = true
  eventRecurence = 'never';

  daysOfWeek = {
    sun: false,
    mon: false,
    tues: false,
    wed: false,
    thurs: false,
    fri: false,
    sat: false,
  };

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private attachmentsService: AttachmentsService,
    private toastService: ToastService,
    public ThemeService: ThemeService,
    public RoleIdService: RoleIdService,
    private httpErroHandle: HttpErrorHandle,
    public TaskService: TaskService,
    public utils: Utils,
    private agendaDataRepository: AgendaDataRepositoryService,
    private AgendaService: AgendaService
  ) {
    this.taskParticipants = [];

    this.task = this.navParams.get('task');
    this.fulltask = this.navParams.get('fulltask');

    console.log('fulltask', this.fulltask)

    if (this.fulltask?.Documents) {
      this.documents = this.fulltask.Documents
    }

    this.postData = new Event();
    this.eventBody = { BodyType: "1", Text: "" };
    this.postData.Body = this.eventBody;

    this.postData.Subject = this.task.Folio;
    this.postData.CalendarName = "Oficial";

    let startDate = this.roundTimeQuarterHour()
    this.postData.StartDate = startDate;
    this.postData.EndDate = this.roundTimeQuarterHourPlus15(startDate);

    this.postData.Category = 'Reunião'
    this.postData.IsAllDayEvent = false

    this.postData.EventRecurrence  = {
      frequency: this.eventRecurence,
      until: "",
      Type: '' ,
    };

    if (this.taskParticipants.length == 0) {
      this.taskParticipants = [
        // {
        //   EmailAddress: SessionStore.user.Email,
        //   IsRequired: true,
        //   Name: SessionStore.user.FullName,
        //   UserType: "GD"
        // }
      ]
    }

    this.loggeduser = SessionStore.user;

    // Define the role priorities
    const rolePriorities: { [key: number]: number } = {
      100000014: 1, // Presidente da República
      100000011: 2, // Vice Presidente (example role ID)
      // Add other roles with their priorities here
    };

    this.agendaDataRepository.getSharedCalendar()

    this.sharedCalendar = this.agendaDataRepository.getShareCalendarItemsLive().pipe(
      map(data => data.sort((a, b) => {
        const priorityA = rolePriorities[a.roleId] || Infinity;
        const priorityB = rolePriorities[b.roleId] || Infinity;
        return priorityA - priorityB;
      }))
    )

    this.setCalendarByDefault(true)
    this.postData.EventRecurrence = {
      frequency: 'never',
      until: "",
      Type: ''
    }

    this.changeAgenda()
  }

  ngOnInit() {
    // console.log('TEsTEROLE',this.eventService.calendarNamesAry)
    this.adding = "intervenient";
    this.getAttachments();
    this.setDefaultTime()
    // this.getRecurrenceTypes();
    this.fetchContacts("")

  }



  async setCalendarByDefault(force) {
    if (!this.selectedUserCalendar || force) {

      const data = await this.agendaDataRepository.geCalendars()

      const prObject = data.find(e => e?.roleId == RoleId.PRES)
      if(prObject) {
        this.selectedUserCalendar = prObject.wxUserId
      } else {
        this.selectedUserCalendar = SessionStore.user.UserId
      }

      this.changeAgenda()

    }
  }

  ngOnDestroy() {
    clearInterval(this.myInterval)
  }

  myInterval = setInterval(() => {
    document.querySelectorAll('.ngx-mat-timepicker input').forEach((e: any) => {
      if (e) {
        e.disabled = true;
      }
    })
  }, 1000);


  // getRecurrenceTypes() {
  //   this.calendarService.getRecurrenceTypes().subscribe(res => {
  //     this.recurringTypes = res;
  //   });
  // }

  changeSegmentCalendar() {
    this.hasChangeCalendar = true
  }

  async changeAgenda() {

    this.CalendarNameShow = false

    const result = await this.agendaDataRepository.geCalendars()

    const selectedCalendar = result.find(e => e.wxUserId == this.selectedUserCalendar)

    if(selectedCalendar) {
      if(selectedCalendar.shareType == 1) {
        this.CalendarNamesOptions = ['Oficial']
      } else if(selectedCalendar.shareType == 2) {
        this.CalendarNamesOptions = ['Pessoal']
      } else if (selectedCalendar.shareType == 3) {
        this.CalendarNamesOptions = ['Oficial', 'Pessoal']
      }
    }

  }

  setDefaultTime() {
    this.postData.StartDate = this.roundTimeQuarterHour();
    this.postData.EndDate = this.roundTimeQuarterHourPlus15(this.postData.StartDate);
  }

  getAttachments() {
    this.attachmentsService.getAttachmentsBySerial(this.task.SerialNumber).subscribe(res => {
      this.loadedAttachments = res;
    });
  }

  close() {
    this.modalController.dismiss(null);
  }

  goBack() {
    this.modalController.dismiss(null);
    window.history.back();
  }


  runValidation() {
    this.validateFrom = true
    if (new Date(this.postData.StartDate).getTime() > new Date(this.postData.EndDate).getTime()) {
      this.toastService._badRequest("A data de fim não pode ser inferior a data de início do evento")
    }
  }

  get dateValid() {
    return new Date(this.postData.StartDate).getTime() < new Date(this.postData.EndDate).getTime() ? 'ok' : null
  }

  injectValidation() {

    this.Form = new FormGroup({
      Subject: new FormControl(this.postData.Subject, [
        Validators.required,
        // Validators.minLength(4)
      ]),
      Location: new FormControl(this.postData.Location, [
        Validators.required,
      ]),
      CalendarName: new FormControl(this.postData.CalendarName, [
        Validators.required
      ]),
      Date: new FormControl(this.dateValid, [
        Validators.required
      ]),
      participantes: new FormControl(this.taskParticipants, [
        Validators.required
      ]),
      Categories: new FormControl(this.postData.Category, [
        Validators.required
      ]),

    })
  }

  @XTracerAsync({name:'convocar-reunia', bugPrint: true})
  async saveTask(tracing?: TracingType) {

    if (SessionStore.user.Profile != 'PR') {
      this.injectValidation()
      this.runValidation()
      if (this.Form.invalid) return false
    }

    let Attendees = this.taskParticipants.concat(this.taskParticipantsCc);

    console.log({Attendees})

    this.eventBody = {
      BodyType: '1',
      Text: this.postData.Body.Text,
    }
    let postData = {
      EventId: '',
      Subject: this.postData.Subject,
      Body: this.eventBody,
      Location: this.postData.Location,
      CalendarId: '',
      CalendarName: this.postData.CalendarName,
      StartDate: this.postData.StartDate,
      EndDate: this.postData.EndDate,
      EventType: 'Meeting',
      Attendees: Attendees,
      IsMeeting: false,
      IsRecurring: false,
      AppointmentState: 0,
      TimeZone: 'UTC',
      Organizer: null,
      Category: 'Meeting',
      HasAttachments: true,
      IsAllDayEvent: this.postData.IsAllDayEvent,
      EventRecurrence: {
        frequency: this.eventRecurence,
        until: "",
        Type: ''  },
      Attachments: []
    }

    this.postData.Attendees = this.taskParticipants.concat(this.taskParticipantsCc);

    const DocumentToSave = this.documents.map((e: any) => {
      return {
        ApplicationId: e.ApplicationType || e.ApplicationId,
        Source: 1,
        SourceId: e.Id || e.DocId || e.SourceId,
        SourceName: e.Assunto,
        Description: e.Assunto,
        Stakeholders: e.EntidadeOrganicaNome
      }
    });

    let i =0;
    this.postData.EventRecurrence.daysOfWeek = []
    for(const [key, value] of Object.entries(this.daysOfWeek)) {
      if(value) {
        if(!this.postData.EventRecurrence.daysOfWeek.includes(i)) {
          this.postData.EventRecurrence.daysOfWeek.push(i);
        }
      }
      i++
    }


    this.postData.Attachments = DocumentToSave as any;
    let loader = this.toastService.loading();

    const calendar = await this.agendaDataRepository.getCalendarByUserId(this.selectedUserCalendar)
    if(calendar.isOk()) {

      const value = await this.agendaDataRepository.createEvent(this.postData, this.documents, calendar.value, tracing)
      if(value.isOk()) {

        console.log(value.value)

        this.httpErroHandle.httpsSucessMessagge('new event')
        loader.remove();
        tracing.setAttribute('outcome', 'success')
        this.close();
      } else {

        if(!isHttpError(value.error)) {
          this.toastService._badRequest('Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico. #1')
          console.log(value.error)
        } else {
          this.httpErroHandle.httpStatusHandle(value.error.status)
        }
        console.log('create event error: ', value.error)
        tracing.setAttribute('outcome', 'failed')
      }


    } else {


      tracing.setAttribute('outcome', 'failed')
      tracing.setAttribute('no', 'this.selectedUserCalendar')
    }

    if (this.task.FsId == '8') {
      // const calendar = await this.agendaDataRepository.getCalendarByUserId(this.selectedUserCalendar)
      // if(calendar.isOk()) {

      //   try {
      //     const value = await this.agendaDataRepository.createEvent(this.postData, this.documents, calendar.value, tracing).toPromise()
      //     console.log(value)
      //     this.httpErroHandle.httpsSucessMessagge('new event')
      //     loader.remove();
      //     tracing.setAttribute('outcome', 'success')
      //   } catch (error) {
      //     console.log('create event error: ', error)
      //     tracing.setAttribute('outcome', 'failed')

      //     this.httpErroHandle.httpStatusHandle(error.status)
      //   }

      // } else {

      // }

    }
    else if (this.task.FsId == '361') {
      // console.log('361')
      // const loader = this.toastService.loading()
      // try {

      //   const CalendarId = this.selectedCalendarId()
      //   console.log({ CalendarId })
      //   await this.calendarService.genericCreateTaskEvent(this.task.FolderID | this.task.FolderId, postData, "", this.task.SerialNumber, this.task.FsId, CalendarId).toPromise();

      //   this.close();
      //   this.httpErroHandle.httpsSucessMessagge('Marcar Reunião')

      // } catch (error) {
      //   this.httpErroHandle.httpStatusHandle(error)
      // } finally {
      //   loader.remove()
      // }
    }
    else {
      this.toastService._successMessage('Não é possível marcar a reunião de momento');
    }


    loader.remove();
  }


  onCheckboxChange(event: any) {
    if (this.allDayCheck) {
      this.postData.IsAllDayEvent = this.allDayCheck;
      this.postData.StartDate = this.setAlldayTime(this.postData.StartDate)
      this.postData.EndDate = this.setAlldayTimeEndDate(this.postData.EndDate)

      console.log('Recurso ativado!!');
    } else {
      this.postData.IsAllDayEvent = this.allDayCheck;
      this.postData.EndDate = this.setAlldayTimeEndDateNotAlday(this.postData.EndDate)
      console.log('Recurso desativado');

    }
  }


  async addParticipants() {

    this.adding = "intervenient";

    if (window.innerWidth <= 801) {
      const modal = await this.modalController.create({
        component: AttendeesPageModal,
        componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc
        },
        cssClass: 'modal modal-desktop',
        backdropDismiss: false
      });



      modal.onDidDismiss().then((data) => {
        console.log('attenes return', data)
        if (data) {
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);
        }
      }, (error) => {
        console.log(error)
      });

      await modal.present();

    } else {
      this.showAttendees = true;
    }
  }

  openLastOccurrence() {
    let input: any = document.querySelector('#last-occurrence')
    if (input) {
      input.click()
    }
  }

  async addParticipantsCc() {

    this.adding = "CC";

    if (window.innerWidth <= 800) {
      const modal = await this.modalController.create({
        component: AttendeesPageModal,
        componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc
        },
        cssClass: 'modal modal-desktop',
        backdropDismiss: false
      });

      await modal.present();

      modal.onDidDismiss().then((data) => {

        if (data) {
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);
        }
      }, (error) => {
        console.log(error)
      });
    } else {
      this.showAttendees = true;
    }
  }

  async distartExpedientModal() {
    const modal = await this.modalController.create({
      component: DiscartExpedientModalPage,
      componentProps: {
        serialNumber: this.task.SerialNumber,
      },
      cssClass: 'discart-expedient-modal',
      backdropDismiss: false
    });


    modal.onDidDismiss().then(
      () => { }
      , (error) => {
        console.log(error)
      })
    await modal.present();
  }

  async getDoc() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        showSearchInput: true,
        select: true
      }
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      if (res) {
        const data = res.data;
        this.documents.push(data.selected);
        // console.log(this.documents)
      }
    }, (error) => {
      console.log(error)
    });
  }

  removeAttachment(index: number) {
    this.documents = this.documents.filter((e, i) => index != i);
  }

  validateFormInputs() {
    let formLocation = this.postData.Location.trim();
    if (!this.postData.Location && formLocation.length <= 0) {
      this.formLocationSatus = true;
    }
  }

  dynamicSetIntervenient({ taskParticipants, taskParticipantsCc }) {
    this.taskParticipants = taskParticipants;
    this.taskParticipantsCc = taskParticipantsCc;
  }

  setIntervenient(data) {
    this.taskParticipants = data;
  }

  setIntervenientCC(data) {
    this.taskParticipantsCc = data;
  }


  roundTimeQuarterHour(timeToReturn = new Date()) {
    let date = timeToReturn || new Date();
    const minutes = date.getMinutes();
    date.setSeconds(0);

    if (minutes % 15 != 0) {

      if (minutes > 45) {
        date.setMinutes(60)
      } else if (minutes > 30) {
        date.setMinutes(45)
      } else if (minutes > 15) {
        date.setMinutes(30)
      } else if (minutes > 0) {
        date.setMinutes(15)
      }

    }

    return date
  }

  roundTimeQuarterHourPlus15(date: Date) {
    const _date = new Date(date);
    const minutes = _date.getMinutes();
    _date.setMinutes(minutes + 15)
    return _date
  }



  setStartDate() {
    this.postData.StartDate = this.roundTimeQuarterHour();
  }

  setEndDate() {
    this.postData.EndDate = this.postData.StartDate;
  }

  async fetchContacts(filter: string) {
    console.log(this.loggeduser)

    const result = await this.AgendaService.setDefaultParticipants()

    if(result.isOk()) {
      if(result.value) {
        console.log('Attendes Email', result.value)
        this.setIntervenient(result.value);
      }
    }

  }


  setAlldayTime(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDate(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDateNotAlday(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }
}
