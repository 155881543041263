import { Dexie } from 'Dexie';
import { DexieSentryErrorTable, SentryErrorTableColumn } from './schema/SentryErrors';
// import FDBFactory from 'fake-indexeddb/lib/FDBFactory';
// import FDBKeyRange from 'fake-indexeddb/lib/FDBKeyRange';

// Database declaration (move this to its own module also)
export const LogsDatabase = new Dexie('logs-analytics', {
  //  indexedDB: new FDBFactory,
  //   IDBKeyRange: FDBKeyRange, // Mocking IDBKeyRange
}) as Dexie & {
  sentryError: DexieSentryErrorTable
};
  
LogsDatabase.version(1).stores({
  sentryError: SentryErrorTableColumn
});
  