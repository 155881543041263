import { EntityTable } from 'Dexie';
import { z } from 'zod';


const DscSchema = z.object({
  environment: z.string(),
  public_key: z.string(),
  release: z.string(),
  replay_id: z.any(),
  sample_rate: z.string(),
  sampled: z.boolean(),
  trace_id: z.string(),
  transaction: z.any(),
});

const MetaSchema = z.object({
  breadcrumbs: z.object({
    values: z.record(z.string(), z.any()),
  }),
});

const Breadcrumbs2Schema = z.object({
  values: z.array(z.object({
    timestamp: z.number(),
    type: z.string(),
    category: z.string(),
    level: z.string(),
    message: z.string().optional(),
    data: z.object({
      method: z.string().optional(),
      status_code: z.number().optional(),
      url: z.string().optional(),
      arguments: z.array(z.any()).optional(),
      logger: z.string().optional(),
      from: z.string().optional(),
      to: z.string().optional(),
      __span: z.string().optional(),
    }).optional(),
    event_id: z.string().optional(),
  })),
});

const MetricsSchema = z.object({
  "bytes.ingested.event": z.number(),
  "bytes.stored.event": z.number(),
});

const ContextsSchema = z.object({
  angular: z.object({
    type: z.string(),
    version: z.number(),
  }),
  browser: z.object({
    name: z.string(),
    version: z.string(),
    type: z.string(),
  }),
  os: z.object({
    name: z.string(),
    version: z.string(),
    type: z.string(),
  }),
  trace: z.object({
    trace_id: z.string(),
    span_id: z.string(),
    op: z.string(),
    status: z.string(),
    client_sample_rate: z.number(),
    type: z.string(),
  }),
});

const ExceptionSchema = z.object({
  values: z.array(z.object({
    type: z.string(),
    value: z.string(),
    stacktrace: z.object({
      frames: z.array(z.object({
        function: z.string(),
        module: z.string(),
        filename: z.string(),
        abs_path: z.string(),
        lineno: z.number().optional(),
        colno: z.number().optional(),
        pre_context: z.array(z.string()).optional(),
        context_line: z.string().optional(),
        post_context: z.array(z.string()).optional(),
        in_app: z.boolean(),
        data: z.object({
          sourcemap: z.string(),
          resolved_with: z.string(),
          sourcemap_origin: z.object({
            scraped_file: z.string(),
          }),
          symbolicated: z.boolean(),
          orig_in_app: z.number().optional(),
        }).optional(),
      })),
    }),
    raw_stacktrace: z.object({
      frames: z.array(z.object({
        function: z.string().optional(),
        filename: z.string(),
        abs_path: z.string(),
        lineno: z.number().optional(),
        colno: z.number().optional(),
        context_line: z.string().optional(),
        post_context: z.array(z.string()).optional(),
        in_app: z.boolean(),
      })),
    }),
    mechanism: z.object({
      type: z.string(),
      handled: z.boolean(),
    }),
  })),
});

const GroupingConfigSchema = z.object({
  enhancements: z.string(),
  id: z.string(),
});

const IngestPathSchema = z.array(z.object({
  version: z.string(),
  public_key: z.string(),
}));

const MetadataSchema = z.object({
  display_title_with_tree_label: z.boolean(),
  filename: z.string(),
  function: z.string(),
  in_app_frame_mix: z.string(),
  type: z.string(),
  value: z.string(),
});

const RequestSchema = z.object({
  url: z.string(),
  headers: z.array(z.tuple([z.string(), z.string()])),
});

const ScrapingAttemptSchema = z.array(z.object({
  status: z.string(),
  url: z.string(),
}));

const SdkSchema = z.object({
  name: z.string(),
  version: z.string(),
  integrations: z.array(z.string()),
  packages: z.array(z.object({
    name: z.string(),
    version: z.string(),
  })),
});

const UserSchema = z.object({
  ip_address: z.string(),
  geo: z.object({
    country_code: z.string(),
    city: z.string(),
    subdivision: z.string(),
    region: z.string(),
  }),
  sentry_user: z.string(),
});

const SentryErrorTableSchema = z.object({
  event_id: z.string(),
  project: z.number(),
  release: z.string(),
  dist: z.string(),
  platform: z.string(),
  message: z.string(),
  datetime: z.string(),
  tags: z.array(z.array(z.string())),
  _dsc: DscSchema,
  _meta: MetaSchema,
  _metrics: MetricsSchema,
  breadcrumbs: Breadcrumbs2Schema,
  contexts: ContextsSchema,
  culprit: z.string(),
  environment: z.string(),
  exception: ExceptionSchema,
  fingerprint: z.array(z.string()),
  grouping_config: GroupingConfigSchema,
  hashes: z.array(z.string()),
  ingest_path: IngestPathSchema,
  key_id: z.string(),
  level: z.string(),
  location: z.string(),
  logger: z.string(),
  metadata: MetadataSchema,
  nodestore_insert: z.number(),
  received: z.number(),
  request: RequestSchema,
  scraping_attempts: ScrapingAttemptSchema,
  sdk: SdkSchema,
  timestamp: z.number(),
  title: z.string(),
  type: z.string(),
  user: UserSchema,
  version: z.string(),
});

export type SentryErrorTable = z.infer<typeof SentryErrorTableSchema>;
export type DexieSentryErrorTable = EntityTable<SentryErrorTable, 'event_id'>;
export const SentryErrorTableColumn = 'event_id';

