import { UserLoginOutputResponse } from "../repository/user-remote-repository";
import { UserLoginOutput } from "../use-case/user-login-use-case.service";

export class UserLoginMapper{
  static toDomainData(input: UserLoginOutputResponse): UserLoginOutput {
    return {
      RefreshToken: input.data.refreshToken,
      Authorization: input.data.authorization,
      Email: input.data.user.wxeMail,
      FullName: input.data.user.wxFullName,
      RoleDescription: input.data.user.role,
      RoleID: input.data.user.roleId,
      UserId: input.data.user.wxUserId,
      UserPermissions: input.data.permissions,
      Profile: '',
      UserPhoto: input.data.user?.userPhoto
    }
  }
}