import { versionData } from '../../../version/git-version'
import { Environment } from './../../app/models/envarioment'

// export const environment: Environment = {
//   id:'3',
//   apiURL: 'http://gpr-dev-01:83/jwt/api/',
//   /* apiURL: 'https://gdapi-dev-0.dyndns.info/jwt/api/', */
//   apiChatUrl: 'https://gdchat-dev.dyndns.info/api/v1/',
//   apiWsChatUrl: 'wss://gdchat-dev.dyndns.info/websocket',
//   apiPCURL: 'http://gpr-dev-01:86/api/',
//   /* apiPCURL: 'https://gdcmapi-dev.dyndns.info/api/', */
//   logoLabel: 'Presidente da República',
//   despachoLabel: 'Presidenciais',
//   despachoLabel2: 'Despachos Presidênciais',
//   production: false,
//   domain: 'gabinetedigital.local',
//   defaultuser: 'paulo.pinto@gabinetedigital.local',
//   defaultuserpwd: 'tabteste@006',
//   chatOffline: true,
//   presidential: true,
//   version: versionData,
//   agendaPR: 'Agenda do PR',
//   agendaVP: 'Agenda do MD',
//   PR: 'PR',
//   VP: '',
//   dispatchPR: 'Despachos Presidênciais',
//   sentryUrl: 'https://9920cc36f1d740b987426ee8d80cf588@o4504340905525248.ingest.sentry.io/4504340946419712',
//   storageProduction: true,
//   rejectUnauthorized: "true",
//   fileHub: 'https://gdcmapi-dev.dyndns.info/FileHub'
// /*   production: true,
//   domain: 'gabinetedigital.local',
//   defaultuser: '',
//   defaultuserpwd: '',
//   chatOffline: true,
//   presidential: false,
//   version: versionData,
//   sentryUrl: 'https://9920cc36f1d740b987426ee8d80cf588@o4504340905525248.ingest.sentry.io/4504340946419712',
//   logoLabel: 'doneIT',
//   despachoLabel: 'do Titular',
//   despachoLabel2: 'Despachos do Titular',
//   agendaPR: 'Agenda do Titular',
//   agendaVP: 'Agenda do (MD)',
//   PR: 'Titular',
//   VP: '',
//   dispatchPR: 'Despachos Titular',
//   storageProduction: true */
// };


export const DevDev: Environment = {
  id:'3',
  apiURL: 'https://gdapi-dev.dyndns.info/jwt/api/',
  apiChatUrl: 'https://gdchat-dev.dyndns.info/api/v1/',
  apiWsChatUrl: 'wss://gdchat-dev.dyndns.info/websocket',
  apiPCURL: 'https://gdcmapi-dev.dyndns.info/api/',
  logoLabel: 'Presidente da República',
  despachoLabel: 'Presidenciais',
  despachoLabel2: 'Despachos Presidênciais',
  production: false,
  domain: 'gabinetedigital.local',
  defaultuser: 'dlima@gabinetedigital.local',
  defaultuserpwd: 'tabteste@006',
  chatOffline: true,
  presidential: true,
  version: versionData,
  agendaPR: 'Agenda do PR',
  agendaVP: 'Agenda do MD',
  PR: 'PR',
  VP: '',
  dispatchPR: 'Despachos Presidênciais',
  sentryUrl: 'https://9920cc36f1d740b987426ee8d80cf588@o4504340905525248.ingest.sentry.io/4504340946419712',
  storageProduction: false,
  rejectUnauthorized: "true"
 /*  production: true,
  domain: 'gabinetedigital.local',
  defaultuser: 'paulo.pinto@gabinetedigital.local',
  defaultuserpwd: 'tabteste@006',
  chatOffline: true,
  presidential: false,
  version: versionData,
  sentryUrl: 'https://9920cc36f1d740b987426ee8d80cf588@o4504340905525248.ingest.sentry.io/4504340946419712',
  logoLabel: 'doneIT',
  despachoLabel: 'do Titular',
  despachoLabel2: 'Despachos do Titular',
  agendaPR: 'Agenda do Titular',
  agendaVP: 'Agenda do (MD)',
  PR: 'Titular',
  VP: '',
  dispatchPR: 'Despachos Titular',
  storageProduction: false, */,
  fileHub: 'https://gdcmapi-dev.dyndns.info/FileHub'
};
