import { DexieRepository } from "src/app/infra/repository/dexie/dexie-repository.service";
import { Observable as  DexieObservable, PromiseExtended } from 'Dexie';
import { AttachmentTable } from "src/app/infra/database/dexie/instance/chat/schema/attachment";
import { Result } from "neverthrow";
import { MemberTable, MemberTableSchema } from "src/app/infra/database/dexie/instance/chat/schema/members";
import { RepositoryResult } from "src/app/infra/repository";
import { z } from "zod";
import { MemberListUPdateStatusInputDTO } from "src/app/core/chat/usecase/socket/member-list-update-status-use-case.service";
import { Observable } from "rxjs";


export const DirectMemberInput = MemberTableSchema.pick({
  roomId: true,
}).extend({
  currentUserId: z.number(),
})

export type  IDirectMemberInput =  z.infer<typeof DirectMemberInput>

export const GetMemberLiveInput = MemberTableSchema.pick({
  wxUserId: true,
  roomId: true,
})

export type IGetMemberLive = z.infer<typeof GetMemberLiveInput>
export abstract class IMemberLocalRepository extends DexieRepository<AttachmentTable, AttachmentTable> {

  abstract directMember(input:IDirectMemberInput): Promise<Result<MemberTable, any>>
  abstract addMember(data: MemberTable):  Promise<RepositoryResult<number, MemberTable>>
  abstract updateMemberRole(data: MemberTable):  Promise<Result<number, any>>
  abstract updateMembersStatus(data: MemberListUPdateStatusInputDTO):  Promise<Result<true, any>>
  abstract allMemberOnline(roomId:string):  DexieObservable<boolean>
  abstract removeMemberFromRoom(roomId:string): Promise<Result<any ,any>>

  abstract getMemberLive(input: IGetMemberLive): DexieObservable<MemberTable>
  abstract getRoomMemberById(roomId:string): Promise<MemberTable[]>
  abstract getRoomMemberByIdLive(roomId:string): Observable<MemberTable[]>
  abstract getRoomMemberNoneAdminByIdLive(roomId:string):  DexieObservable<MemberTable[]>

}
