import { Injectable } from '@angular/core';
import { roomListDetermineChanges } from '../../../../module/chat/data/async/list/rooms/roomListChangeDetector';
import { captureAndReraiseAsync } from 'src/app/services/decorators/captureAndReraiseAsync';
import { CronJobService } from 'src/app/utils/task-scheduler'
import { z } from "zod";
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { MessageEntitySchema } from 'src/app/core/chat/entity/message';
import { GetRoomListMapper } from 'src/app/core/chat/mapper/getRoomListMapper';
import { IDBoolean } from 'src/app/infra/database/dexie/type';
import { RoomType } from 'src/app/core/chat/entity/group';
import { Logger } from 'src/app/services/logger/main/service';


const CreatedBySchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string().email(),
  userPhoto: z.string().nullable()// api check
});

const roomListItemSchema = z.object({
  id: z.string(),
  roomName: z.string(),
  createdBy: CreatedBySchema,
  createdAt: z.string(),
  expirationDate: z.string().nullable(), // api check
  roomType: z.number(),
  messages: MessageEntitySchema.array(),
  user1: CreatedBySchema.nullable(),
  user2: CreatedBySchema.nullable()
})

const RoomListItemOutPutDTOSchema = z.object({
  chatRoom: roomListItemSchema,
  joinAt: z.string()
})


// Define the schema for the entire response
export const RoomListOutPutDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.array(RoomListItemOutPutDTOSchema),
});

export type RoomListItemOutPutDTO = z.infer<typeof RoomListItemOutPutDTOSchema>
export type RoomListItemSchema = z.infer< typeof roomListItemSchema>
export type RoomListOutPutDTO = z.infer<typeof RoomListOutPutDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class GetRoomListUseCaseService {

  constructor(
    private roomRemoteDataSourceService: IRoomRemoteRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
    private CronJobService: CronJobService
  ) { }

  @captureAndReraiseAsync('RoomRepositoryService/list')
  async execute() {
    const result = await this.roomRemoteDataSourceService.getRoomList()

    const localList = await this.roomLocalDataSourceService.findAll()

    if(localList.isOk()) {
      if(result.isOk()) {

        const filterValidateRooms = result.value.data.filter(e => {
          if(e.chatRoom.roomType == RoomType.Direct) {

            if(e.chatRoom.user1 != null && e.chatRoom.user2  != null ||  e.chatRoom.user1?.wxUserId == e.chatRoom.user2?.wxUserId) {
              return true
            } else {
              Logger.error("direct room invalid data from, GetRoomListUseCaseService.execute", {
                data: e.chatRoom,
                user1: e.chatRoom.user1,
                user2: e.chatRoom.user2
              })
              return false
            }
          }
          return true
        })

        const { roomsToDelete, roomsToInsert, roomsToUpdate } = roomListDetermineChanges(filterValidateRooms, localList.value)

        // console.log({roomsToDelete, roomsToInsert, roomsToUpdate})

        // sometime api return duplicated rooms
        const insertedIds = []

        if(roomsToInsert) {
          const roomsToInsertEntity = GetRoomListMapper.toDomain(roomsToInsert)
          for( const room of  roomsToInsertEntity) {

            if(room.roomType == RoomType.Direct) {
              if(room.$id) {
                this.roomLocalDataSourceService.insert(room).then((result) => {

                  if( result.isErr() &&
                    result.error.DBErrorName == 'ConstraintError') {
                      this.roomLocalDataSourceService.update(room.$id, room).then((result) => {
                        if(result.isErr()) {
                          console.log('failed to update room id')
                        }
                      })
                  }
                })
              }

            } else {

              // prevent to insert the same room due to server duplication
              if(!insertedIds.find(e => room.id)) {

                const createResult = this.roomLocalDataSourceService.insert(room)

                insertedIds.push(room.id)

                createResult.then((result) => {
                  if(result.isErr()) {
                    console.log('error', result.error)
                  }
                })
              }

            }

          }
        }

        if(roomsToUpdate.length >= 1) {
          const roomsToUpdateEntity = GetRoomListMapper.toDomain(roomsToUpdate)
          this.roomLocalDataSourceService.updateMany(roomsToUpdateEntity)
        }

        for( const room of  roomsToDelete) {
          if(room.local == IDBoolean.false) {
            const findResult = this.roomLocalDataSourceService.findOne({id:room.id})
            findResult.then((result) => {
              if(result.isOk() && result.value) {
                this.roomLocalDataSourceService.delete(result.value.$id)
              }
            })

          }
        }

        for(const room of filterValidateRooms) {
          if(room.chatRoom.expirationDate) {


            this.CronJobService.createCronJob('remove expired room list', new Date(room.chatRoom.expirationDate), () => {
              setTimeout(() => {
                this.execute();
              }, 60000);
            })
          }
        }

      }
    }
    return result
  }

}
