import { Injectable } from '@angular/core';
import { GetRoomListUseCaseService } from './room-get-list-use-case.service'
import { RoomSocketRepositoryService } from '../../../../module/chat/data/repository/room/room-socket-repository.service'
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { IRoomSocketRepository } from '../../repository/room/room-socket-repository';

@Injectable({
  providedIn: 'root'
})
export class RoomGetListOnCreateUseCaseService {

  constructor(
    private RoomSocketRepositoryService: IRoomSocketRepository,
    private getRoomListUseCaseService: GetRoomListUseCaseService,
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) {
    this.init()
  }


  private init() {
    this.OnReceiveCreateRoom()
    this.OnDeleteCreateRoom()
  }


  private async OnReceiveCreateRoom() {
    this.RoomSocketRepositoryService.listenToCreateRoom().subscribe(async(data)=> {

      setTimeout(async () => {
        const findLocally = await this.roomLocalDataSourceService.findOne({id:(data.data as any).id})

        if(findLocally.isOk() && !findLocally.value) {
          this.getRoomListUseCaseService.execute()
        }

      }, 1000);


      // const result = await this.roomRemoteDataSourceService.getRoom(id)
      // console.log('OnReceiveCreateRoom', data)
      //this.getRoomListUseCaseService.execute()
    })
  }

  private OnDeleteCreateRoom() {
    this.RoomSocketRepositoryService.listenToDeleteRoom().subscribe((data)=> {
      console.log('OnDeleteCreateRoom', data)
      this.getRoomListUseCaseService.execute()
    })
  }
}
