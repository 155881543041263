import { HttpErrorResponse } from "@angular/common/http";
import { Result } from "neverthrow";
import { HttpResult } from "src/app/infra/http/type";
import { UserLoginInput } from "../use-case/user-login-use-case.service";
import { z } from "zod";

const UserRepositoryLoginParams = z.object({
  Auth: z.string(),
  ChannelId: z.number()
})


// Define the schema for the user object
const UserSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  role: z.string(),
  roleId: z.number(),
  userPhoto: z.string(),
  adUserSID: z.string(),
});

// Define the schema for the main response object
const UserLoginOutputSchema = z.object({
  user: UserSchema,
  authorization: z.string(),
  refreshToken: z.string(),
  permissions: z.array(z.number()),
});

// Define the main schema for the response
const LoginUserResponseSchema = z.object({
  success: z.boolean(),
  message: z.nullable(z.string()), // Message can be null
  data: UserLoginOutputSchema,
});



export type UserLoginOutputResponse = z.infer<typeof LoginUserResponseSchema>

const UserRefreshTokenInputSchema = z.object({
  authorization: z.string(),
  refreshToken: z.string(),
  channelId: z.number()
})

export type UserRefreshTokenInputDTO = z.infer<typeof UserRefreshTokenInputSchema>



export type IUserRepositoryLoginParams = z.infer<typeof UserRepositoryLoginParams>
export abstract class IUserRemoteRepository {
  abstract login(input: IUserRepositoryLoginParams): Promise<Result<HttpResult<UserLoginOutputResponse>, HttpErrorResponse>>
  abstract logout(): Promise<Result<HttpResult<any>, HttpErrorResponse>>
  abstract refreshToken(input:UserRefreshTokenInputDTO): Promise<Result<HttpResult<any>, HttpErrorResponse>>
}
