import { Injectable } from '@angular/core';
import { IRoomSocketRepository } from 'src/app/core/chat/repository/room/room-socket-repository';
import { GetRoomByIdUseCaseService } from './room-get-by-id-use-case.service'
@Injectable({
  providedIn: 'root'
})
export class RoomUpdateNameSyncService {

  constructor(
    private GetRoomByIdUseCaseService: GetRoomByIdUseCaseService,
    private roomSocketRepository: IRoomSocketRepository
  ) {
    // this.watch()
  }


  private watch() {
    this.roomSocketRepository.listenToRoomUpdate().subscribe((event) => {
      console.log('listenToRoomUpdate')
      this.GetRoomByIdUseCaseService.execute(event.data.id)
    })
  }
}
