import { Injectable } from '@angular/core';
import { IUserRemoteRepository } from 'src/app/core/user/repository/user-remote-repository';
import { UserLoginInput, UserLoginUseCaseService } from 'src/app/core/user/use-case/user-login-use-case.service';
import { UserLogOutUseCaseService } from 'src/app/core/user/use-case/user-log-out-use-case.service';
import { SessionStore } from './service/session.service'
import { UserEntity } from 'src/app/core/user/entity/userEntity';
import { UserRefreshTokenService } from 'src/app/core/user/use-case/user-refresh-token.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private userLoginUseCaseService: UserLoginUseCaseService,
    private userLogOutUseCaseService: UserLogOutUseCaseService,
    private userRefreshTokenService: UserRefreshTokenService
  ) { }


  async login(input: UserLoginInput) {
    const result = await this.userLoginUseCaseService.execute(input)

    if(result.isOk()) {

      // SessionStore.reset(new UserEntity({...result.value, ...result.value.user}))
    }

    return result
  }

  async logout() {
    return await this.userLogOutUseCaseService.execute()
  }

  refreshToken() {
    return this.userRefreshTokenService.execute()
  }
}
