import { Component, Input, OnInit } from '@angular/core';
import { EventPerson } from 'src/app/models/eventperson.model';
import { ModalController, NavParams } from '@ionic/angular';
import { ThemeService } from 'src/app/services/theme.service'
import { UserSession } from 'src/app/models/user.model';
import { SessionStore } from 'src/app/store/session.service';
import { Router } from '@angular/router';
import { ContactRepositoryService } from 'src/app/services/Repositorys/contacts/repository/contacts-repository.service';


@Component({
  selector: 'app-attendees',
  templateUrl: './attendees.page.html',
  styleUrls: ['./attendees.page.scss'],
})
export class AttendeesPageModal implements OnInit {

  // Defined by the API
  contacts: EventPerson[];
  showLoader: boolean = false;
  selectedContact: EventPerson[] =[];
  eventPersons: EventPerson[];
  adding: "intervenient" | "CC";
  currentPath = window.location.pathname;

  taskParticipants:EventPerson[] = [];
  taskParticipantsCc:EventPerson[] = [];
  loggeduser: UserSession;
  hideExternalDomain = true;
  taskType: any;


  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    public ThemeService: ThemeService,
    private router: Router,
    private ContactRepositoryService: ContactRepositoryService) {

      this.adding = this.navParams.get('adding');
      this.taskParticipants = this.navParams.get('taskParticipants');
      this.taskParticipantsCc = this.navParams.get('taskParticipantsCc');
      this.taskType = this.navParams.get('taskType');
      this.hideExternalDomain = this.navParams.get('hideExternalDomain');

      this.loggeduser = SessionStore.user;

    }

  ngOnInit() {
    this.fetchContacts("");

    if(this.taskParticipants == null || this.taskParticipants == undefined){
      this.taskParticipants = [];
    }

    if(this.taskParticipantsCc == null || this.taskParticipantsCc == undefined){
      this.taskParticipantsCc = [];
    }

  }

  ngOnChanges(event) {}

  save(){

    this.modalController.dismiss({
      'taskParticipants': this.taskParticipants,
      'taskParticipantsCc': this.taskParticipantsCc
    });
  }

  close() {
    this.modalController.dismiss(false);
  }

  onChange(evt: any) {
    this.fetchContacts(evt.detail.value);
  }

  filterSearchList(itm: EventPerson): boolean {

    const result =  this.taskParticipants.concat( this.taskParticipantsCc).find((contact, index)=>{

      if(contact.Name.toLocaleLowerCase() == itm.Name.toLocaleLowerCase() && contact.EmailAddress.toLocaleLowerCase() == itm.EmailAddress.toLocaleLowerCase()){
        index = index;
        return contact;
      }

    })

    return undefined == result;

  }

  remove(itm: EventPerson) {

    if(this.adding == "intervenient") {

      this.taskParticipants = this.taskParticipants.filter((contact, index) =>{

        if(contact.Name.toLocaleLowerCase() != itm.Name.toLocaleLowerCase() && contact.EmailAddress.toLocaleLowerCase() != itm.EmailAddress.toLocaleLowerCase()){
          return contact;
        }
        return false;

      });

    } else if (this.adding == "CC") {

      this.taskParticipantsCc = this.taskParticipantsCc.filter((contact, index) =>{

        if(contact.Name.toLocaleLowerCase() != itm.Name.toLocaleLowerCase() && contact.EmailAddress.toLocaleLowerCase() != itm.EmailAddress.toLocaleLowerCase()){
          return contact;
        }
        return false;

      });
    }

  }

  async selectContact(itm: EventPerson){
    if(this.adding == "intervenient"){
      itm.IsRequired = true;
      this.taskParticipants.push(itm);

    } else if (this.adding == "CC") {
      itm.IsRequired = false;
      this.taskParticipantsCc.push(itm);
    }
  }

  async fetchContacts(filter: string) {
    this.showLoader = true;

    const RequestResult  = await this.ContactRepositoryService.getUsersMap();
    if(RequestResult.isOk()) {
      const _result = RequestResult.value


      let result = _result.filter( e => e.UserType == 'GD' && e.Name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))

        if (this.eventPersons != null)
        {
          this.eventPersons.forEach(attendee => {
            const index: number = result.findIndex((cont) => {
              return cont.EmailAddress.toLocaleLowerCase() == attendee.EmailAddress.toLocaleLowerCase()
            });

            result.splice(index, 1);

          });
        }

        this.contacts = result as any;
        // console.log('Attendes Email',this.loggeduser.Email)
        let filterLoggedUserEmail = this.contacts.filter(item => item.EmailAddress.toLocaleLowerCase() != this.loggeduser.Email.toLocaleLowerCase())
        if(this.taskType == 0 || this.taskType == 1){
          filterLoggedUserEmail = this.contacts.filter(item => item.IsPR == false)
          }
        // console.log('Attendes Email', filterLoggedUserEmail)
        let filterEmptyEmail = filterLoggedUserEmail.filter(item => item.EmailAddress.toLocaleLowerCase() != "")

        this.contacts = filterEmptyEmail;
        //console.log('Attendes Email', this.contacts)
        this.showLoader = false;
    }
  }


  sort(data: []) {
    return data.sort(function (a: any, b: any) {
      if (a.Name > b.Name) {
          return -1;
      }
      if (b.Name > a.Name) {
          return 1;
      }
      return 0;
  }).reverse()
  }

}
