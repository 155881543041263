import { ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ZipkinExporter } from '@opentelemetry/exporter-zipkin';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { Resource } from '@opentelemetry/resources';
//import { OTLPTraceExporter } from '@opentelemetry/exporter-otlp-http';
import { context, trace, propagation } from '@opentelemetry/api';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
// const { OTLPTraceExporter: OTLPTraceExporterProto } = require("@opentelemetry/exporter-trace-otlp-proto");

function createProvider(serviceName) {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
    }),
  });

  // provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
  // provider.addSpanProcessor(new SimpleSpanProcessor(new ZipkinExporter({
  //   //url: 'https://5-180-182-151.cloud-xip.com:85/zipkin-endpoint/api/v2/spans',
  //   url: 'https://185-229-224-75.cloud-xip.com:85/zipkin-endpoint/api/v2/spans',
  //   serviceName: serviceName,
  //   getExportRequestHeaders: () => {
  //     return {
  //       'Authorization': 'Basic ' + btoa('tabteste@006:tabteste@006'),
  //     };
  //   }
  // })));

  provider.addSpanProcessor(new SimpleSpanProcessor(new OTLPTraceExporter({
    url: 'https://185-229-224-75.cloud-xip.com:85/collector2/v1/traces',
  })));

  provider.register();
  return provider;
}

// Example usage:
export const OpentelemetryChatProvider = createProvider('FO-chat-service');
export const OpentelemetryUserProvider = createProvider('FO-User-service');
export const OpentelemetryAgendaProvider = createProvider('FO-agenda-service');
export const OpentelemetryNotificationProvider = createProvider('FO-notification');
export const OpentelemetryInterceptorProvider = createProvider('FO-interceptor');
export const OpentelemetryPublicationProvider = createProvider('FO-publication-service');
export const OpentelemetryOtherProvider = createProvider('FO-other-service');

export const OpentelemetryLogging = createProvider('logging');

const tracerInstance = OpentelemetryAgendaProvider.getTracer('example-tracer-hole', '111', {})


function parentSpanExample() {
  // Create a tracer instance
  const tracer = trace.getTracer('FO-chat-service');

  // Start a parent span
  const parentSpan = tracer.startSpan('parent-span');
  parentSpan.setAttribute('key', 'value')

  const traceId = parentSpan.spanContext().traceId;
  const spanId = parentSpan.spanContext().spanId;
  parentSpan.end();  // End the parent span

  const spanContext = {
    traceId: traceId,
    spanId: spanId,
    traceFlags: 1 // 1 means the trace is sampled
  };

  // Create a new context with the parent span
  const parentContext = trace.setSpan(context.active(), parentSpan);
  const parentContext1 = trace.setSpan(context.active(), trace.wrapSpanContext(spanContext));

  // Start a new child span
  const childSpan = tracer.startSpan('child-span1', {root: false}, parentContext1);
  childSpan.end();

  // Simulate some work
  setTimeout(() => {
    childSpanExample(parentContext);  // Pass the context to child span

  }, 500);
}


function childSpanExample(parentContext) {
  // Create a new child span with the parent context
  const tracer = trace.getTracer('FO-chat-service');
  const childSpan = tracer.startSpan('child-span', undefined, parentContext);

  // Simulate some work
  setTimeout(() => {
    childSpan.end();  // End the child span
  }, 500);
}

// parentSpanExample()
