import { MessageTable } from "src/app/infra/database/dexie/instance/chat/schema/message";
import { DexieRepository } from "src/app/infra/repository/dexie/dexie-repository.service";
import { MessageEntity } from "../../entity/message";
import { Observable as  DexieObservable, PromiseExtended } from 'Dexie';
import { Observable } from "rxjs";
import { Result } from "neverthrow";

export interface IDirectMessages {
  receiverId: string,
  roomId: string
}

export abstract class IMessageLocalRepository extends DexieRepository<MessageTable, MessageEntity> {
  abstract setAllSenderToFalse(): void
  abstract getItems(roomId: string): PromiseExtended<MessageEntity[]>
  abstract getOfflineMessages(): Promise<MessageEntity[]>
  abstract onCreateObservable(): Observable<MessageTable>
  abstract getDirectMessages(input: IDirectMessages): Promise<Result<MessageEntity[], any>>
}
