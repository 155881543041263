
import { Dexie } from 'Dexie';
import { DexieMessageTable, messageTableColumn } from 'src/app/infra/database/dexie/instance/chat/schema/message';
import { DexieMembersTableSchema, MemberTableColumn } from 'src/app/infra/database/dexie/instance/chat/schema/members';
import { DexieRoomsTable, RoomTableColumn } from 'src/app/infra/database/dexie/instance/chat/schema/room';
import { DexieTypingsTable, TypingTableColumn } from 'src/app/infra/database/dexie/instance/chat/schema/typing';
import { MessageEntity } from 'src/app/core/chat/entity/message';
import { AttachmentTableColumn, DexieAttachmentsTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/attachment';
import { DexieDistributionTable, DistributionTableColumn } from './schema/destribution';
import { BoldTableColumn, DexieBoldTable } from './schema/bold';
import { DexieUserPhotoTable, UserPhotoTableColumn } from './schema/user-foto';
import FDBFactory from 'fake-indexeddb/lib/FDBFactory';
import FDBKeyRange from 'fake-indexeddb/lib/FDBKeyRange';


// Database declaration (move this to its own module also)
export const chatDatabase = new Dexie('chat-database-v4',{
  indexedDB: new FDBFactory,
  IDBKeyRange: FDBKeyRange, // Mocking IDBKeyRange
}) as Dexie & {
  message: DexieMessageTable,
  members: DexieMembersTableSchema,
  room: DexieRoomsTable,
  typing: DexieTypingsTable,
  attachment: DexieAttachmentsTableSchema,
  distribution: DexieDistributionTable,
  bold: DexieBoldTable,
  userPhoto: DexieUserPhotoTable
};

chatDatabase.version(1).stores({
  message: messageTableColumn,
  members: MemberTableColumn,
  room: RoomTableColumn,
  typing: TypingTableColumn,
  attachment: AttachmentTableColumn,
  distribution: DistributionTableColumn,
  bold:BoldTableColumn,
  userPhoto: UserPhotoTableColumn
});

chatDatabase.message.mapToClass(MessageEntity)
// Apply in-memory storage
