import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable, race, timer } from 'rxjs';
import { ok, Result, err } from 'neverthrow';
import { SessionStore } from 'src/app/store/session.service';
import { filter, first, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid'
import { ISignalRInput, ISignalROutput, ISocketMethod } from '../type';
import { MessageOutPutDataDTO } from 'src/app/core/chat/repository/dto/messageOutputDTO';
import { Subject } from 'rxjs';
export interface SocketMessage<T, I = any> {
  method: ISocketMethod,
  data: T,
  payload: I
}

export enum EnumSocketError  {
  catch = 1,
  close
}

export class SignalRConnection {

  private hubConnection: signalR.HubConnection;
  private connectionStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private disconnectSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private reconnectSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private sendLaterSubject: BehaviorSubject<Object> = new BehaviorSubject<Object>(false);
  private reconnect = true

  private sendDataSubject: Subject<ISignalROutput> = new Subject<ISignalROutput>();
  private pendingRequests: Map<string, { resolve: Function; reject: Function }> = new Map();
  url: string
  private hasConnectOnce = false
  private payload = {}

  constructor({url}) {
    this.url = url
  }

  establishConnection(): Promise<Result<signalR.HubConnection, false>> {
    return new Promise((resolve, reject) => {

      const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.url)
      .build();

      this.hubConnection = hubConnection

      hubConnection
      .start()
      .then(() => {
        this.hubConnection = hubConnection
        this.hasConnectOnce = true
        // console.log('Connection started');
        this.connectionStateSubject.next(true);
        this.join()
        this.addMessageListener()
        resolve(ok(hubConnection))
      })
      .catch(error => {
        console.log('Error while starting connection: ' + error);
        if(this.hasConnectOnce) {
          setTimeout(()=> {
            resolve(this.attempReconnect());
          }, 2000)
        } else {
          resolve(err(false))
        }
      });

      hubConnection.onclose(() => {
        console.log('Connection closed');
        this.connectionStateSubject.next(false);
        this.disconnectSubject.next(true)

        this.pendingRequests.forEach((_, requestId) => {
          const data = this.pendingRequests.get(requestId);

          if(data) {
            const { reject } = data
            reject(err({
              type: EnumSocketError.close
            }));
            this.pendingRequests.delete(requestId);
          }

        });

        if(this.reconnect) {
          resolve(this.attempReconnect());
        } else {
          resolve(err(false))
        }

      });
    })
  }


  async attempReconnect() {
    const attempConnection = await this.establishConnection()

    if(attempConnection.isOk()) {
      this.reconnectSubject.next(true)
    }

    return attempConnection
  }

  public join() {
    if(this.connectionStateSubject.value == true) {
      this.hubConnection.invoke("Join", SessionStore.user.UserId, SessionStore.user.FullName);
      //this.hubConnection.invoke("Join", 105, "UserFirefox");
    } else {
      this.sendLaterSubject.next({method: 'SendMessage', args:["Join", 312, "Daniel"]})
    }

  }


  sendData<T>(input: ISignalRInput): Promise<Result<T, any>> {
    return new Promise((resolve, reject) => {

      if(this.connectionStateSubject.value == true) {
        try {
          this.pendingRequests.set(input.data.requestId, { resolve, reject: (data: any) => resolve(data) });

          this.hubConnection.invoke(input.method, input.data)

          this.payload[input.data.requestId] = input.data

          this.sendDataSubject.pipe(
            filter((message) => {
              return input.data.requestId == message?.data.requestId ||
              input?.data?.roomName == message?.data.roomName && typeof input?.data?.roomName == 'string'

            }),
          ).subscribe(value => {
            resolve(ok(value.data as unknown  as T))
            // console.log('Received valid value:', value);
          });

        } catch(error) {
          console.log(error);
          resolve(err({
            type: EnumSocketError.catch
          }))
        }


      } else {
        console.log('dfdf');
        this.sendLaterSubject.next({method: 'SendMessage', args: input})
        return resolve(err(false))
      }

    })
  }

  private addMessageListener(): void {

    const methods = ['ReceiveMessage', 'TypingMessage', 'AvailableUsers',
      'ReadAt', 'DeleteMessage', 'UpdateMessage', 'GroupAddedMembers',
      'GroupDeletedMembers', 'UserAddGroup']

    for(const method of methods) {
      this.hubConnection.on(method, (message: any) => {
        this.sendDataSubject.next({
          method: method as any,
          data: message,
          payload: this.payload[message?.requestId]
        })
      });
    }
  }

  public getConnectionState(): Observable<boolean> {
    return this.connectionStateSubject.asObservable()
  }

  public getDisconnectTrigger(): Observable<boolean> {
    return this.disconnectSubject.asObservable();
  }

  public getData() {
    return this.sendDataSubject.asObservable()
  }

  public closeConnection(): void {
    this.reconnect = false
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => {
          console.log('Connection closed by user');
          this.connectionStateSubject.next(false);
          this.pendingRequests.forEach((_, requestId) => {
            const data = this.pendingRequests.get(requestId);

            if(data) {
              const { reject } = data
              reject(err({
                type: EnumSocketError.close
              }));
              this.pendingRequests.delete(requestId);
            }

          });
        })
        .catch(err => console.log('Error while closing connection: ' + err));
    }
  }

}
