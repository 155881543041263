import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventPerson } from 'src/app/models/eventperson.model';
import { removeDuplicate } from 'src/plugin/removeDuplicate.js'
import { ThemeService } from 'src/app/services/theme.service'
import { ViewChild } from '@angular/core';
import { Searchbar } from 'ionic-angular';
import { UserSession } from 'src/app/models/user.model';
import { SessionStore } from 'src/app/store/session.service';
import { Router } from '@angular/router';
import { ContactRepositoryService } from 'src/app/services/Repositorys/contacts/repository/contacts-repository.service';

@Component({
  selector: 'app-attendee-modal',
  templateUrl: './attendee-modal.page.html',
  styleUrls: ['./attendee-modal.page.scss'],
})
export class AttendeePage implements OnInit {

  // Defined by the API
  contacts: EventPerson[];
  filterEmptyEmail: EventPerson[];
  showLoader: boolean = false;
  eventPersons: EventPerson[];
  inputFilter = ''


  @Output() closeComponent = new EventEmitter<any>();

  @Output() setIntervenient = new EventEmitter<any>();
  @Output() setIntervenientCC = new EventEmitter<any>();
  @Output() dynamicSetIntervenient = new EventEmitter<any>();


  @Input() hideExternalDomain = true;


  @Input() taskParticipants: EventPerson[] = [];
  @Input() taskParticipantsCc: EventPerson[] = [];

  @Input() footer: boolean;

  @Input() selectLogUser: boolean = true;

  @ViewChild('Searchbar') searchbar: Searchbar;
  @Input() taskType: any;

  LtaskParticipants: EventPerson[] = [];
  LtaskParticipantsCc: EventPerson[] = [];
  loggeduser: UserSession;
  listOfNotAllowdRoute = ['/home/gabinete-digital/despachos/', ]
  selectedUserCalendar:any;

  constructor(
    public ThemeService: ThemeService,
    private router: Router,
    private ContactRepositoryService: ContactRepositoryService) {



    this.LtaskParticipants = removeDuplicate(this.taskParticipants);
    this.LtaskParticipantsCc = removeDuplicate(this.taskParticipantsCc);
    this.loggeduser = SessionStore.user;

  }

  ngOnChanges() {
    this.LtaskParticipants = removeDuplicate(this.taskParticipants);
    this.LtaskParticipantsCc = removeDuplicate(this.taskParticipantsCc);
  }

  currentPath = window.location.pathname;
  @Input() adding: "intervenient" | "CC";

  ngOnInit() {
    console.log('TASK TYPE',this.taskType);
    this.fetchContacts("");

    if (this.LtaskParticipants == null || this.LtaskParticipants == undefined) {
      this.LtaskParticipants = [];
    }

    if (this.LtaskParticipantsCc == null || this.LtaskParticipantsCc == undefined) {
      this.LtaskParticipantsCc = [];
    }

  }

  removeIdfromRoute(route: string) {
    let lastIndex: number = route.lastIndexOf('/');
    if (lastIndex !== -1) {
      let modifiedString: string = route.slice(0, lastIndex + 1);
      console.log(modifiedString);

    } else {
      console.log("No '/' found in the string.");
    }
  }


  save() {

    this.setIntervenient.emit(removeDuplicate(this.LtaskParticipants));
    this.setIntervenientCC.emit(removeDuplicate(this.LtaskParticipantsCc));

    this.searchbar.value = null;
    this.closeComponent.emit();
  }

  setContactWithClose() {

    if (this.currentPath == '/home/gabinete-digital') {
      this.setIntervenient.emit(this.LtaskParticipants);
      this.setIntervenientCC.emit(this.LtaskParticipantsCc);
    }

    this.dynamicSetIntervenient.emit({
      taskParticipants: this.LtaskParticipants,
      taskParticipantsCc: this.LtaskParticipantsCc
    })

  }

  async selectContact(itm: EventPerson) {

    if (this.adding == "intervenient") {

      itm.IsRequired = true;
      this.LtaskParticipants.push(itm);

    } else if (this.adding == "CC") {

      itm.IsRequired = false;
      this.LtaskParticipantsCc.push(itm);
    } else {
      //
    }

    // run only in gabinete digital

    this.setContactWithClose();
  }

  close() {
    this.closeComponent.emit();
  }

  onChange(evt: any) {
    this.fetchContacts(evt.detail.value);
  }

  filterSearchList(itm: EventPerson): boolean {

    const result = this.LtaskParticipants.concat(this.LtaskParticipantsCc).find((contact, index) => {

      if (this.checkStringNull(contact.Name) == this.checkStringNull(itm.Name) && this.checkStringNull(contact.EmailAddress) == this.checkStringNull(itm.EmailAddress)) {
        index = index;
        return contact;
      }
    });

    // if to show
    if (undefined != result) {
      return false;
    }


    const result2 = this.LtaskParticipantsCc.find((contact, index) => {

      if (this.checkStringNull(contact.Name) == itm.Name && this.checkStringNull(contact.EmailAddress) == this.checkStringNull(itm.EmailAddress)) {
        index = index;
        return contact;
      }
    });

    // if to show
    if (undefined != result2) {
      return false;
    }

    // don't show
    return true;

  }

  remove(itm: EventPerson) {

    if (this.adding == "intervenient") {

      this.LtaskParticipants = this.LtaskParticipants.filter((contact, index) => {

        if (contact.Name.toLocaleLowerCase() != itm.Name.toLocaleLowerCase() && contact.EmailAddress.toLocaleLowerCase() != itm.EmailAddress.toLocaleLowerCase()) {
          return contact;
        }
        return false;

      });

    } else if (this.adding == "CC") {

      this.LtaskParticipantsCc = this.LtaskParticipantsCc.filter((contact, index) => {

        if (contact.Name.toLocaleLowerCase() != itm.Name.toLocaleLowerCase() && contact.EmailAddress.toLocaleLowerCase() != itm.EmailAddress.toLocaleLowerCase()) {
          return contact;
        }
        return false;

      });

    }
    this.setContactWithClose();

  }

  sort(data: []) {
    return data.sort(function (a: any, b: any) {
      if (a.Name > b.Name) {
        return -1;
      }
      if (b.Name > a.Name) {
        return 1;
      }
      return 0;
    }).reverse()
  }

  async fetchContacts(_filter: string) {
    this.showLoader = true;

    const RequestResult  = await this.ContactRepositoryService.getUsersMap();

    if(RequestResult.isOk()) {
      const _result = RequestResult.value


      console.log({_result})

      let result = _result.filter( e => e.UserType == 'GD' && e.Name.toLocaleLowerCase().includes(_filter.toLocaleLowerCase()))

      if (this.eventPersons != null) {
        this.eventPersons.forEach(attendee => {
          const index: number = result.findIndex((cont) => {
            return this.checkStringNull(cont.EmailAddress) == this.checkStringNull(attendee.EmailAddress)
          });

          result.splice(index, 1);
        });
      }

      this.contacts = this.sort(result as any);
      // console.log('Attendes Email', this.loggeduser.Email)
      let filterLoggedUserEmail = this.contacts.filter(item => item.EmailAddress.toLocaleLowerCase() != this.loggeduser.Email.toLocaleLowerCase())
      if(this.taskType == 0 || this.taskType == 1){
        filterLoggedUserEmail = this.contacts.filter(item => item.IsPR == false)
        }
      // console.log('Attendes Email', filterLoggedUserEmail)
      let filterEmptyEmail = filterLoggedUserEmail.filter(item => item.EmailAddress.toLocaleLowerCase() != "")
      this.contacts = filterEmptyEmail;
      // console.log('Attendes Email', this.contacts)
      this.showLoader = false;



    } else {

    }
  }

  checkStringNull(value: string) {
    if (value) {
      return value.toLowerCase();
    } else {
      return value
    }
  }

}
