import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventPerson } from  'src/app/models/eventperson.model';
import { ThemeService } from 'src/app/services/theme.service'
import { AgendaService } from 'src/app/module/agenda/domain/agenda.service'

@Component({
  selector: 'app-sender',
  templateUrl: './sender.page.html',
  styleUrls:
   ['./sender.page.scss'],
})
export class SenderPage implements OnInit {

  contacts: EventPerson[];
  showContacts: EventPerson[];
  sender: string;
  selectedUser: string;

  constructor(private modalController:ModalController,
    public ThemeService: ThemeService,
    private AgendaService: AgendaService
  ) { 

    }

  ngOnInit() {
    this.getSender();
  }

  async getSender(){

    const result = await this.AgendaService.setDefaultParticipants()

    if(result.isOk()) {
      if(result.value) {
        console.log('Attendes Email', result.value)
        this.contacts = result.value as any;
        this.showContacts = result.value as any
      }
    }
  }
  
  filterContact(event?:any){

    const findPerson = this.sender.toLowerCase();
    const persons = this.contacts.filter((person) => {

      if (person.Name.toLowerCase().indexOf(findPerson) == 0){
       return true;
      }

    });

    this.showContacts = persons;
  }

  selectUser(username){
    this.selectedUser = username;
    this.close(this.selectedUser);
  }

  close(username?: string){
    this.modalController.dismiss(username);
  }

}
