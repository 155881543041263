import { Injectable } from '@angular/core';
import { ContactRepositoryService } from 'src/app/services/Repositorys/contacts/repository/contacts-repository.service';
import { SessionStore } from 'src/app/store/session.service';
import { UserList } from "src/app/models/entiry/agenda/contact";
import { err, ok, Result } from 'neverthrow';
import { XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';


export enum RoleId {
  /**
   * @description President role id
   */
  PV = 100000011,
  MD = 100000011,

  /**
   * @description Vice president role id
   */
  PRES = 100000014,

  /**
   * @description Consultant role id
   */
  Consultant = 99999872
}

@Injectable({
  providedIn: 'root'
})
export class EventSetDefaultParticipantsService {

  constructor(
    private ContactRepositoryService: ContactRepositoryService
  ) { }

  @XTracerAsync({name:'Event-Set-Default-Participants', module:'chat',  bugPrint: true})
  async execute(): Promise<Result<UserList|null, any>>  {

    if (SessionStore.user.Profile == 'PR') {

      const RequestResult  = await this.ContactRepositoryService.getUsersMap();
      if(RequestResult.isOk()) {
        const result = RequestResult.value

        let filterLoggedUserEmail = result.filter(item => item.RoleId == RoleId.MD)

        if(filterLoggedUserEmail.length >= 1) {
          filterLoggedUserEmail[0].IsRequired = true
        }

        return ok(filterLoggedUserEmail)

      } else {
        return err(null)
      }

    }

    return ok(null) 
  }
}
