import { SessionStore } from "src/app/store/session.service";
import { BaseEntity } from "src/app/utils/entity";
import { z } from "zod"
import { MessageEntitySchema } from "./message";
import { IDBoolean } from "src/app/infra/database/dexie/type";
import { Logger } from "src/app/services/logger/main/service";

export enum RoomType {
  Group = 1,
  Direct = 2
}

const UserSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string().nullable(),
});

const MemberSchema = z.object({
  id: z.string(),
  user: UserSchema,
  joinAt: z.string(),
  isAdmin: z.boolean()
});

export const RoomEntitySchema = z.object({
  $id: z.string(),
  id: z.string().uuid().optional(),
  roomName: z.string(),
  createdBy: z.object({
    wxUserId: z.number(),
    wxFullName: z.string(),
    wxeMail: z.string().email(),
    userPhoto: z.string().nullable().optional()// api check
  }),
  createdAt: z.any(),
  expirationDate: z.any().nullable().optional(),
  roomType: z.nativeEnum(RoomType),
  members: z.array(MemberSchema).optional(),
  messages: MessageEntitySchema.array().optional(),
  local: z.nativeEnum(IDBoolean).optional(),
  receiverId: z.number().optional()
})

export type IRoom = z.infer<typeof RoomEntitySchema>

export class RoomEntity  extends BaseEntity<RoomEntity>(RoomEntitySchema) implements IRoom{

  $id: typeof RoomEntitySchema._input.$id
  id: typeof RoomEntitySchema._input.id
  roomName: typeof RoomEntitySchema._input.roomName
  createdBy: typeof RoomEntitySchema._input.createdBy
  createdAt: typeof RoomEntitySchema._input.createdAt
  expirationDate: typeof RoomEntitySchema._input.expirationDate
  roomType: typeof RoomEntitySchema._input.roomType
  members: typeof RoomEntitySchema._input.members = []
  messages: typeof RoomEntitySchema._input.messages
  receiverId: typeof RoomEntitySchema._input.receiverId

  constructor(data: IRoom) {
    super();
    Object.assign(this, data)

    try {
      if(data.roomType == RoomType.Direct) {
        this.setName()

        if(this.members.length == 2) {
          if(!this.$id ) {
            this.$id = this.getReceiverId()
          }

          if(!this.receiverId  ) {
            this.setReceiver()
          }
        }


      }
    } catch (e) {
      console.error('eee', e, this)
    }

  }

  // direct room only
  getReceiverId() {
    const receiver = this.members?.find((e) => e.user.wxUserId != SessionStore.user.UserId)
    if(receiver) {
      return receiver.user.wxUserId.toString()
    } else {
      // Logger.error('cant get receiver Id from Room.getReceiverId '+ this.id, this)
      return undefined
    }

  }

  // direct room only
  setLocalId() {
    this.$id = this.getReceiverId()
  }

  // direct room only
  setReceiver() {
    const receiverId = this.getReceiverId()
    if(receiverId) {
      this.receiverId = parseInt(receiverId)
    } else {
      // Logger.error('cant set receiver Id from Room.setReceiver', this)
    }

  }

  // direct room only
  setName() {
    const userChatName = this.members?.find((e) => e.user.wxUserId != SessionStore.user.UserId)
    if(userChatName) {
      this.roomName = userChatName.user.wxFullName
    }
  }

  hasLastMessage() {
    return this.messages?.length >= 1
  }
}
