import { z } from "zod";
import { EntityTable } from 'Dexie';
import { RoomType } from "src/app/core/chat/entity/group";
import { MessageTableSchema } from "./message";
import { IDBoolean } from "../../../type";

export const RoomTableSchema = z.object({
  $id: z.string().optional(),
  id: z.string().optional(),
  roomName: z.string(),
  createdBy: z.object({
    wxUserId: z.number(),
    wxFullName: z.string(),
    wxeMail: z.string().email(),
    userPhoto: z.string().nullable().optional()// api check
  }),
  createdAt: z.any(),
  expirationDate: z.string().nullable().optional(),
  roomType: z.nativeEnum(RoomType),
  messages: MessageTableSchema.array().optional(),
  bold: z.number().optional(),
  local: z.nativeEnum(IDBoolean).optional(),
  receiverId: z.number().optional()
})

export type RoomTable = z.infer<typeof RoomTableSchema>
export type DexieRoomsTable = EntityTable<RoomTable, '$id'>;
export const RoomTableColumn = '$id, id, createdBy, receiverId, roomName, roomType, expirationDate, lastMessage'
