
export type MessageType = {
  message: string;
  context?: string;
  obj?: object;
};

function getCurrentTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export class ColoredLoggerService {

  constructor() {}

  static log(message: string): void {
    console.log(
      `[${getCurrentTime()}] %cINFO : `, // Console Message
      'color: #00897B', // CSS Style
      message
    );
  }

  static  debug({ message, context, obj = {} }: MessageType): void {
    console.info(
      `[${getCurrentTime()}] %cINFO : `, // Console Message
      'color: #039BE5', // CSS Style
      Object.assign(obj, { context, createdAt: getCurrentTime(), message })
    );
  }

  static  info({ message, context, obj = {} }: MessageType): void {
    console.info(
      `[${getCurrentTime()}] %cINFO : `, // Console Message
      'color: #039BE5', // CSS Style
      Object.assign(obj, { context, createdAt: getCurrentTime(), message })
    );
  }

  static  warn({ message, context, obj = {} }: MessageType): void {
    console.warn(
      `[${getCurrentTime()}] %cWARN : `, // Console Message
      'color: #FB8C00', // CSS Style
      Object.assign(obj, { context, createdAt: getCurrentTime(), message })
    );
  }

  static  error(error: any = "", message?: string, context = ""): void {

    console.error(
      `[${getCurrentTime()}] %cERROR : `, // Console Message
      'color: #E53935', // CSS Style
      message+', '+ context,
      '\n',
      error,
      '\n',
    );
  }

  fatal(error?: any, message?: string, context?: string): void {}
}
