import { DistributionTable, DistributionTableSchema,DistributionTableColumn } from "src/app/infra/database/dexie/instance/chat/schema/destribution";
import { chatDatabase } from "src/app/infra/database/dexie/instance/chat/service";
import { DexieRepository } from "src/app/infra/repository/dexie/dexie-repository.service";


export class  DistributionLocalRepository extends DexieRepository<DistributionTable, DistributionTable>  {
  constructor() {
    super(chatDatabase.distribution, DistributionTableSchema)

    chatDatabase.distribution.hook("creating", function (primKey, obj, transaction) {
      obj.$messageIdMemberId = `${obj.messageId}${obj.memberId}`
    });
  }
}
