import { Injectable } from '@angular/core';

import { Dexie, EntityTable, liveQuery } from 'Dexie';
import { any, z } from 'zod';
import { err, ok } from 'neverthrow';
import { from } from 'rxjs';
import { SharedCalendarListItemOutputDTO } from '../dto/sharedCalendarOutputDTO';


const tableScharedCalendar = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string().email(),
  role: z.string(),
  roleId: z.number(),
  shareType: z.number(),
  date: z.string(),
})
export type TableSharedCalendar = z.infer<typeof tableScharedCalendar>

// Database declaration (move this to its own module also)
export const AgendaDataSource = new Dexie('AgendaDataSource') as Dexie & {
  shareCalendar: EntityTable<TableSharedCalendar, 'wxUserId'>;
};


AgendaDataSource.version(1).stores({
  shareCalendar: 'wxUserId, wxFullName, wxeMail, role, roleId, shareType, startDate, endDate'
});

@Injectable({
  providedIn: 'root'
})
export class AgendaLocalDataSourceService {

  constructor() { }

  async bulkCreate(data: SharedCalendarListItemOutputDTO[]) {
    // db.eve
    try {
      const result = await AgendaDataSource.shareCalendar.bulkAdd(data)
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }

  async clearAndAddRecords(data: SharedCalendarListItemOutputDTO[]) {
    try {
      await AgendaDataSource.transaction('rw', AgendaDataSource.shareCalendar, async () => {
      // Clear existing records from myTable
      await AgendaDataSource.shareCalendar.clear();

      await AgendaDataSource.shareCalendar.bulkAdd(data);

      });
      // console.log('Clear and add operations completed within transaction.');
    } catch (error) {
      console.error('Error performing transaction:', error, data);
    }
  }


  async createCalendar(data: SharedCalendarListItemOutputDTO) {
    // db.eve
    try {
      const result = await AgendaDataSource.shareCalendar.add(data)
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }

  async removeCalendar(data: SharedCalendarListItemOutputDTO) {
    // db.eve
    try {
      const result = await AgendaDataSource.shareCalendar.delete(data.wxUserId)
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }

  clearSharedCalendar() {
    // db.eve
    try {
      const result = AgendaDataSource.shareCalendar.clear()
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }

  async geCalendars() {
    return await AgendaDataSource.shareCalendar.toArray()
  }

  getShareCalendarItemsLive() {
    return from(liveQuery( () => {
      return  AgendaDataSource.shareCalendar.toArray()
    }))
  }

  // New method to get calendars by wxUserId
  async getCalendarByUserId(wxUserId: number) {
    try {
      const result = await AgendaDataSource.shareCalendar.get(wxUserId)
      if(!result) {
        const list = await AgendaDataSource.shareCalendar.toArray()
        const found = list.find(e => e.wxUserId == wxUserId)
        if(found) {
          return ok(found)
        } else {
          return err('404')
        }
      } else {
        return ok(result)
      }
    } catch (e) {
      return err(false);
    }
  }
}
