import { Injectable } from '@angular/core';
import { z } from 'zod';
import { MessageSocketRepositoryService } from '../../../../module/chat/data/repository/message/message-live-signalr-data-source.service';
import { XTracerAsync, TracingType } from 'src/app/services/monitoring/opentelemetry/tracer';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';

export const MessageDeleteInputDTOSchema = z.object({
  requestId: z.string().optional(),
  roomId: z.string(),
  messageId: z.string(),
  senderId: z.number(),
});
export type MessageDeleteInputDTO = z.infer<typeof MessageDeleteInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class MessageDeleteLiveUseCaseService {
  constructor(
    public repository: IMessageSocketRepository
  ) { }

  @XTracerAsync({name:'MessageDeleteLiveUseCaseService', module:'chat',  bugPrint: true, waitNThrow: 5000})
  async execute(data: MessageDeleteInputDTO, tracing?: TracingType) {
    tracing.log('MessageDeleteLiveUseCaseService payload', {
      data: data
    })
    return this.repository.sendMessageDelete(data)
  }
}
