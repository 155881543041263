
import { MemberTable } from "src/app/infra/database/dexie/instance/chat/schema/members";
import { RoomByIdMemberItemOutputDTO } from "../usecase/room/room-get-by-id-use-case.service";

export function MemberListMapper(outputDto: RoomByIdMemberItemOutputDTO, roomId: string): MemberTable {
  return {
    roomId: roomId,
    wxUserId: outputDto.user.wxUserId,
    wxFullName: outputDto.user.wxFullName,
    wxeMail: outputDto.user.wxFullName,
    userPhoto: outputDto.user.userPhoto,
    joinAt: outputDto.joinAt,
    isAdmin: outputDto.isAdmin
  }
}
