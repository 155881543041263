import { Injectable, NgZone } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { v4 as uuidv4 } from 'uuid'
import { SHA1 } from 'crypto-js';
import { XTracer } from '../services/monitoring/opentelemetry/tracer';
@Injectable({
  providedIn: 'root'
})
export class NotificationHolderService {

  notificationList = []

  private _notificationList = []


  constructor(
    private storageService: StorageService,
    private zone: NgZone,
  ) {

    try {
      this.restore()
    } catch (error) { }

  }


  reverse() {
    this.notificationList = this._notificationList.reverse()
  }

  restore() {
    this.storageService.get("Notifications").then((store) => {
      if (Array.isArray(store)) {
        this._notificationList = store
        this.reverse()
      }
    }).catch((error) => { })
  }

  save() {
    this.storageService.store("Notifications", this._notificationList)
  }

  notificationObject(notification) {
    const element = notification
    const i = this._notificationList.length + 1

    let notificationObject;

    if (element.notification) {

      notificationObject = {
        id: notification?.id || uuidv4(),
        index: i,
        title: element.notification.title,
        Service: element.data.Service || element.data.service,
        Object: element.data.Object || element.data.object,
        IdObject: element.data.IdObject || element.data.idObject,
        FolderId: element.data.FolderId || element.data.folderId,
        body: element.notification.body,
        dateInit: this.getFormatedTime(element.data.dateInit),
        dateEnd: this.getFormatedTime(element.data.dateEnd),
        Location: element.data.Location,
        TypeAgenda: element.data.TypeAgenda,
        Role: element.data.Role,
        Status: element.data.Status,
        read: false,
      }

    } else if (element.data) {
      notificationObject = {
        id: notification?.id || uuidv4(),
        index: i,
        title: element.title,
        Service: element.data.Service || element.data.service,
        Object: element.data.Object || element.data.object,
        IdObject: element.data.IdObject || element.data.idObject,
        FolderId: element.data.FolderId || element.data.folderId,
        body: element.body,
        dateInit: this.getFormatedTime(element.data.dateInit),
        dateEnd: this.getFormatedTime(element.data.dateEnd),
        Location: element.data.Location,
        TypeAgenda: element.data.TypeAgenda,
        Role: element.data.Role,
        Status: element.data.Status,
        read: false,
      }

    } else {
      {
        notificationObject = {
          id: notification?.id || uuidv4(),
          FolderId: element.FolderId || element.data.folderId,
          IdObject: element.IdObject || element.data.idObject,
          Location: element.Location,
          Object: element.Object || element.data.object,
          Role: element.Role,
          Service: element.Service || element.data.service,
          Status: element.Status,
          TypeAgenda: element.TypeAgenda,
          body: element.body,
          dateEnd: element.dateEnd,
          dateInit: element.dateInit,
          index: element.index,
          title: element.title,
          read: false,
        }
      }
    }

    notificationObject.hashCode = (SHA1(notification)).toString()

    return notificationObject
  }


  stractureNotificationObject(notification) {
    const element = notification
    let notificationObject;

    if (element.notification) {

      notificationObject = {
        id: notification?.id || uuidv4(),
        title: element.notification.title,
        Service: element.data.Service || element.data.service,
        Object: element.data.Object || element.data.object,
        IdObject: element.data.IdObject || element.data.idObject,
        FolderId: element.data.FolderId || element.data.folderId,
        body: element.notification.body,
        dateInit: this.getFormatedTime(element.data.dateInit),
        dateEnd: this.getFormatedTime(element.data.dateEnd),
        Location: element.data.Location,
        TypeAgenda: element.data.TypeAgenda,
        Role: element.data.Role,
        Status: element.data.Status,
        read: false,
      }

    } else if (element.data) {
      notificationObject = {
        id: notification?.id || uuidv4(),
        title: element.title,
        Service: element.data.Service  || element.data.service,
        Object: element.data.Object || element.data.object,
        IdObject: element.data.IdObject || element.data.idObject,
        FolderId: element.data.FolderId || element.data.folderId,
        body: element.body,
        dateInit: this.getFormatedTime(element.data.dateInit),
        dateEnd: this.getFormatedTime(element.data.dateEnd),
        Location: element.data.Location,
        TypeAgenda: element.data.TypeAgenda,
        Role: element.data.Role,
        Status: element.data.Status,
        read: false,
      }

    } else {
      {
        notificationObject = {
          id: notification?.id || uuidv4(),
          FolderId: element.FolderId || element.data.folderId,
          IdObject: element.IdObject || element.data.idObject,
          Location: element.Location,
          Object: element.Object || element.data.object,
          Role: element.Role,
          Service: element.Service || element.data.service,
          Status: element.Status,
          TypeAgenda: element.TypeAgenda,
          body: element.body,
          dateEnd: element.dateEnd,
          dateInit: element.dateInit,
          index: element.index,
          title: element.title,
          read: false,
        }
      }
    }

    notificationObject.hashCode = (SHA1(notification)).toString()

    return notificationObject
  }


  addNotification(notification) {

    console.log(notification)
    let notificationObject = this.notificationObject(notification)

    //alert("add notification")
    //alert(JSON.stringify(notificationObject))

    if (!this.notificationExist(notificationObject)) {
      this._notificationList.push(notificationObject)

      this.zone.run(() => {
        this.reverse()
        this.save()
      })

    } else {
      console.log('duplicate', notification, this._notificationList)
    }

  }
  notificationExist(notificationObject) {
    const notification = this._notificationList.find(item => {
      return item.id === notificationObject.id;
    });

    if (notification?.id) {
      return true
    } else {
      return false
    }
  }


  getFormatedTime(dateString) {
    var date = new Date(dateString);
    var hours = date.getHours() /* > 12 ? date.getHours() - 12 : date.getHours() */;
    var am_pm = date.getHours() >= 12 ? "pm" : "am";
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let time = hours + ":" + minutes /* + " " + am_pm */;
    return time;
  }

  removeNotification(notification) {
    this._notificationList = this._notificationList.filter((e) => {
      return e.index != notification.index
    })


    this.zone.run(() => {
      this.save()
      this.reverse()
    })
  }


  clear() {
    this._notificationList = []
    this.zone.run(() => {
      this.save()
      this.reverse()
    })
  }


  PhoneClickNotification(ClickedNotificationObject) {
    //alert("PhoneClickNotification")

    //alert(JSON.stringify(ClickedNotificationObject))
    const { hashCode, id } = this.notificationObject(ClickedNotificationObject)



    //alert(id)
    //alert(JSON.stringify(this._notificationList))
    // let foundNotification = this._notificationList.find( (e) => {
    //   return e.hashCode == hashCode
    // })

    //if(foundNotification) {
    // this.removeNotification(foundNotification)
    //} else {
    console.log({ ClickedNotificationObject, list: this._notificationList })

    let foundNotification = this._notificationList.find((e) => {
      return e.id == id
    })

    if (foundNotification) {
      this.removeNotification(foundNotification)
    } else {
      console.log({ ClickedNotificationObject, list: this._notificationList })
    }
    //}
  }

}
