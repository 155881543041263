import { Injectable, Input } from '@angular/core';
import { MessageLocalDataSourceService } from '../../../../module/chat/data/repository/message/message-local-data-source.service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { ParamsValidation } from 'src/app/services/decorators/validate-schema.decorator';
import { MessageEntitySchema } from 'src/app/core/chat/entity/message';
import { z } from 'zod';

const SocketMessageCreateOutputSchema = MessageEntitySchema.pick({
  id: true,
  attachments: true,
  canEdit: true,
  editedAt: true,
  info: true,
  isDeleted: true,
  message: true,
  messageType: true,
  oneShot: true,
  reactions: true,
  receiverId: true,
  requireUnlock: true,
  roomId: true,
  sender: true,
  sending: true,
  sentAt: true,
})

export type ISocketMessageCreateOutput = z.infer<typeof SocketMessageCreateOutputSchema>

@Injectable({
  providedIn: 'root'
})
export class SocketMessageCreateUseCaseService {

  private broadcastChannel: BroadcastChannel;
  private processedMessages = new Set<string>();

  constructor(
    private messageLocalDataSourceService: MessageLocalDataSourceService,
  ) {
    this.broadcastChannel = new BroadcastChannel('socket-message');
    this.broadcastChannel.onmessage = (event) => {
      console.log('hello', event.data)
      const messageId = event.data;
      this.processedMessages.add(messageId);
    };

    // this.broadcastChannel.postMessage('incomingMessage.id');
  }

  @XTracerAsync({name:'Socket-Message-Create-UseCase', module:'chat',  bugPrint: true})
  async execute(input: ISocketMessageCreateOutput, tracing?: TracingType) {

    this.broadcastChannel.postMessage(input.id);
    ParamsValidation(SocketMessageCreateOutputSchema, input, tracing)

    const incomingMessage =  {
      ...input,
      sending: false
    }

    // Check if the message ID already exists in the processedMessages set
    if (this.processedMessages.has(incomingMessage.id)) {
      console.warn(`Duplicate message detected: ${incomingMessage.id}`);
      return; // Exit early to prevent duplicate handling
    } else {
      console.log('no duplicate')
    }

    // Add the message ID to the processedMessages set and broadcast it
    this.processedMessages.add(incomingMessage.id);


    // console.log('create message', { incomingMessage });

    tracing?.addEvent("Message Create start");
    const result = await this.messageLocalDataSourceService.insert(incomingMessage);
    tracing?.addEvent("Message Create end");

    if (result.isOk()) {
      // Optionally, you can handle post-insertion logic here
    } else {
      tracing?.addEvent("error while creating message");
      tracing.log("error while creating message", {
        error: result.error
      });
    }
  }
}
