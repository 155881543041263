import { Injectable } from '@angular/core';
import { z } from 'zod';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { SessionStore } from 'src/app/store/session.service';
import { RoomType } from 'src/app/core/chat/entity/group';
import { IDBoolean } from 'src/app/infra/database/dexie/type';


export const RoomCreateLocalDirectMessageInputDTOSchema = z.object({
  roomName: z.string(),
  members: z.array(z.number()),
  receiverId: z.number().optional(),
});
export type RoomCreateLocalDirectMessageInputDTOInputDTO = z.infer<typeof RoomCreateLocalDirectMessageInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class RoomCreateLocalDirectMessageService {

  constructor(
    private roomLocalRepository: IRoomLocalRepository
  ) { }

  async execute(input: RoomCreateLocalDirectMessageInputDTOInputDTO) {
    return await this.roomLocalRepository.insert({
      roomName: input.roomName,
      bold: 0,
      createdAt: new Date().toISOString(),
      createdBy: {
        userPhoto: '',
        wxeMail: SessionStore.user.Email,
        wxFullName: SessionStore.user.FullName,
        wxUserId: SessionStore.user.UserId
      },
      $id: input.receiverId.toString(),
      messages: [],
      roomType: RoomType.Direct,
      local: IDBoolean.true,
      receiverId: input.receiverId,
    })
  }
}
