import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PickerController, PopoverController } from '@ionic/angular';
import { GroupDurationPage } from 'src/app/shared/popover/group-duration/group-duration.page';
import { ThemeService } from 'src/app/services/theme.service';
import { SessionStore } from 'src/app/store/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { catchError } from 'rxjs/operators';
import { ChatServiceService } from 'src/app/module/chat/domain/chat-service.service'
import { UDate } from 'src/app/utils/date';
import { HttpErrorResponse } from '@angular/common/http';
import { z } from 'zod';
import { GroupContactsPage, IGroupContactsPageOutPutSchema } from '../group-contacts/group-contacts.page';


const NewGroupModalOutPutSchema = z.object({
  roomId: z.string().optional()
})
export type INewGroupModalOutPut = z.infer<typeof NewGroupModalOutPutSchema>

@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.page.html',
  styleUrls: ['./new-group.page.scss'],
})
export class NewGroupPage implements OnInit {
  isGroupCreated: boolean;
  showLoader: boolean;
  expirationDate: Date  = null;
  displayDuration = ''
  showDuration: boolean;
  selectedDuration = ['', '', ''];
  thedate: any;
  groupName: string;
  documents: any;

  constructor(
    private pickerController: PickerController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private navParams: NavParams,
    public ThemeService: ThemeService,
    public chatSystemService: ChatServiceService,
    private toastService: ToastService,
  ) {
    
    this.isGroupCreated = false;
    this.groupName = this.navParams.get('name');
    this.documents = this.navParams.get('documents');
  }

  ngOnInit() {}

  _ionChange(event) {
    this.showDuration = event.detail.checked;

    if (event.detail.checked) {
      this.thedate = new Date();
    }
    else {
      this.thedate = '';
    }
  }
  close() {
    this.modalController.dismiss();
  }

  async createGroup() {

    const result = await this.chatSystemService.createRoom({
      roomName: this.groupName,
      createdBy: SessionStore.user.UserId,
      roomType: 0,
      expirationDate: this.expirationDate?.toISOString() ?  UDate.GetDateWithTimeZone(this.expirationDate) :  null,
      members: []
    })

    if(result.isOk()) {
      await this.chatSystemService.getRoomById(result.value.id)
      this.addContacts(result.value.id)
      //this.addGroupMessage.emit(result.value.id);
    } else if(result.error instanceof HttpErrorResponse) {
      // this.httpErrorHandle.httpStatusHandle(result.error)
    } else  {
      this.toastService._badRequest('Por favor, contacta um administrador.');
    }

  }



  async addContacts(roomId:string) {

    let name = this.groupName.split(' ').join('-');

    const modal = await this.modalController.create({
      component: GroupContactsPage,
      componentProps: {
        roomId: roomId,
      },
      cssClass: 'contacts',
      backdropDismiss: false
    });


    modal.onDidDismiss<IGroupContactsPageOutPutSchema>().then(result => {

      this.modalController.dismiss({roomId})

    });
    await modal.present();
  }

  async setDuration(ev: any) {
    const popover = await this.popoverController.create({
      component: GroupDurationPage,
      cssClass: 'group-duration',
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  async showPicker() {
    const picker = await this.pickerController.create({
      cssClass: '',
      buttons: [
        {
          text: 'Cancelar', role: 'cancel', cssClass: 'btn-cancel'
        },
        {
          text: 'Ok',
          cssClass: 'btn-cancel',
          handler:(value:any)=>{

            let now = new Date();
            this.expirationDate = new Date(now.getFullYear(), now.getMonth(),  now.getDate() + value.days.value, now.getHours() + value.hours.value, now.getMinutes() + value.minutes.value, now.getSeconds(), now.getMilliseconds())

            this.selectedDuration = [
              value.days.value,
              value.hours.value,
              value.minutes.value,
            ]

            if(value.days.value != null && value.hours.value != null && value.minutes.value != null){
              if(value.days.value > 0){
                if(value.days.value == 1){
                  if(value.hours.value == 1){
                    this.displayDuration = value.days.value  + " day " +
                    value.hours.value + " hora " +
                    value.minutes.value + " minutos";
                  }
                  else{
                    this.displayDuration = value.days.value  + " days " +
                    value.hours.value + " horas " +
                    value.minutes.value + " minutos";
                  }
                }
                else{
                  if(value.hours.value == 1){
                    this.displayDuration = value.days.value  + " days " +
                    value.hours.value + " hora " +
                    value.minutes.value + " minutos";
                  }
                  else{
                    this.displayDuration = value.days.value  + " days " +
                    value.hours.value + " horas " +
                    value.minutes.value + " minutos";
                  }
                }
              }
              else{
                if(value.hours.value == 1){
                  this.displayDuration = value.hours.value + " hora " +
                  value.minutes.value + " minutos";
                }
                else{
                  this.displayDuration = value.hours.value + " horas " +
                  value.minutes.value + " minutos";
                }
              }
            }
          },
        },
      ],
      columns: [
        {
          name: 'days',
          prefix: 'Dias',
          options: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
          ]
        },
        {
          name: 'hours',
          prefix: 'Horas',
          options: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '6', value: 6 },
            { text: '7', value: 7 },
            { text: '8', value: 8 },
          ]
        },
        {
          name: 'minutes',
          prefix: 'Minutos',
          selectedIndex: 0,
          options: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: '30', value: 30 },
            { text: '35', value: 35 },
            { text: '45', value: 45 },
            { text: '50', value: 50 },
            { text: '55', value: 55 },
          ]
        }
      ]
    });
    await picker.present();
    picker.onDidDismiss().then(async data => {
      let day = await picker.getColumn('days');
      let hour = await picker.getColumn('hours');
      let minutes = await picker.getColumn('minutes');
    });

  }

}
