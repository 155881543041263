import { Injectable } from '@angular/core';
import { MessageSocketRepositoryService } from '../../../../module/chat/data/repository/message/message-live-signalr-data-source.service';
import { XTracerAsync, TracingType } from 'src/app/services/monitoring/opentelemetry/tracer';
import { z } from 'zod';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';

export const MessageMarkAsReadInputSchema = z.object({
  memberId: z.number(),
  messageId: z.string(),
  roomId: z.string(),
  requestId: z.string()
})

export type MessageMarkAsReadInput = z.infer<typeof MessageMarkAsReadInputSchema>

@Injectable({
  providedIn: 'root'
})
export class MessageMarkAsReadUseCaseService {

  constructor(
    private MessageSocketRepositoryService: IMessageSocketRepository,
  ) { }


  @XTracerAsync({name:'MessageMarkAsReadUseCaseService', module:'chat',  bugPrint: true, waitNThrow: 5000})
  async execute(sendReadAt: MessageMarkAsReadInput, tracing?: TracingType) {
    const result = await this.MessageSocketRepositoryService.sendReadAt(sendReadAt as any)

    if(result.isErr()) {
      tracing.setAttribute('meesageId', sendReadAt.messageId)
      tracing.hasError('failed to read message')
    }

    return result

  }
}
