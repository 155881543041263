import { Injectable } from '@angular/core';
import { momentG } from 'src/plugin/momentG';
import { ProcessesService } from 'src/app/services/processes.service';
import { ExpedienteGdStore } from 'src/app/store/expedientegd-store.service';
import { DeplomasStore } from 'src/app/store/deplomas.service';
import { PendentesStore } from 'src/app/store/pendestes-store.service';
import { DespachoStore } from 'src/app/store/despacho-store.service';
import { TotalDocumentStore } from 'src/app/store/total-document.service';
import { DespachosprStore } from 'src/app/store/despachospr-store.service';
import { EventoAprovacaoStore } from 'src/app/store/eventoaprovacao-store.service';
import { PedidosStore } from 'src/app/store/pedidos-store.service';
import { ExpedienteTaskPipe } from 'src/app/pipes/expediente-task.pipe';
import { DespachoService } from 'src/app/Rules/despacho.service';
import { CustomTaskPipe } from '../pipes/custom-task.pipe';
import { SessionStore } from 'src/app/store/session.service';
import { customTask } from '../models/dailyworktask.model';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid'
import { ChangeProfileService } from './change-profile.service';
import { PermissionService } from './permission.service';
import { AgendaDataRepositoryService } from 'src/app/module/agenda/data/repository/agenda-data-repository.service';
import { SortService } from './functions/sort.service';
import { TracingType, XTracerAsync } from './monitoring/opentelemetry/tracer';
import { EEventFilterStatus } from '../module/agenda/data/dto/enums';
@Injectable({
  providedIn: 'root'
})
export class TaskService {


  read = 0
  unread = 0
  deadline = 0
  new = 0
  overdueTasks = 0

  expedientegbstore = ExpedienteGdStore;
  pendentesstore = PendentesStore;
  despachoStore = DespachoStore;
  eventoaprovacaostore = EventoAprovacaoStore;
  pedidosstore = PedidosStore;
  despachoprstore = DespachosprStore;
  totalDocumentStore = TotalDocumentStore
  deplomasStore = DeplomasStore

  SessionStore = SessionStore

  AllProcess = []
  showLoaderNum: number = 0
  loadCount = false
  loadNum = 0

  expedienteTaskPipe = new ExpedienteTaskPipe();
  customTaskPipe = new CustomTaskPipe()

  callbacks: {
    [key: string]: {
      funx: Function
      id: string
    }
  } = {}


  constructor(
    private processesbackend: ProcessesService,
    private despachoRule: DespachoService,
    private router: Router,
    private changeProfileService: ChangeProfileService,
    public p: PermissionService,
    public AgendaDataRepositoryService: AgendaDataRepositoryService,
    private sortService: SortService,
  ) {

    this.changeProfileService.registerCallback(() => {
      this.updateCount()
      this.runCallback();
    })

    window['all-process-gabinete'] = () => {
      this.updateAllProcess();
      this.updateCount();
      this.runCallback();
    }


    this.updateAllProcess();
    this.updateCount()
    this.runCallback();

    document.addEventListener('resume', () => {
      if(window['all-process-gabinete']) {
        window['all-process-gabinete']()
      }
    });
   }

   registerCallback({funx, id = uuidv4()}) {

    this.callbacks[id] = { funx, id}

    return {
      delete: ()=> {
        delete this.callbacks[id]
      }
    }
  }

  runCallback() {
    for (const [key, value] of Object.entries(this.callbacks)) {
      value.funx()
    }
  }


  deadlineIsToday(isoDateString:string) {

    if(!isoDateString) {
      return false
    }


    return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(isoDateString), 'dd MMMM yyyy')
  }

  overdueTask(isoDateString:string) {
    if(!isoDateString) {
      return false
    }
    return (new Date()).getTime()  > (new Date(isoDateString)).getTime()
  }

  lessThen24Hours(isoDateString:string) {

    if(!isoDateString) {
      return false
    }

    const creationDate = new Date(isoDateString)
    return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(creationDate), 'dd MMMM yyyy')
  }

  filter(item, attribute) {
    if(attribute == 'Para hoje') {
      return this.deadlineIsToday(item.Deadline || item.deadline)
    } else if (attribute == 'Novos') {
      return this.lessThen24Hours(item.TaskReceiveDate || item.taskReceiveDate)
    } else if (attribute == 'Lidos') {
      return item.TaskStatus == 'Open' || item.taskStatus == 'Open'
    } else if (attribute == 'Não lidos') {
      return !(item.TaskStatus == 'Open' || item.taskStatus == 'Open')
    } else if (attribute == 'OverdueTasks') {

      return this.overdueTask(item.Deadline || item.deadline)

    }
    return true
  }


  seen(item) {
    return item.TaskStatus == 'Open' || item.taskStatus == 'Open'
  }

  updateAllProcess = () => {
    this.AllProcess = this.sortArrayISODate(this.despachoprstore.list.concat(this.deplomasStore.diplomasAssinadoList as any).concat(this.deplomasStore.diplomasParaAssinarList as any).concat(this.deplomasStore.DiplomaGerarList as any).concat(this.deplomasStore.diplomasReviewList)
    .concat(this.eventoaprovacaostore.contactList as any).concat(this.expedientegbstore.list as any).concat(this.despachoStore.list as any).concat(this.pedidosstore.listparecer as any).concat(this.pedidosstore.listdeferimento as any)
    .concat(this.pendentesstore.list as any))

  }

  sortArrayISODate(myArray: any): any[] {

    return myArray.sort((a,b) =>
      Date.parse(b.CreateDate || b.taskStartDate || b.TaskStartDate || b.TaskReceiveDate || b.taskReceiveDate )
      -
      Date.parse(a.CreateDate ||  b.taskStartDate || b.TaskStartDate || a.TaskReceiveDate || a.taskReceiveDate ))
  }

  reorderList(orderBy: string, list) {

    if(orderBy == 'recent') {
      return this.sortArrayISODate(list).reverse();
    } else {
      return this.sortArrayISODate(list)
    }
  }



  updateCount = () => {
    this.read = this.AllProcess.filter((item) => this.filter(item,'Lidos')).length
    this.unread = this.AllProcess.filter((item) => this.filter(item,'Não lidos')).length
    this.deadline = this.AllProcess.filter((task) => this.deadlineIsToday(task.Deadline || task.deadline)).length
    this.new = this.AllProcess.filter((task) => this.lessThen24Hours(task.TaskReceiveDate || task.taskReceiveDate)).length
    this.overdueTasks = this.AllProcess.filter((task) => this.overdueTask(task.Deadline || task.deadline)).length
  }



  async LoadTask() {


    try {
      await this.loadExpedientes()
      this.loadCount = true;
    } catch(error) {
      this.loadCount = true;
    }

    this.loadNum++;
    await this.loadDespachos();



    await this.loadPedidos()
    await this.loadPendestes()

    await this.loadDiplomas()


    await this.loadEventosParaAprovacao()
    this.loadCount = true
  }

  async loadDespachos() {
    this.showLoaderNum++

    try {

      let despachos = await this.despachoRule.getList({ updateStore: true })
      if (despachos) {
        this.despachoStore.reset(despachos)
      }

    } catch(error) {}

    this.showLoaderNum--
  }

  async loadExpedientes() {

    this.showLoaderNum++

    try {
      let expedientes;
      expedientes = await this.processesbackend.GetTaskListExpediente(false).toPromise()

      expedientes = (expedientes || [] ).filter(data => data.workflowInstanceDataFields.Status == "" || data.workflowInstanceDataFields.Status == "Active")

      expedientes = expedientes.filter((item) => {
        return item.activityInstanceName != 'Retificar Expediente'
      });

      expedientes = expedientes.map((element) => this.expedienteTaskPipe.transform(element));

      expedientes = this.sortArrayISODate(expedientes).reverse()

      this.expedientegbstore.reset(expedientes);
    } catch (error) {

    }

    this.showLoaderNum--

  }

  async loadPedidos() {
    this.showLoaderNum++

    try {
      let pareceres = await this.processesbackend.GetTasksList("Pedido de Parecer", false).toPromise();
      let pareceresPr = await this.processesbackend.GetTasksList("Pedido de Parecer do Presidente", false).toPromise();

      let allParecer = pareceres.concat(pareceresPr);

      this.pedidosstore.countparecer = Object.keys(allParecer.filter(data => data.workflowInstanceDataFields.Status == "Active")).length;

      this.pedidosstore.resetparecer(allParecer.filter(data => data.workflowInstanceDataFields.Status == "Active").map((element) => {
        return this.customTaskPipe.transform(element)
      }));

      let deferimentos = await this.processesbackend.GetTasksList("Pedido de Deferimento", false).toPromise();
      this.pedidosstore.countdeferimento = Object.keys(deferimentos.filter(data => data.workflowInstanceDataFields.Status == "Active")).length
      this.pedidosstore.resetdeferimento(deferimentos.filter(data => data.workflowInstanceDataFields.Status == "Active").map((element) => {
        return this.customTaskPipe.transform(element)
      }));
    } catch (error) {}

    this.showLoaderNum--
  }


  async loadPendestes() {
    this.showLoaderNum++

    try {

      let pendentes = await this.processesbackend.GetPendingTasks(false).toPromise();
      this.pendentesstore.count = Object.keys(pendentes).length;

      pendentes = pendentes.map((element)=> this.customTaskPipe.transform(element))

      this.pendentesstore.reset(pendentes);
    } catch (error) {

    }

    this.showLoaderNum--

  }


  @XTracerAsync({name:'taskService/loadEventToAprove', bugPrint: true})
  async loadEventosParaAprovacao(tracing?: TracingType) {
    this.showLoaderNum++
    // console.log('PR')
    // PR dont have event to approve
    /* if(this.SessionStore.user.Profile != 'PR') { */
      try {

        const list = await this.AgendaDataRepositoryService.geCalendars()

        for(let calendar of list) {

          let allEvents =  await this.AgendaDataRepositoryService.eventToApproveList({
            userId: calendar.wxUserId,
            status: EEventFilterStatus.Pending
          }, tracing)

          if(allEvents.isOk()) {
            tracing.setAttribute('outcome', 'success')

            let eventsList = []
            if(allEvents.value.length >= 1) {
              eventsList = this.sortService.sortArrayByDate(allEvents.value).reverse();
              this.eventoaprovacaostore.save(calendar.wxUserId as any, eventsList)
            } else {
              this.eventoaprovacaostore.save(calendar.wxUserId as any, [])
            }
          } else {

            tracing.setAttribute('outcome', 'failed')
          }
        }
      } catch (error) {

      }
   /*  } */

     this.showLoaderNum--

  }



  async loadDiplomas() {
    if(this.p.userPermission([this.p.permissionList.Gabinete.md_tasks]) || this.p.userPermission([this.p.permissionList.Gabinete.pr_tasks])) {
      this.showLoaderNum++

      try {

        let depachoAPI  = await this.processesbackend.GetTasksList("Despacho do Presidente da República", false).toPromise();


        if(SessionStore.user.Profile != 'PR') {

          let depacho: any = depachoAPI.reverse().filter(data => data.activityInstanceName == "Tarefa de Despacho");
          depacho = depacho.filter(data => data.workflowInstanceDataFields.Status == "Active");

          depacho = depacho.map((e)=> this.customTaskPipe.transform(e))

          this.despachoprstore.reset(depacho)

        } else if(SessionStore.user.Profile == 'PR') {

          let depacho: any = depachoAPI.filter(data => data.activityInstanceName == "Concluir Despacho").filter(data => data.workflowInstanceDataFields.Status == "Active")
          depacho = depacho.map((e)=> this.customTaskPipe.transform(e))
          this.despachoprstore.reset(depacho)

        }


        let gerarDiploma =  depachoAPI.reverse().filter(data => data.activityInstanceName == "Gerar Diploma" || data.activityInstanceName == "Retificar Diploma");
        let diplomasList = []

        gerarDiploma.forEach(element => {
          let task: customTask = this.customTaskPipe.transform(element);
          diplomasList.push(task);
        });

        diplomasList = diplomasList
        this.deplomasStore.resetDiplomaGerar(diplomasList);


        let diplomasValidar = depachoAPI.filter(data => data.activityInstanceName == "Revisar Diploma");

        diplomasValidar = diplomasValidar.filter(data => data.activityInstanceName != "Tarefa de Despacho");


        this.deplomasStore.resetDiplomasReview(diplomasValidar.map((element)=> {

          let date = new Date(element.taskStartDate);
          date.setMonth(date.getMonth() + 1);
          let taskDate = date.getFullYear()+"-"+ date.getMonth()+"-"+date.getDate()+" "+date.getHours()+":"+date.getMinutes()+ ":"+date.getSeconds();

          return this.customTaskPipe.transform(element)
        }))


        let diplomasAssinar: any = depachoAPI.filter(data => data.activityInstanceName == "Assinar Diploma");
        diplomasAssinar = diplomasAssinar.map((element) => this.expedienteTaskPipe.transform(element))
        this.deplomasStore.resetDiplomasParaAssinar(diplomasAssinar)

        let diplomasAssinados: any = depachoAPI.filter(data => data.activityInstanceName == "Diploma Assinado");
        diplomasAssinados = diplomasAssinados.map((element) => this.expedienteTaskPipe.transform(element))
        this.deplomasStore.resetDiplomasAssinadoList(diplomasAssinados)
      } catch (error) {}

      this.showLoaderNum--
    }

  }



  goToProcess(serialNumber: string, workflowName: string, activityName: string) {


    console.log({workflowName, serialNumber, activityName})

    if (workflowName == 'Despacho') {
      if (activityName == 'Tarefa de Despacho' || activityName == 'Concluir Despacho' || activityName == 'Reexecutar Despacho') {
        this.router.navigate(['/home/gabinete-digital/despachos', serialNumber, 'gabinete-digital']);
      }
    }
    else if (workflowName == 'Despacho do Presidente da República') {
      if (activityName == 'Tarefa de Despacho' || activityName == 'Concluir Despacho') {
        this.router.navigate(['/home/gabinete-digital/despachos-pr', serialNumber, 'gabinete-digital']);
      }
      else if (activityName == 'Revisar Diploma' || activityName == 'Diploma Assinado') {
        this.router.navigate(['/home/gabinete-digital/diplomas', serialNumber, 'gabinete-digital']);
      } else if ( activityName == 'Assinar Diploma') {
        this.router.navigate(['/home/gabinete-digital/diplomas-assinar', serialNumber, 'gabinete-digital']);
      } else {
        throw('Bug!');
      }
    }
    else if (workflowName == 'Pedido de Parecer' || workflowName == 'Pedido de Deferimento') {
      this.router.navigate(['/home/gabinete-digital/pedidos', serialNumber, 'gabinete-digital']);
    }
    else if (workflowName == 'Expediente') {
      this.router.navigate(['/home/gabinete-digital/expediente', serialNumber, 'gabinete-digital']);
    }
    else if (workflowName == 'Expediente' && SessionStore.user.Profile == 'PR') {
      this.router.navigate(['/home/gabinete-digital/expedientes-pr', serialNumber, 'gabinete-digital']);
    }
    else if (workflowName == "Pedido de Parecer do Presidente") {
      this.router.navigate(['/home/gabinete-digital/pedidos', serialNumber, 'gabinete-digital']);
    }
    /* else if (workflowName == "Expediente") {
      this.router.navigate(['/home/gabinete-digital/pedidos',serialNumber,'gabinete-digital']);
    } */
    else if (workflowName == 'Agenda Pessoal PR' || workflowName == 'Agenda Oficial PR' || workflowName == 'Agenda Oficial MDGPR' || workflowName == 'Agenda Pessoal MDGPR' || activityName == "Aprovar Evento" || workflowName == "Agendar Evento") {
      this.router.navigate(['/home/gabinete-digital/event-list/approve-event', serialNumber, 'gabinete-digital']);
    }
    else {
      throw(`${workflowName} && ${activityName} no route`)
    }

  }


  dynamicSearch({searchSubject, ordinance, list}) {
    if(searchSubject) {

      const AllProcess = list.filter((task) => {
        let subject = task.Folio || task.Subject || task.workflowInstanceDataFields.Subject
        subject = subject.toLowerCase();
        return subject.includes(searchSubject.toLowerCase())
      })

      this.AllProcess = list.reorderList(ordinance, AllProcess)
    } else {
      const AllProcess = list
      this.AllProcess = list.reorderList(ordinance, AllProcess)
    }
  }


  attachmentAppName(e) {
    const ApplicationId = e.ApplicationId || e.ApplicationType
    if( ApplicationId =='8' || ApplicationId ==8) {
      return 'Correspondencia'
    } else if (ApplicationId =='361' || ApplicationId ==361) {
      return 'ArquivoDespachoElect'
    }
  }


  taskCleaner(serialNumber: string) {
    const taskData = {
      serialNumber: serialNumber,
      action: "Conhecimento",
      ActionTypeId: 104,
      dataFields: {
        ReviewUserComment: "ii"
      },
      AttachmentList: {
        ProcessInstanceID: "",
        Attachments: []
      }
    };

    this.processesbackend.CompleteTask(taskData).subscribe(
      response => {
        console.log('Task completed successfully:', response);
      },
      error => {
        console.error('Error completing task:', error);
      }
    );
  }

}
