import { NgModule } from '@angular/core';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { ChatServiceService } from 'src/app/module/chat/domain/chat-service.service'
import { skip, switchMap } from 'rxjs/operators';
import { SessionStore } from 'src/app/store/session.service';
import { Subject, timer } from 'rxjs';
import { UserTypingLocalRepository } from './data/repository/typing/user-typing-local-data-source.service';
import { UserTypingRemoteRepositoryService } from './data/repository/typing/user-typing-live-data-source.service';
import { HttpListenToMessageLoadHistoryAdapter } from './domain/adapter';
import { ISignalRService } from 'src/app/infra/socket/adapter';
import { HttpModule } from 'src/app/infra/http/http.module';
import { HttpListenToMessageLoadHistoryUseCase } from 'src/app/core/chat/usecase/message/http-listen-to-message-load-history-by-roomId-use-case';
import { IMessageLocalRepository } from 'src/app/core/chat/repository/message/message-local-repository';
import { MessageLocalDataSourceService } from './data/repository/message/message-local-data-source.service';
import { MessageRemoteDataSourceService } from './data/repository/message/message-remote-data-source.service';
import { IMessageRemoteRepository } from 'src/app/core/chat/repository/message/message-remote-repository';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';
import { MessageSocketRepositoryService } from './data/repository/message/message-live-signalr-data-source.service';
import { MemberListLocalRepository } from './data/repository/member/member-list-local-repository.service';
import { IMemberLocalRepository } from 'src/app/core/chat/repository/member/member-local-repository';
import { MemberListRemoteRepository } from './data/repository/member/member-list-remote-repository.service';
import { IMemberRemoteRepository } from 'src/app/core/chat/repository/member/member-remote-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { RoomLocalRepository } from './data/repository/room/room-local-repository.service';
import { RoomRemoteDataSourceService } from './data/repository/room/room-remote-repository.service';
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';
import { RoomSocketRepositoryService } from './data/repository/room/room-socket-repository.service';
import { IRoomSocketRepository } from 'src/app/core/chat/repository/room/room-socket-repository';
import { IAttachmentLocalRepository } from 'src/app/core/chat/repository/typing/typing-local-repository';
import { AttachmentLocalDataSource } from './data/repository/attachment/attachment-local-repository.service';
import { IAttachmentRemoteRepository } from 'src/app/core/chat/repository/attachment/attachment-remote-repository';
import { AttachmentRemoteDataSourceService } from './data/repository/attachment/attachment-remote-repository.service';
import { IDistributionLocalRepository } from 'src/app/core/chat/repository/distribution/distribution-local-repository';
import { DistributionLocalRepository } from './data/repository/destribution/destribution-local-repository';
import { DistributionService } from './domain/service/distribution.service'
import { BoldLocalRepository } from './data/repository/bold/bold-local-repository';
import { IBoldLocalRepository } from 'src/app/core/chat/repository/bold/bold-local-repository';
import { RoomLastMessageService } from 'src/app/module/chat/domain/service/room-last-message.service'
import { IUserPhotoLocalRepository } from 'src/app/core/chat/repository/user-photo/user-photo-local-repository';
import { UserPhotoLocalRepository } from './data/repository/user-foto/user-photo-local-repository.service';
import { IUserPhotoRemoteRepository } from 'src/app/core/chat/repository/user-photo/user-photo-remote-repository';
import { UserPhotoRemoteRepositoryService } from './data/repository/user-foto/user-photo-remote-repository.service';
import { IMemberSocketRepository } from 'src/app/core/chat/repository/member/member-socket-repository';
import { MemberSocketRepositoryService } from './data/repository/member/member-socket-repository.service';
@NgModule({
  imports: [HttpModule],
  providers: [
    {
      provide: ISignalRService,
      useClass: SignalRService, // or MockDataService
    },
    {
      provide: HttpListenToMessageLoadHistoryAdapter,
      useClass: HttpListenToMessageLoadHistoryUseCase, // or MockDataService
    },
    // message repository
    {
      provide: IMessageLocalRepository,
      useClass: MessageLocalDataSourceService
    },
    {
      provide: IMessageRemoteRepository,
      useClass: MessageRemoteDataSourceService
    },
    {
      provide: IMessageSocketRepository,
      useClass: MessageSocketRepositoryService
    },
    // member repository
    {
      provide: IMemberLocalRepository,
      useClass: MemberListLocalRepository
    },
    {
      provide: IMemberRemoteRepository,
      useClass: MemberListRemoteRepository
    },
    {
      provide: IMemberSocketRepository,
      useClass: MemberSocketRepositoryService
    },
    // room repository
    {
      provide: IRoomLocalRepository,
      useClass: RoomLocalRepository
    },
    {
      provide: IRoomRemoteRepository,
      useClass: RoomRemoteDataSourceService
    },
    {
      provide: IRoomSocketRepository,
      useClass: RoomSocketRepositoryService
    },
    // attachment
    {
      provide: IAttachmentLocalRepository,
      useClass: AttachmentLocalDataSource
    },
    {
      provide: IAttachmentRemoteRepository,
      useClass: AttachmentRemoteDataSourceService
    },
    //
    {
      provide: IDistributionLocalRepository,
      useClass: DistributionLocalRepository
    },
    {
      provide: IBoldLocalRepository,
      useClass: BoldLocalRepository
    },
    // user-photo
    {
      provide: IUserPhotoLocalRepository,
      useClass: UserPhotoLocalRepository
    },
    //
    {
      provide: IUserPhotoRemoteRepository,
      useClass: UserPhotoRemoteRepositoryService
    },

  ],
  declarations: [],
  schemas: [],
  entryComponents: []
})
export class ChatModule {

  typingCallback: {[key: string]: Subject<any> } = {}

  constructor(
    private SignalRService: SignalRService,
    private ChatServiceService: ChatServiceService,
    private signalR: SignalRService,
    private localDataSource: UserTypingLocalRepository,
    private UserTypingRemoteRepositoryService: UserTypingRemoteRepositoryService,
    private DistributionService: DistributionService,
    private RoomLastMessageService: RoomLastMessageService
  ) {
    this.syncMessage()
    this.listenToTyping()
  }

  async listenToTyping() {
    this.UserTypingRemoteRepositoryService.listenToTyping().subscribe(async(e) => {

        // this.memoryDataSource.dispatch(removeUserTyping({data: {...e} as any}))
        // this.memoryDataSource.dispatch(addUserTyping({data: {...e} as any}))
        //
        const value = await this.localDataSource.addUserTyping(e);

        const id =  e.roomId + '@' + e.userName
        if(!this.typingCallback[id]) {
          this.typingCallback[id] = new Subject()
          this.typingCallback[id].pipe(
            switchMap(() => timer(2000)),
          ).subscribe(() => {
            // console.log('111111==============')
            // this.memoryDataSource.dispatch(removeUserTyping({data: {...e} as any}))
            this.localDataSource.removeUserTyping(e)
          })
        } else {
          this.typingCallback[id].next()
        }
    })

  }

  async syncMessage() {
    const connection = this.SignalRService.getConnectionState()

    connection.pipe(
    ).subscribe((value: boolean)=> {
      if(value) {
        // on reconnect
        this.ChatServiceService.chatSync();
      }
    });

    connection.subscribe((value: boolean) => {
      if(value) {
        // on connect
        this.ChatServiceService.sendLocalMessages()
      }
    })

    // on page reload sync
    if(!(!SessionStore.user.Inactivity || !SessionStore.exist)) {
      this.ChatServiceService.start();
    }
  }
}
