import { Attachment } from './attachment.model';
import { EventBody } from './eventbody.model';
import { EventPerson } from './eventperson.model';


export interface EventRecurrence {
    Type?: number | string;
    Day?: number;
    daysOfWeek?: number[];
    Month?: number | string;
    LastOccurrence?: Date | string;
    frequency?: string,
    until?: string
}

export class Event{
    ParentId?: string;
    EventId: string;
    Subject: string;
    Body: EventBody;
    Location: string;
    CalendarId: string;
    CalendarName: string;
    StartDate: Date;
    EndDate: Date;
    EventType: string;
    Attendees: EventPerson[];
    IsMeeting: boolean;
    IsRecurring: boolean;
    AppointmentState: number;
    TimeZone: string;
    Organizer: any
    Category: string;
    HasAttachments: boolean;
    EventRecurrence: EventRecurrence;
    Name?: string
    Attachments?: Attachment[];
    HumanDate?: string;
    IsAllDayEvent?: boolean;
    owner?: {
      wxUserId: number,
      wxFullName: string,
      wxeMail: string,
      userPhoto: string,
    }
}


interface EventList {
  HasAttachments: boolean;
  IsAllDayEvent: boolean;
  EventId: string;
  Subject: string;
  Location: string;
  CalendarId: string;
  CalendarName: string;
  StartDate: string;
  EndDate: string;
  Schedule: string;
  RequiredAttendees: any; // Adjust type as per your data
  OptionalAttendees: any; // Adjust type as per your data
  HumanDate: string;
  TimeZone: string;
  IsPrivate: boolean;
}

// event to approve details ================================================
export interface Originator {
    email: string;
    manager: string;
    displayName: string;
    fqn: string;
    username: string;
}

export interface WorkflowInstanceDataFields {
    Body: string;
    Location: string;
    Subject: string;
    StartDate: string;
    EndDate: string;
    Participants?: string;
    CC?: string;
    ReviewUserComment?: string;
    Role?: number;
    MDName: string;
    MDEmail: string;
    OriginatorComments?: string;
    Status?: string;
    TimeZone?: string;
    Agenda: string;
    EventType: string;
    EventID?: string;
    IsRecurring?: any;
    HasAttachments?: boolean;
    ParticipantsList?: any;
    EventOrganizer?: string;
    CreateEvent?: string;
    IsAllDayEvent: boolean;
    SerializedItem?: string;
    MDwxUserID?: number;
    DeserializedItem?: string;
    Message: string;
    InstanceId: string;
    Header?: string;
    RecurringString?: string;
    LastOccurrence: string;
    OccurrenceType: string;
    SerialNumber?: string;
    UserEmail?: string;
    PREmail?: string;
}

export interface EventToApproveDetails {
    serialNumber: string;
    originator?: Originator;
    actions?: string[];
    activityInstanceName?: string;
    workflowInstanceFolio?: string;
    taskStartDate?: string;
    workflowID?: number;
    workflowInstanceID?: number;
    workflowName?: string;
    workflowDisplayName?: string;
    formURL?: string;
    workflowInstanceDataFields: WorkflowInstanceDataFields;
    totalDocuments?: any;
    Documents?: any;
}
// ================================================================================


// event event to approve ================
export interface ParticipantsList {
    Id: number;
    EmailAddress: string;
    Name: string;
    IsRequired: boolean;
}


export interface EventToApproveEdit {
    SerialNumber: string;
    Body: string;
    Location: string;
    Subject: string;
    StartDate: Date | string;
    EndDate:  Date | string;
    ReviewUserComment: string;
    MDName: string;
    MDEmail: string;
    IsAllDayEvent: boolean;
    Status: string;
    Category: string
    EventType: string;
    IsRecurring: boolean;
    ParticipantsList: ParticipantsList[];
    Message: string;
    EventRecurrence: EventRecurrence;
    Participants?: string;
    CC?: string;
    Agenda: string;
    HasAttachments?: boolean;
    EventOrganizer?: string;
    InstanceId?: string;
}

// // ================================================================================
// export interface EventToApproveDetails {
//     serialNumber: string;
//     originator: {
//         email: string;
//         manager: string;
//         displayName: string;
//         fqn: string;
//         username: string;
//     }[]
//     actions: string[];
//     activityInstanceName: string;
//     workflowInstanceFolio: string;
//     taskStartDate: string;
//     workflowID: number;
//     workflowInstanceID: number;
//     workflowName: string;
//     workflowDisplayName: string;
//     formURL: string;
//     workflowInstanceDataFields: {
//         Body: string;
//         Location: string;
//         Subject: string;
//         StartDate: string;
//         EndDate: string;
//         Participants: string;
//         CC: string;
//         Private: boolean;
//         ReviewUserComment: string;
//         MDName: string;
//         MDEmail: string;
//         OriginatorComments: string;
//         Agenda: string;
//         EventType: string;
//         TimeZone: string;
//         EventID: string;
//         HasAttachments: boolean;
//         ParticipantsList: {
//             $type?: any;
//             EmailAddress: string;
//             Name: string;
//             IsRequired: boolean;
//         }[]
//         EventOrganizer: string;
//         CreatEvent: string;
//         IsAllDayEvent: boolean;
//         MDwxUserID: number;
//         SerializedItem: string;
//         DeserializedItem: string;
//         Status: string;
//         Message: string;
//         InstanceId: string;
//     }
//     totalDocuments?: any;
//     Documents?: any;
// }
