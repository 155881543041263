import { Injectable } from '@angular/core';
import { GetDraftListByProcessIdService, IGetDraftListByProcessIdSchema } from './usecase/getDraft-list-by-process-id.service';
import { DraftSaveByIdInputSchema, DraftSaveByIdUseCaseService, IDraftSaveByIdInput } from './usecase/draft-save-by-id-use-case.service';
import { EventSetDefaultParticipantsService } from 'src/app/core/agenda/use-case/event-set-default-participants.service';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  constructor(
    private getDraftListByProcessIdService: GetDraftListByProcessIdService,
    private DraftSaveByIdUseCaseService: DraftSaveByIdUseCaseService,
    private EventSetDefaultParticipants: EventSetDefaultParticipantsService
  ) { }

  getDraftListByProcessId(input: IGetDraftListByProcessIdSchema) {
    return this.getDraftListByProcessIdService.execute(input)
  }

  draftSaveById(input: IDraftSaveByIdInput) {
    return this.DraftSaveByIdUseCaseService.execute(input)
  }

  setDefaultParticipants() {
    return this.EventSetDefaultParticipants.execute()
  }
}
