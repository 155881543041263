import { Injectable } from '@angular/core';
import { CustomCalendarEvent, EventListStore } from 'src/app/models/agenda/AgendaEventList';
import { DateService } from '../../../services/date.service';
import { momentG } from 'src/plugin/momentG';
import { NativeNotificationService } from 'src/app/services/native-notification.service';

@Injectable({
  providedIn: 'root'
})
export class ListBoxService {

	height = "unset"


	constructor(
    private dateService: DateService,
	){}


	filterProfile(eventSource: EventListStore[] = [], profile: 'md' | 'pr' |  'all') {
		return eventSource.filter((e) => e.profile == profile)
	}

	getEventInsideRange(eventSource: EventListStore[], rangeStartDate, randEndDate) {
		return eventSource.filter((e)=> {
			if(new Date(rangeStartDate).getTime() <= new Date(e.startTime).getTime() &&
					new Date(randEndDate).getTime() >= new Date(e.endTime).getTime()) {
				return true
			}
			return false
		})
	}

	filterSegment(eventSource: EventListStore[], segment): EventListStore[] {

		return eventSource.filter( data => data.calendarName == segment)
	}

	daysBetween(){ }


	list(eventSource: EventListStore[], profile: 'md' | 'pr' | 'all', rangeStartDate, randEndDate, {segment = 'Combinado', selectedDate= null}): Year[] {

		// // filter range
		// if(selectedDate) {
		// 	eventSource = eventSource.filter(data =>
		// 		data.startTime.toLocaleDateString('pt') >= new Date(rangeStartDate).toLocaleDateString('pt') &&
		// 		data.startTime.toLocaleDateString('pt') <= new Date(rangeStartDate).toLocaleDateString('pt')
		// 	)
		// }

		if(segment!='Combinado') {
			eventSource = this.filterSegment(eventSource, segment)
		}

		// if(profile != 'all') {
		// 	eventSource = this.filterProfile(eventSource, profile)
		// }

    let newStracture:CustomCalendarEvent[];
		newStracture = this.encapsulation(eventSource);

    // const object = {}

    // for (const e of newStracture.reverse()) {
    //   if(!object[momentG(new Date(e.start), 'MMMM yyyy')]) {
    //     object[momentG(new Date(e.start), 'MMMM yyyy')] = []
    //   }
    //   object[momentG(new Date(e.start), 'MMMM yyyy')].push(e)
    // }

    // const daysStringNum = Object.keys(object).reverse()

    // const daysObject = {}

    // for(const day of daysStringNum) {
    //   daysObject[day] = object[day]
    // }

    // console.log('newStracture', newStracture)

    const data = this.display(newStracture, selectedDate)
		return data.year

    // const daysStringNum = Object.keys(daysObject)

    // for(const day of daysStringNum) {
    //   daysObject[day] = daysObject[day].reverse()
    // }


    // daysObject = this.transformObjectKeyOrder(daysObject, daysStringNum)

    // return daysObject
	}

  sortArrayISODate(myArray: any): any[] {

    return myArray.sort((a,b) =>
      Date.parse(b.start )
      -
      Date.parse(a.start))
  }

	display(list: CustomCalendarEvent[], selectedDate) {

		let days = {};
    const year: Year[] = []

		this.sortArrayISODate(list).reverse().forEach( (event:CustomCalendarEvent, index) => {

			var startDate: any = new Date(event.start);

			var endDate: any = this.dateService.EventEndDateTreatment({
				startTime: startDate,
				endTime: event.end
			})

			const day = this.dateService.getDay(event.start)

			event['manyDays'] = !this.dateService.isSameDate(event.start, event.end)
			event['todayOnly'] = this.dateService.isSameDate(event.start, event.end)

			if(!days.hasOwnProperty(day)) {
				days[day] = []
			}

			const diffDays = this.dateService.deferenceBetweenDays(endDate, startDate)

			if (this.dateService.notSameDate(startDate, endDate)) {

        const cloneSelectedDate = new Date(selectedDate)
        cloneSelectedDate.setHours(0)
        cloneSelectedDate.setMinutes(0)
        cloneSelectedDate.setSeconds(0)


        if (diffDays >= 1) {

          const StartEvent =  this.transForm(event, {startMany: true, endMany: false,	middle: false, hasMany: true})

          if(this.CanPush(event, selectedDate) && (new Date(event.start)).getTime() >= cloneSelectedDate.getTime()) {
            days[day].push(StartEvent); this.push(StartEvent, year)
          }

          let i = 1;

          //create event between date
          while (startDate.getFullYear() !=  endDate.getFullYear() ||
            startDate.getMonth() != endDate.getMonth() ||
            startDate.getDate() != endDate.getDate()) {

            const newDate = startDate.setDate(startDate.getDate()+ i)
            let otherDays =   this.dateService.getDay(newDate)

            const cloneEvent = {...event}
            cloneEvent['other'] = true

            cloneEvent.start = new Date(startDate)

            if(!days.hasOwnProperty(otherDays)) {
              days[otherDays] = []
            }

            if (!(startDate.getFullYear() !=  endDate.getFullYear() ||
            startDate.getMonth() != endDate.getMonth() ||
            startDate.getDate() != endDate.getDate())) {
              // last push
              const EndEvent = this.transForm(cloneEvent, {startMany: false, endMany: true, middle: false, hasMany: true})
              if(this.CanPush(cloneEvent, selectedDate) && cloneEvent.start.getTime() >= cloneSelectedDate.getTime()) {
                days[otherDays].push(EndEvent) ; this.push(EndEvent, year)
              }

            } else {

              const EndEvent = this.transForm(cloneEvent, {startMany: false,endMany: false,	middle: true, hasMany: true})
              if(this.CanPush(cloneEvent, selectedDate) && cloneEvent.start.getTime() >= cloneSelectedDate.getTime()) {
                days[otherDays].push(EndEvent) ; this.push(EndEvent, year)
              } else {
                if( cloneEvent.start.getFullYear() == cloneSelectedDate.getFullYear() && cloneEvent.start.getDate() == cloneSelectedDate.getDate() && cloneEvent.start.getMonth() == cloneSelectedDate.getMonth()) {
                  // console.log("00_00")
                  days[otherDays].push(EndEvent) ; this.push(EndEvent, year)
                } else {
                  // console.log('0000000000000000000000000',cloneEvent.start.getTime(), cloneSelectedDate.getTime())
                  // console.log('0000000000000000000000000',cloneEvent.start, cloneSelectedDate)
                }
              }

            }

            days[otherDays] = days[otherDays].reverse()

          }

        } else {
          if(this.CanPush(event, selectedDate)) { days[day].push(event) ; this.push(event, year) }
        }

			} else {
        event['sameDay'] = true
				if(this.CanPush(event, selectedDate) && diffDays != 2) { days[day].push(event) ; this.push(event, year) }
			}

		})

		// remove days that haven't event
		Object.entries(days).forEach(([index, value]) => {
		const _value: any = value

		if(_value.length == 0) {
			delete days[index]
		}

		})

		return {days, year}
	}


  push(event: any, year: Year[]) {
    const date = new Date(event.start)

    const yearName = momentG(new Date(date), 'yyyy')
    const monthName = momentG(new Date(date), 'MMMM')
    const dayName = momentG(new Date(date), 'dd')


    let YearIndex = year.findIndex( x => x.yearInfo.yearName == yearName)



    if(YearIndex == -1) {
      YearIndex = year.push({
        yearInfo: {
          yearName: yearName
        },
        months: []
      })

      YearIndex--
    }

    let MonthNameIndex = year[YearIndex].months.findIndex( x => x.monthInfo.monthName == monthName)

    if(MonthNameIndex == -1) {
      MonthNameIndex = year[YearIndex].months.push({
        monthInfo: {
          monthName: monthName
        },
        days: []
      })

      MonthNameIndex --
    }

    let DayNameIndex = year[YearIndex].months[MonthNameIndex].days.findIndex( x => x.daysInfo.dayName == dayName)

    if(DayNameIndex == -1) {
      year[YearIndex].months[MonthNameIndex].days.push({
        daysInfo: {
          dayName: dayName
        },
        events: [event]
      })
    } else {
      year[YearIndex].months[MonthNameIndex].days[DayNameIndex].events.push(event)
    }

  }

	CanPush(event: any, selectedDate: Date) {

    const cloneSelectedDate = new Date(selectedDate);

    cloneSelectedDate.setHours(0)
    cloneSelectedDate.setMinutes(0)
    cloneSelectedDate.setSeconds(0)

    const limite = this.endOfMonth(selectedDate)
    limite.setDate(limite.getDate() + 10)
    limite.setHours(0)

    selectedDate.setHours(0);
    selectedDate.setMinutes(0);
    selectedDate.setSeconds(0);

		return  (selectedDate.getTime() <= new Date(event.start).getTime()  || selectedDate.getTime() <= new Date(event.end).getTime()) &&
        (limite.getTime() >= new Date(event.start).getTime() || limite.getTime() >= new Date(event.end).getTime())
	}

	encapsulation(eventsList:EventListStore[]): CustomCalendarEvent[] {

		// remove all event
		let events: CustomCalendarEvent[] = [];

		eventsList.forEach((element, eventIndex) => {

			events.push({
				start: new Date(element.startTime),
				end: new Date(element.endTime),
				id: element.id,
				event: element.event,
				profile: element.profile
			});

		});

		return events;
	}

	transForm(event:  CustomCalendarEvent, {startMany, endMany, middle, hasMany = false}) {

    let daysLeft = this.daysToEndWithJS(event.start, event.end);
    let eventTotalDuration = this.daysToEndWithJS(event.event.StartDate, event.event.EndDate);

		return Object.assign({}, {
		start: event.start,
		end: event.end,
		id: event.id,
		profile: event.profile,
		event: {
			Subject: event.event.Subject,
			StartDate:  event.event.StartDate,
			EndDate: event.event.EndDate,
			Location:  event.event.Location,
			EventId:  event.event.EventId,
			CalendarName: event.event.CalendarName,
			CalendarId: event.event.CalendarId,
      daysLeft
		},
    eventTotalDuration,
    hasMany,
    duration: this.duration(event.start, event.event.EndDate),
    daysLeft,
		Subject: event.event.Subject,
		startMany: startMany,
		endMany: endMany,
		middle: middle
		})
	}




  daysToEndWithJS(startDateStr: any, endDateStr: any) {
    // Define the start and end dates
    const startDate: any = new Date(startDateStr);
    const endDate: any = new Date(endDateStr);

    startDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    endDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    // Calculate the difference in milliseconds between the two dates
    const differenceMs = Math.abs(endDate - startDate);

    // Convert milliseconds to days
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const differenceDays = Math.ceil(differenceMs / millisecondsPerDay);

    // console.log(`Number of days between the dates: ${differenceDays}`);

    return differenceDays

  }



  duration(date1Str, date2Str) {

    // Convert string dates to Date objects
    const date1: any = new Date(date1Str);
    const date2: any = new Date(date2Str);

    // Calculate the difference in milliseconds
    const timeDifferenceMs = date2 - date1;

    // Convert difference to days, hours, and minutes
    const totalMinutes = Math.floor(timeDifferenceMs / (1000 * 60));
    const days = Math.floor(totalMinutes / (60 * 24));
    const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
    const minutes = totalMinutes % 60;

    return `${days}d`
  }


  transformObjectKeyOrder(originalObject, keyOrder) {
    const transformedObject = {};

    for (const key of keyOrder) {
      if (originalObject.hasOwnProperty(key)) {
        transformedObject[key] = originalObject[key];
      }
    }

    // Include any remaining keys not in the specified order
    for (const key in originalObject) {
      if (!keyOrder.includes(key) && originalObject.hasOwnProperty(key)) {
        transformedObject[key] = originalObject[key];
      }
    }

    return transformedObject;
  }


  endOfMonth(myDate){
    let date = new Date(myDate);
    date.setDate(1); // Avoids edge cases on the 31st day of some months
    date.setMonth(date.getMonth() +1);
    date.setDate(0);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }
}

interface DayInfo {
  // Define properties for dayInfo here
  dayName: string
}

interface Day {
  // Define properties for day here

}

interface Month {
  monthInfo: {
    monthName: string
    // Define properties for yearInfo inside months here
  }
  days: {
    daysInfo: DayInfo;
    events: Day[];
  }[]
}

interface Year {
  yearInfo: {
    yearName: string
    // Define properties for yearInfo here
  };
  months: Month[];
}

const years: Year[] = [
  {
    yearInfo: {
       yearName: ""
      // Define properties for yearInfo inside the first year here
    },
    months: [
      {
        monthInfo: {
          monthName: "",
          // Define properties for yearInfo inside the first month here
        },
        days: [
          {
            daysInfo: {
              dayName: "",
              // Define properties for dayInfo inside the first day here
            },
            events: [
              {
                // Define properties for the first day here
              },
            ],
          }
        ],
      },
      // Add more months here as needed
    ],
  },
  // Add more years here as needed
];
