import { Injectable, Input } from '@angular/core';
import { IAttachmentRemoteRepository } from 'src/app/core/chat/repository/attachment/attachment-remote-repository';
import { HttpService } from 'src/app/services/http.service';
import { DataSourceReturn } from 'src/app/services/Repositorys/type';
import { HttpAdapter } from 'src/app/infra/http/adapter'
@Injectable({
  providedIn: 'root'
})
export class AttachmentRemoteDataSourceService implements IAttachmentRemoteRepository {
  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2/Chat'; // Your base URL

  constructor(
    private httpService: HttpService,
    private HttpAdapter: HttpAdapter
  ) { }

  async getAttachment(id: string | number) {
    return await this.HttpAdapter.get<Blob>(`${this.baseUrl}/attachment/${id}`, { responseType: 'blob' })
  }

}
