import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { IProfilePictureInputDTO } from '../dto/profilePictureInputDTO';
import { HttpHeaders } from '@angular/common/http';
import { TracingType } from 'src/app/services/monitoring/opentelemetry/tracer';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { IUserRepositoryLoginParams, UserRefreshTokenInputDTO } from 'src/app/core/user/repository/user-remote-repository';
import { UserLoginOutput } from 'src/app/core/user/use-case/user-login-use-case.service';
import { SessionStore } from 'src/app/store/session.service';
@Injectable({
  providedIn: 'root'
})
export class UserRemoteRepositoryService {

  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2'; // Your base URL
  
  constructor(
    private httpService: HttpService,
    private http: HttpAdapter,
  ) { }


    // @APIReturn(MessageOutPutDTOSchema, 'get/Messages')
    async login(input: IUserRepositoryLoginParams) {

      return await this.http.post<UserLoginOutput>(`${this.baseUrl}/Users/login`, input)
    }

  // @APIReturn(MessageOutPutDTOSchema, 'get/Messages')
  async logout() {

    return await this.http.post<UserLoginOutput>(`${this.baseUrl}/Users/${SessionStore.user.UserId}/logout`, {})
  }

  async refreshToken(input: UserRefreshTokenInputDTO) {

    return await this.http.post<UserLoginOutput>(`${this.baseUrl}/Users/RefreshToken`, input)
  }

  getUserProfilePhoto(guid: string, tracing?: TracingType) {
    const geturl = environment.apiURL + 'UserAuthentication/GetPhoto';

    const params = {
      UserPhoto: guid
    }

    return this.httpService.get<string>(`${geturl}`, params, tracing);
  }

  addUserProfilePhoto(data: IProfilePictureInputDTO) {
    const geturl = environment.apiURL + 'UserAuthentication/AddPhoto';

    let http = new HttpHeaders();

    http = http.set('content-type', "application/json");
    http = http.set('accept', "application/json");
    let options = {
      headers: http
    };

    return this.httpService.post<string>(`${geturl}`, data, options);
  }
}
