import { HttpResult, IHttPReturn } from "src/app/infra/http/type"
import { UserPhotoGetByIdInputSchema } from "src/app/core/chat/usecase/user-photo/user-photo-get-by-id-use-case.service"
import { z } from "zod"

export const IGetUserPhotoByAttachmentIdInputSchema = z.object({
  attachmentId: z.string(),
})

export type IGetUserPhotoByAttachmentId = z.infer<typeof IGetUserPhotoByAttachmentIdInputSchema>

export abstract class  IUserPhotoRemoteRepository {
  abstract getUserPhotoByAttachmentId(input: IGetUserPhotoByAttachmentId): IHttPReturn<string>
}
