import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ok, err, Result } from 'neverthrow';
import { HttpParams } from '@angular/common/http';
import { TracingType } from './monitoring/opentelemetry/tracer';


export type  HTTPOptions = {
  headers?: HttpHeaders
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http:HttpClient) { }

  async post<T>(url: string, body: any, options:HTTPOptions ={}, tracing?: TracingType): Promise<Result<T, HttpErrorResponse>>  {

    try {
      const result = await this.http.post(url, body, options).toPromise()
      return ok (result as T)
    } catch (e) {
      tracing?.setAttribute('url', url)
      if(isHttpError(e)) {
        tracing?.setAttribute('status.code', e.status.toString())
        if (e.status == 400) {
          tracing?.log("bad request "+url, {
            requestPath: url,
            requestBody: body,
            requestStatusCode: e.status.toString()
          })

        }
      }
      tracing?.setAttribute('outcome', 'failed')
      return err(e as HttpErrorResponse)
    }
  }

  async get<T>(url: string, httpParamsObj = {}, tracing?: TracingType): Promise<Result<T, HttpErrorResponse>>   {
    try {

      let httpParams = new HttpParams();

      // Convert params object to HttpParams
      if (httpParamsObj) {
        Object.keys(httpParamsObj).forEach(key => {
          httpParams = httpParams.set(key, httpParamsObj[key]);
        })
      }

      let opts = {
        params : httpParams
      }
      const result = await this.http.get<T>(url, opts).toPromise()

      return ok (result as T)
    } catch (e) {
      tracing?.setAttribute('url', url)
      if(isHttpError(e)) {
        tracing?.setAttribute('status.code', e.status.toString())
        if (e.status == 400) {
          tracing?.log("bad request "+url, {
            requestPath: url,
            queryParameter: httpParamsObj,
            requestStatusCode: e.status.toString()
          })
        }
      } else {
        //tracing?.setAttribute('map.error', 'true')
        //tracing?.setAttribute('map.error.context', JSON.stringify(e))
        //tracing?.setAttribute('outcome', 'failed')
      }
      tracing?.setAttribute('outcome', 'failed')
      return err(e as HttpErrorResponse)
    }
  }

  async getFile<T>(url: string, options ={}): Promise<Result<T, HttpErrorResponse>>   {
    try {
      const result = await this.http.get<T>(url, options).toPromise()
      return ok (result as T)
    } catch (e) {
      return err(e as HttpErrorResponse)
    }
  }

  async put<T>(url: string, body: any): Promise<Result<T, HttpErrorResponse>>   {

    try {
      const result = await this.http.put<T>(url, body).toPromise()
      return ok (result as T)
    } catch (e) {
      return err(e as HttpErrorResponse)
    }

  }

  async delete<T>(url: string, httpParamsObj = {}, body = {}): Promise<Result<T, HttpErrorResponse>>   {

    let httpParams = new HttpParams();

    // Convert params object to HttpParams
    if (httpParamsObj) {
      Object.keys(httpParamsObj).forEach(key => {
        httpParams = httpParams.set(key, httpParamsObj[key]);
      })
    }

    let opts = {
      params : httpParams,
      body
    }

    try {
      const result = await this.http.delete<T>(url, opts).toPromise()
      return ok (result as T)
    } catch (e) {
      return err(e as HttpErrorResponse)
    }
  }

  async patch<T>(url: string, body  ={}): Promise<Result<T, HttpErrorResponse>> {
    try {
      const result = await this.http.patch<T>(url, body).toPromise();
      return ok(result as T);
    } catch (e) {
      return err(e as HttpErrorResponse);
    }
  }
}



export const isHttpError = (e: any): e is HttpResponse<any> => {
  return Number.isInteger((e as HttpResponse<any>).status);
}

