import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LocalNotifications } from '@capacitor/local-notifications';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class NativeNotificationService {

  private scheduledEventIds: number[] = [];

  constructor(
    private platform: Platform,) {
    this.askForPermission()

    window['send'] = () => {
      this.eventNotification({eventId:uuidv4()})
    }

  }

  askForPermission() {}

  foregroundNotification() {}

  backgroundNotification() {
    /* LocalNotifications.addListener('localNotificationActionPerformed', (action) => {
      console.log('Local notification action performed (background):', action);
      // Implemente a lógica para lidar com a ação da notificação em segundo plano
    }); */
  }

  sendNotificationChat({title = 'User', icon = '', message = 'hello'}) {

  /*   LocalNotifications.schedule({
      notifications:[
        {
          title : title,
          body : message,
          id : new Date().getTime()
        }
      ]
    }); */

  }

  uuidTo32BitInt(uuid) {
    // Remove hyphens and convert to a 16-byte hex string
    const hex = uuid.replace(/-/g, '');

    // Get the least significant 8 bytes (16 hex characters)
    const leastSignificantBytes = hex.slice(-16);

    // Convert to a 64-bit integer
    const bigInt = BigInt('0x' + leastSignificantBytes);

    // Convert to a 32-bit integer and ensure it fits within the int32 range
    const int32 = Number(bigInt % BigInt(2 ** 32));

    // Adjust to fit within the signed 32-bit range
    return int32 >= 2 ** 31 ? int32 - 2 ** 32 : int32;
  }

  async cancelNotification(eventId: number) {
    //await LocalNotifications.cancel({ notifications: [{ id: eventId }] });
    //console.log(`Notification with event ID ${eventId} canceled.`);
  }

  didEventHappenToday(eventTime) {
    const eventDate = new Date(eventTime);
    const today = new Date();
    return eventDate.toDateString() === today.toDateString();
  }


  async cancelAllNotification() {
    // try {
    //   await LocalNotifications.cancel({
    //     notifications: []
    //   });
    //   console.log('All notifications cancelled');
    // } catch (error) {
    //   console.error('Error cancelling notifications:', error);
    // }
  }

  async scheduleNotifications(events: any) {
    const notifications = [];


    // console.log({events});

    events = events
      .filter(e =>  new Date().getTime() <= new Date(e.start).getTime())
      .filter(e => this.didEventHappenToday(e.start))

    // console.log('notify', events)


    await this.cancelAllNotification();

    for (const event of events) {

      const eventTime = new Date(event.start).getTime();
      const now = new Date().getTime();

      // Schedule notifications for 1 hour before and 15 minutes before the event
      const oneHourBefore = eventTime - 60 * 60 * 1000;
      const fifteenMinutesBefore = eventTime - 15 * 60 * 1000;

      const timeDifference = eventTime - now;
      const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
      const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds

      console.log('notification to notify object', event)
      // if (timeDifference <= fifteenMinutes) {

        // console.log({notification: event, e: '15 minutes'})
        // notifications.push({
        //   title: 'Event Reminder',
        //   body: `Reminder: ${event.Subject} starts in ${this.duration(event.start, new Date())} minutes.£`,
        //   id: this.uuidTo32BitInt(event.event.id)
        // });
      // } else if (timeDifference <= oneHour) {

        // console.log({notification: event, e: '1 hour.'})
        // notifications.push({
        //   title: 'Event Reminder',
        //   body: `Reminder: ${event.Subject} starts in ${this.duration(event.start, new Date())} hour.£`,
        //   id: this.uuidTo32BitInt(event.id)
        // });
      // } else {
        // console.log("Event is more than 1 hour away.")


        if (timeDifference >= fifteenMinutes) {
          notifications.push({
            title: 'Event Reminder',
            body: `Reminder: ${event.event.Subject}  15 minutes`,
            id: this.uuidTo32BitInt(event.id)+1,
            schedule: { at: new Date(fifteenMinutesBefore) },
          });
          console.log('15m', new Date(fifteenMinutesBefore))

        }

        if (timeDifference >= oneHour) {
          notifications.push({
            title: 'Event Reminder',
            body: `Reminder: ${event.event.Subject}  1 hour`,
            id: this.uuidTo32BitInt(event.id)+2,
            schedule: { at: new Date(oneHourBefore) },
          });
          console.log('1h', new Date(oneHourBefore))
        }

      // }

    }

    await LocalNotifications.schedule({ notifications });
  }

  async eventNotification({eventId}) {
    // await LocalNotifications.schedule({
    //   notifications: [
    //     {
    //       title: 'Test Notification 6',
    //       body: 'This is a test notification. 6',
    //       id: this.uuidTo32BitInt(eventId),  // Unique ID for the notification
    //       schedule: { at: new Date(Date.now() + 1000 * 10) }, // Schedule to show in 5 seconds
    //       sound: null,
    //       attachments: null,
    //       actionTypeId: '',
    //       extra: null,
    //     },
    //   ],
    // });

    // console.log('send notificatino')
  }

  isDesktop() {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      return true
    } else {
      return false

    }
  }

  duration(date1Str, date2Str) {

    // Convert string dates to Date objects
    const date1: any = new Date(date1Str);
    const date2: any = new Date(date2Str);

    // Calculate the difference in milliseconds
    const timeDifferenceMs = date2 - date1;

    // Convert difference to days, hours, and minutes
    const totalMinutes = Math.floor(timeDifferenceMs / (1000 * 60));
    const days = Math.floor(totalMinutes / (60 * 24));
    const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
    const minutes = totalMinutes % 60;

    return `${days}d ${hours}h ${minutes}m`.replace('0d', '')
  }
}
