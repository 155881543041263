import { Injectable } from '@angular/core';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { IDBoolean } from 'src/app/infra/database/dexie/type';
import { z } from 'zod';

const RoomSetLocalToFalseByIdInputSchema = z.object({
  $roomId: z.string(),
  roomId: z.string()
})

export type IRoomSetLocalToFalseByIdInput = z.infer<typeof RoomSetLocalToFalseByIdInputSchema >

@Injectable({
  providedIn: 'root'
})
export class RoomSetLocalToFalseByIdService {

  constructor(
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) { }

  execute(input: IRoomSetLocalToFalseByIdInput) {
    return this.roomLocalDataSourceService.update(input.$roomId, {
      local: IDBoolean.false,
      id: input.roomId
    })
  }
}
