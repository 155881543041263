import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { DataSourceReturn } from 'src/app/services/Repositorys/type';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { IMessageGetAllByRoomIdOutPut } from 'src/app/core/chat/usecase/message/message-get-all-by-room-Id';
import { IGetMessagesFromRoomParams, IMessageRemoteRepository } from 'src/app/core/chat/repository/message/message-remote-repository';
import { HttpErrorResponse } from '@angular/common/http';
import { Result } from 'neverthrow';
import { HttpResult } from 'src/app/infra/http/type';

@Injectable({
  providedIn: 'root'
})
export class MessageRemoteDataSourceService implements IMessageRemoteRepository {

  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2/Chat'; // Your base URL

  constructor(
    private httpService: HttpService,
    private socket: SignalRService,
    private http: HttpAdapter
  ) {}


  // @APIReturn(MessageOutPutDTOSchema, 'get/Messages')
  async getMessagesFromRoom(input: IGetMessagesFromRoomParams): DataSourceReturn<IMessageGetAllByRoomIdOutPut>  {

    let a: Result<HttpResult<IMessageGetAllByRoomIdOutPut>, HttpErrorResponse>

    if(input.lastMessageDate) {
      a = await this.http.get<IMessageGetAllByRoomIdOutPut>(`${this.baseUrl}/Room/${input.roomId}/Messages?startDate=${encodeURIComponent(input.lastMessageDate)}`)
    } else {
      a = await this.http.get<IMessageGetAllByRoomIdOutPut>(`${this.baseUrl}/Room/${input.roomId}/Messages`)
    }

    return a.map((e) => {
      return e.data
    })

    // return await this.httpService.get(`${this.baseUrl}/Room/${id}/Messages`);
  }

}
