import { SessionStore } from 'src/app/store/session.service';

export class AgendaPermission{
  private hasOwnCalendar = false
  private hasSharedCalendar = false

  constructor() {
    if(SessionStore.exist) {
      // for (let  calendar of SessionStore?.user?.OwnerCalendars) {
      //   this.hasOwnCalendar = true
      // }
  
      // for (let  sharedCalendar of SessionStore?.user?.SharedCalendars) {
      //   this.hasSharedCalendar = true
      // }

      this.hasOwnCalendar = true
      this.hasSharedCalendar = true
    } 
  }
  get access () {
    return 530 
    
    if(this.hasOwnCalendar || this.hasSharedCalendar) {
      return 530
    } else {
      return null
    }
    
  }

  get creatEvent () {
    return 504
  }

  get listAprovalEvents () {
    return 507
  }

  get contactManager() {
    return 548
  }
}
