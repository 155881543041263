import { Result, ok, err } from 'neverthrow';
import Dexie, { EntityTable, Table } from 'Dexie';
import { ZodError, ZodObject, ZodSchema } from 'zod';
import { Logger } from 'src/app/services/logger/main/service';
import { IDexieRepository, ILocalModel, RepositoryResult } from '../adapter'
import { IDBError, IDBErrorParams, IDexieError } from '../types';

// Define a type for the Result of repository operations

class DBError<T> extends Error implements IDBError<T> {
  zodError?: ZodError<T>;
  parameters: T;
  error: IDexieError;
  DBErrorName?: 'NotFoundError' | 'ConstraintError'

  constructor(data: IDBErrorParams<T>) {
    super(data.message);
    this.zodError = data.zodError;
    this.parameters = data.parameters;
    this.error = data.error;
    this.DBErrorName = data.error?.name as any;

    Logger.error(data.message, {
      zodError: this.zodError,
      parameters: this.parameters
    })

    // // Manually capture the stack trace if needed
    // if (Error.captureStackTrace) {
    //   Error.captureStackTrace(this, DBError);
    // }
  }
}

export class DexieRepository<T, R, I = EntityTable<any, any>> implements IDexieRepository<T, R, I> {
  private table: EntityTable<any, any>;
  private ZodSchema: ZodSchema<T>
  private ZodPartialSchema: ZodSchema<T>
  private db: Dexie

  constructor(table: EntityTable<any, any>, ZodSchema: ZodSchema, db?:Dexie) {
    this.table = table as any
    this.ZodSchema = ZodSchema
    this.ZodPartialSchema = (ZodSchema as ZodObject<any>).partial() as any;
    this.db = db
  }

  // Method to create a transaction and use the callback
  async createTransaction(callback: (table:I) => Promise<void>): Promise<void> {
    return this.db.transaction('rw', this.table, async () => {
      try {
        // Execute the callback function
        await callback(this.table as any);
      } catch (error) {
        // Transactions are automatically rolled back on error
        throw error;
      }
    }).then(() => {
      console.log('Transaction completed successfully');
    }).catch((error) => {
      console.error('Transaction failed: ' + error);
    });
  }


  async insert(document: T): Promise<RepositoryResult<any, T>> {

    const dataValidation = this.ZodSchema.safeParse(document)

    if(dataValidation.success) {
      try {
        const id = await this.table.add(dataValidation.data);
        return ok(id);
      } catch (_error) {
        const error: IDexieError = _error
        return err(new DBError({
          message: `dexie.js failed to insert into ${this.table.name}, ${error.message}`,
          parameters: document,
          error: error,
        }))
      }
    } else {
      return err(new DBError({
        message: `dexie.js failed to insert into ${this.table.name}, invalid data`,
        parameters: document,
        zodError: dataValidation.error
      }))
    }
  }

  async insertMany(documents: T[]): Promise<RepositoryResult<number[], T[]>> {
    // Validate each document
    const schema = this.ZodSchema.array()

    const validationResult = schema.safeParse(documents)
    if(!validationResult.success) {
      return err(new DBError({
        message: `dexie.js failed to insert many into ${this.table.name}, invalid data`,
        parameters: documents,
        zodError: validationResult.error
      }))
    }

    try {
      const ids = await this.table.bulkAdd(documents as any);
      return ok(ids);
    } catch (_error) {
      const error: IDexieError = _error
      return err(new DBError({
        message: `dexie.js failed to insert into many ${this.table.name}, ${error.message}`,
        parameters: documents,
        error: error
      }))
    }
  }

  async update(id: any, updatedDocument: Partial<T>) {

    const dataValidation = this.ZodPartialSchema.safeParse(updatedDocument)

    if(dataValidation.success) {
      try {
        const updatedCount = await this.table.update(id, dataValidation.data);
        return ok(updatedCount);
      } catch (_error) {
        const error: IDexieError = _error
        return err(new DBError({
          message: `dexie.js Failed to update  into ${this.table.name}, ${error.message} `,
          parameters: {
            ...updatedDocument,
            [this.table.schema.primKey.name]: id
          } as unknown as T,
          error: error
        }))
      }
    } else {
      return err(new DBError({
        message: `dexie.js failed to update into ${this.table.name}, invalid data`,
        parameters: {
          ...updatedDocument,
          [this.table.schema.primKey.name]: id
        } as unknown as T,
        zodError: dataValidation.error
      }))
    }
  }

  async updateMany(updatedDocument: Partial<T>[]) {

    const schema = this.ZodSchema.array()
    const dataValidation = schema.safeParse(updatedDocument)

    if(updatedDocument.length == 0) {
      console.log(`dexie.js failed to update many into ${this.table.name}, empty array)`)
    }

    if(dataValidation.success) {
      try {
        const updatedCount = await this.table.bulkPut(dataValidation.data);
        return ok(updatedCount);
      } catch (_error) {
        const error: IDexieError = _error
        return err(new DBError({
          message: `dexie.js Failed to update many into ${this.table.name}, ${error.message} `,
          parameters: document,
          error: error
        }))
      }
    } else {
      return err(new DBError({
        message: `dexie.js failed to update many into ${this.table.name}, invalid data`,
        parameters: updatedDocument ,
        zodError: dataValidation.error
      }))
    }
  }


  async delete(id: any): Promise<RepositoryResult<void, T>> {
    try {
      await this.table.delete(id);
      return ok(undefined);
    } catch (_error) {
      const error: IDexieError = _error
      return err(new DBError({
        message: `dexie.js Failed to delete  into ${this.table.name}, ${error.message} `,
        parameters: id,
        error: error
      }))
    }
  }

  async findById(id: any) {
    try {
      const document = await this.table.get(id);
      return ok(document);
    } catch (_error) {
      const error: IDexieError = _error
      return err(new DBError({
        message: `dexie.js Failed to delete  into ${this.table.name}, ${error.message} `,
        parameters: id,
        error: error
      }))
    }
  }

  async find(filter: Partial<T>): Promise<RepositoryResult<R[], T[]>>  {
    try {
      const documents: any = await this.table.where(filter).toArray();
      return ok(documents);
    } catch (_error) {
      const error: IDexieError = _error;
      return err(new DBError({
        message: `dexie.js Failed to find  into ${this.table.name}, ${error.message} `,
        parameters: filter as any,
        error: error
      }))
    }
  }

  async findOne(filter: Partial<T>): Promise<RepositoryResult<T & ILocalModel<T, R, I> | undefined, T>> {
    try {
      const document = await this.table.where(filter).first();

      if(document) {
        return ok(Object.assign(new LocalModel(this.table, this, this.db), document));
      }

      return ok(document);
    } catch (_error) {
      const error: IDexieError = _error
      return err(new DBError({
        message: `dexie.js Failed to findOne  into ${this.table.name}, ${error.message} `,
        parameters: filter as any,
        error: error
      }))
    }
  }

  async findAll(): Promise<RepositoryResult<(T & ILocalModel<T, R, I>)[], T>> {
    try {
      const documents = await this.table.toArray()
      return ok(documents);
    } catch (_error) {
      const error: IDexieError = _error
      return err(new DBError({
        message: `dexie.js Failed to findAll  into ${this.table.name}, ${error.message} `,
        parameters: null,
        error: error
      }))
    }
  }

  async count(filter?: Object): Promise<RepositoryResult<number, T>> {
    try {
      const count = filter ? await this.table.where(filter).count() : await this.table.count();
      return ok(count);
    } catch (_error) {
      const error: IDexieError = _error
      return err(new DBError({
        message: `dexie.js Failed to count  into ${this.table.name}, ${error.message}`,
        parameters: filter as any,
        error: error
      }))
    }
  }

  async clear() {
    try {
      const result =  await this.table.clear()
      return ok(result)
    } catch (e) {
      return err(false)
    }
  }
}

export class LocalModel<T, R, I> implements ILocalModel<T, R, I> {

  constructor(
    private table: EntityTable<any, any>,
    private repository: DexieRepository<T, R, I>,
    private db?:Dexie,
  ) {}

  save() {
    const primaryKey = this.table.schema.primKey.name
    const idValue = this[primaryKey]
    return this.repository.update(idValue, this as any)
  }

  delete() {
    const primaryKey = this.table.schema.primKey.name
    const idValue = this[primaryKey]
    return this.repository.delete(idValue)
  }

}
