import { EEventCategory, EEventOwnerType, EEventStatus, EAttendeeType } from "../../data/dto/enums";
import { EventOutputDTO } from "../../data/dto/eventDTOOutput";

function getTextInsideParentheses(inputString) {
  var startIndex = inputString.indexOf('(');
  var endIndex = inputString.indexOf(')');
  if (startIndex !== -1 && endIndex !== -1) {
      return inputString.substring(startIndex + 1, endIndex);
  } else {
      return null;
  }
}

export class EventMapper {

  constructor() {}
  static toDomain(_dto: EventOutputDTO) {

    const dto = _dto.data;

    let category;
    if(dto.category == EEventCategory.Oficial) {
      category = 'Oficial'
    } else {
      category = 'Pessoal'
    }

    let ownerType;
    if(dto.ownerType == EEventOwnerType.PR) {
      ownerType = 'PR'
    } else if(dto.ownerType == EEventOwnerType.MD) {
      ownerType = 'MD'
    } else if(dto.ownerType == EEventOwnerType.Others) {
      ownerType = 'Other'
    }

    let type;

    let status;
    if(dto.status == EEventStatus.Pending) {
      status = 'Pending'
    } else if(dto.status == EEventStatus.Revision) {
      status = 'Revision'
    }

    function FEAttendeeType(num) {
      if(num == EAttendeeType.Required) {
        return 'Required'
      } else if (num == EAttendeeType.Acknowledgment) {
        return 'Acknowledgment'
      } else if (num == EAttendeeType.Optional) {
        return 'Optional'
      }
    }


    return {
      "owner": dto.owner,
      "HasAttachments": dto.hasAttachments,
      "EventComunicationId": 1682,
      "EventId": dto.id,
      "Subject": dto.subject,
      "Body": {
        "BodyType": 1,
        "Text": dto.body
      },
      "Location": dto.location,
      "CalendarId": "",
      "CalendarName": category,
      "StartDate": dto.startDate,
      "EndDate": dto.endDate,
      "EventType": "Single",
      "Attendees": dto.attendees.map((e) => ({
        Id: e.id,
        wxUserId: e.wxUserId,
        EmailAddress: e.emailAddress,
        Name: e.name,
        IsRequired: FEAttendeeType(e.attendeeType) == 'Required',
        UserType: "GD",
        // "IsPR": false,
        attendeeType: FEAttendeeType(e.attendeeType)
        // "RoleDescription": null,
        // "RoleId": 0
      })),
      "Status": status,
      "IsMeeting": category,
      "IsRecurring": dto.isRecurring,
      "IsAllDayEvent": dto.isAllDayEvent,
      "AppointmentState": 1,
      "TimeZone": getTextInsideParentheses(new Date(dto.startDate)+ ''),
      "Organizer": {
          "Id": dto.organizer.wxUserId,
          "EmailAddress": dto.organizer.wxeMail,
          "Name": dto.organizer.wxFullName,
          "IsRequired": true,
          "UserType": 'GD',
          "IsPR": ownerType == 'PR',
          //"Entity": null,
          "Acknowledgment": true,
          //"RoleDescription": null,
          //"RoleId": 0
      },
      "InstanceId": null,
      "Category": dto.type,
      "EventRecurrence": {
          "Type": -1,
          "Day": null,
          "daysOfWeek": dto.eventRecurrence?.daysOfWeek.map(e => e.day),
          "Month": null,
          "LastOccurrence": null,
          "frequency": dto?.eventRecurrence?.frequency,
          "until": dto?.eventRecurrence?.until
      },
      "Attachments": dto.attachments.map( e => ({
        "Id": e.id,
        // "ParentId": "AAMkADVhOGY3ZDQzLTg4ZGEtNDYxMC1iMzc5LTJkMDgwNjMxOWFlZQBGAAAAAABEDW9nKs69TKQcVqQURj8YBwBR2HR2eO7pSpNdD9cc70l+AAAAAAFKAABR2HR2eO7pSpNdD9cc70l+AACK2OeJAAA=",
        // "Source": 1,
        "SourceId": e.docId,
        // "Description": "teste pp",
        "SourceName": e.sourceName,
        // "CreateDate": "2024-05-24 16:41",
        // "Stakeholders": "",
        // "Link": "",
        // "Data": null,
        "ApplicationId": e.applicationId,
        // "FileSize": 301208

      })),
      "IsPrivate": dto.isPrivate
    }
  }

  static toDTO(data: any): any {
    return {}
  }

}
