import { DataSourceReturn } from "src/app/services/Repositorys/type";
import { IMessageGetAllByRoomIdOutPut } from "../../usecase/message/message-get-all-by-room-Id";

export interface IGetMessagesFromRoomParams  {
 roomId: string
 lastMessageDate?: string
}

export abstract class IMessageRemoteRepository {
  abstract getMessagesFromRoom(input: IGetMessagesFromRoomParams): DataSourceReturn<IMessageGetAllByRoomIdOutPut>
}
