export enum RoleId {
  /**
   * @description President role id
   */
  PV = 100000011,
  MD = 100000011,

  /**
   * @description Vice president role id
   */
  PRES = 100000014,

  /**
   * @description Consultant role id
   */
  Consultant = 99999872
}
