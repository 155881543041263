import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { Network } from '@ionic-native/network/ngx';
import { MultipleDocumentsPicker } from '@awesome-cordova-plugins/multiple-document-picker/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { FFMpeg } from '@awesome-cordova-plugins/ffmpeg/ngx';
import { FFmpeg } from '@ffmpeg/ffmpeg';



import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient } from '@angular/common/http';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { CookieService } from 'ngx-cookie-service';

import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { Media } from '@ionic-native/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import {NgxImageCompressService} from 'ngx-image-compress';
import { CustomImageCachePageRoutingModule } from './services/file/custom-image-cache/custom-image-cache-routing.module';
import { IonicImageLoaderModule } from 'ionic-image-loader-v5';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';


import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';


// The example is using Angular, Import '@sentry/vue' or '@sentry/react' when using a Sibling different than Angular.
 import * as SentrySibling from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';
// The e xample is using Angular, Import '@sentry/vue' or '@sentry/react' when using a Sibling different than Angular.
// For automatic instrumentation (highly recommended)
import { Integration } from '@sentry/types';
import { BrowserTracing } from '@sentry/tracing';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { firebaseConfig } from '../firebase-config';
import { EditorModule } from '@tinymce/tinymce-angular';;
import { CreateProcessPageModule } from './modals/create-process/create-process.module';
import { CreateProcessPage } from './modals/create-process/create-process.page';
import { LoggingInterceptorService } from './services/logging-interceptor.service';
import { PopupQuestionPipe } from './modals/popup-question.pipe';
import '@teamhive/capacitor-video-recorder';
import { tokenInterceptor } from './interceptors/token.interceptors';

import { InputFilterDirective } from './services/directives/input-filter.directive';
import { VisibilityDirective } from './services/directives/visibility.directive';
import { DeplomaOptionsPageModule } from './shared/popover/deploma-options/deploma-options.module';
import { DiplomaOptionsPage } from './shared/popover/deploma-options/deploma-options.page';
import { ImageCropperModule } from 'ngx-image-cropper';
import { metricsInterceptor } from './interceptors/metter.interceptor';

import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { ChatModule } from './module/chat/chat.module';
import { openTelemetryLogging } from './services/monitoring/opentelemetry/logging';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LogsDatabase } from './infra/database/dexie/instance/logs/service';
import { UserModule } from './module/user/user.module';

// Register the locale data
registerLocaleData(localePt, 'pt');

  Sentry.init(
  {
    dsn: 'https://5b345a3ae70b4e4da463da65881b4aaa@o4504340905525248.ingest.sentry.io/4504345615794176',
    // To set your release and dist versions
    release: 'gabinetedigital@1.0.0',
    dist: '1',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', 'https://gd-api.oapr.gov.ao/api/'],
      }) as Integration,
    ],
    beforeSend(event) {
      console.log('event.exception.values[0].value', event.exception.values[0].value);

      if (event.level === 'error') {

        LogsDatabase.sentryError.add(event as any).then(() => {
          console.log('event', event)
        })

        openTelemetryLogging.send({
          type: 'graylog',
          payload: {
            message: event.exception.values[0].value,
            object: {
              sentry: true,
              error: event
            }
          },
          spanContext: null
        })
      }
      // Return event to send it to Sentry
      return event;
    },
  }
);

@NgModule({
  declarations: [AppComponent, PopupQuestionPipe, InputFilterDirective],
  imports: [BrowserModule,
    CommonModule,
    FormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    //AngularFireModule.initializeApp(environment.firebase),
    //AngularFireMessagingModule,
    IonicImageLoaderModule,
    IonicModule.forRoot({animated: false}),
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']

 }),

    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    // NgbModule,
    NoopAnimationsModule,
    MatDatepickerModule,

    //
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    NgxMatMomentModule,
    MatButtonModule,
    ReactiveFormsModule,

    MatSelectModule,
    MatDialogModule,
    //
    HammerModule,
    CustomImageCachePageRoutingModule,

    //
    MatInputModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    MatSelectModule,
    MatButtonModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireMessagingModule,
    EditorModule,
    // options
    DeplomaOptionsPageModule,
    CreateProcessPageModule,
    ImageCropperModule,
    MatMenuModule,
    MatIconModule,
    // module
    ChatModule,
    UserModule
  ],
  entryComponents: [
    DiplomaOptionsPage,
    CreateProcessPage
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
       useValue: SentrySibling.createErrorHandler(),
    },
    StatusBar,
    //SplashScreen,
    HttpClient,
    HttpClientModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    InAppBrowser,
    Camera,
    File,
    WebView,
    FilePath,
   /*  FCM,
    FirebaseX, */
    ScreenOrientation,
    Network,
    SQLite,
    CookieService,
    ImagePicker,
    MediaCapture,
    Media,
    StreamingMedia,
    PhotoViewer,
    NgxImageCompressService,
    MultipleDocumentsPicker,
    NgxExtendedPdfViewerModule,
    FileOpener,
    DocumentViewer,
    FFMpeg,
    FFmpeg,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptorService, multi: true },
    tokenInterceptor,
    metricsInterceptor
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, fab, far);
	}
}
