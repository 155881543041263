import { NgModule } from '@angular/core';
import { HttpModule } from 'src/app/infra/http/http.module';
import { IUserRemoteRepository } from 'src/app/core/user/repository/user-remote-repository';
import { UserRemoteRepositoryService } from './data/datasource/user-remote-repository.service';
import { UserService } from './domain/user.service'

import { UserLoginUseCaseService } from 'src/app/core/user/use-case/user-login-use-case.service';
import { UserLogOutUseCaseService } from 'src/app/core/user/use-case/user-log-out-use-case.service';
import { UserRefreshTokenService } from 'src/app/core/user/use-case/user-refresh-token.service';

@NgModule({
  imports: [HttpModule],
  providers: [
    {
      provide: IUserRemoteRepository,
      useClass: UserRemoteRepositoryService, // or MockDataService
    },
    // domain service
    UserService,
    // use case
    UserLoginUseCaseService,
    UserLogOutUseCaseService,
    UserRefreshTokenService
  ],
  declarations: [],
  schemas: [],
  entryComponents: [

  ],
})
export class UserModule {
  constructor() {}
}
