import { Injectable } from '@angular/core';
import { AgendaDataService } from '../../data/data-source/agenda-data.service';
import { z } from 'zod';

export const DraftSaveByIdInputSchema = z.object({
  description: z.string(),
  status: z.boolean(),
  content: z.string(),
  path: z.string(),
  ownerId: z.number(),
  id: z.number()
})

export type IDraftSaveByIdInput= z.infer< typeof DraftSaveByIdInputSchema>

@Injectable({
  providedIn: 'root'
})
export class DraftSaveByIdUseCaseService {

  constructor(
    private agendaDataService: AgendaDataService
  ) { }

  async execute(input: IDraftSaveByIdInput) {
    return await this.agendaDataService.draftSaveById(input)
  }
}
