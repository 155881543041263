import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid'
import { InstanceId } from '../../../domain/chat-service.service';
import { MessageUpdateInput } from '../../../../../core/chat/usecase/message/message-update-by-id-use-case.service';
import { MessageReactionInput } from '../../../../../core/chat/usecase/message/message-reaction-by-id-use-case.service';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { filter, map } from 'rxjs/operators';
import { SocketMessage } from 'src/app/infra/socket/signalR/signalR';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';
import { MessageCreateOutPutDataDTO, MessageInputDTO } from '../../../../../core/chat/usecase/message/message-create-use-case.service';
import { MessageMarkAsReadInput } from '../../../../../core/chat/usecase/message/message-mark-as-read-use-case.service';
import { MessageOutPutDataDTO } from 'src/app/core/chat/repository/dto/messageOutputDTO';
import { MessageDeleteInputDTO } from '../../../../../core/chat/usecase/message/message-delete-by-id-live-use-case.service';
import { BehaviorSubject } from 'rxjs';

interface sendDeliverAt {
  memberId: number,
 messageId:string,
 roomId: string,
 requestId: string
}

@Injectable({
  providedIn: 'root'
})
export class MessageSocketRepositoryService implements IMessageSocketRepository {

  private sendDataSubject: BehaviorSubject<{method: string, data: any}> = new BehaviorSubject<{method: string, data: any}>(null);

  constructor(
    private socket: SignalRService
  ) {}

  connect() {
    return this.socket.establishConnection();
  }

  async join() {
    return await this.socket.join()
  }


  async sendGroupMessage(data: MessageInputDTO) {

    if(!data.requestId) {
      data.requestId = InstanceId +'@'+ uuidv4();
    }

    const result =  await this.socket.sendData<MessageCreateOutPutDataDTO>({
      method: 'sendMessage',
      data: data,
    })

    this.sendDataSubject.next({
      method: 'sendMessage',
      data: data,
    })

    return result;
  }

  async sendDirectMessage(data: MessageInputDTO) {

    if(!data.requestId) {
      data.requestId = InstanceId +'@'+ uuidv4();
    }
    const result =  await this.socket.sendData<MessageOutPutDataDTO>({
      method: 'SendDirectMessage',
      data: data as any,
    })

    this.sendDataSubject.next({
      method: 'SendDirectMessage',
      data: data,
    })
    return result;
  }

  async sendDeliverAt(data: sendDeliverAt) {
    const result =  await this.socket.sendData<any>({
      method: 'DeliverAt',
      data: data as any,
    })

    return result;
  }

  async sendReadAt(data: MessageMarkAsReadInput) {
    const result =  await this.socket.sendData<any>({
      method: 'ReadAt',
      data: data as any,
    })

    return result;
  }

  async sendDelete(data: MessageDeleteInputDTO) {
    const result =  await this.socket.sendData<any>({
      method: 'ReadAt',
      data: data,
    })

    return result;
  }

  listenToMessages() {
    return this.socket.getData().pipe(
      filter((e) : e is SocketMessage<MessageOutPutDataDTO>=> e?.method == 'ReceiveMessage')
    )
  }


  listenToDeleteMessages() {
    return this.socket.getData().pipe(
      filter((e) : e is SocketMessage<MessageOutPutDataDTO>=> e?.method == 'DeleteMessage'
      ),
      map((e)=> e.data)
    )
  }

  listenToUpdateMessages() {
    return this.socket.getData().pipe(
      filter((e) : e is SocketMessage<MessageOutPutDataDTO>=> e?.method == 'UpdateMessage'
      ),
      map((e)=> e.data)
    )
  }

  listenToSendMessage() {
    return this.sendDataSubject.pipe(
      filter((e) : e is SocketMessage<MessageInputDTO>=> {
        console.log(e, e?.method == 'sendMessage' || e?.method == 'SendDirectMessage')

        return e?.method == 'sendMessage' || e?.method == 'SendDirectMessage'
      }
    ))


  }


  reactToMessageSocket(data: MessageReactionInput) {
    data['requestId'] = InstanceId +'@'+ uuidv4();

    return this.socket.sendData({
      method: 'ReactMessage',
      data
    })
  }

  updateMessage(input: MessageUpdateInput) {
    input['requestId'] = InstanceId +'@'+ uuidv4();

    this.socket.sendData({
      method: 'EditMessage',
      data: input,
    })
  }


  sendMessageDelete(data: MessageDeleteInputDTO) {

    data['requestId'] = InstanceId +'@'+ uuidv4();

    const result =  this.socket.sendData<any>({
      method: 'DeleteMessage',
      data: data,
    })

    return result;
  }

}
