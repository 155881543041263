import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';
import { MessageEntity } from 'src/app/core/chat/entity/message';
import { IBoldLocalRepository } from 'src/app/core/chat/repository/bold/bold-local-repository';
import { IMessageLocalRepository } from 'src/app/core/chat/repository/message/message-local-repository';
import { InstanceId } from '../../../../module/chat/domain/chat-service.service';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { IMessageGetAllByRoomIdOutPut } from 'src/app/core/chat/usecase/message/message-get-all-by-room-Id';


@Injectable({
  providedIn: 'root'
})
export class RoomBoldSyncUseCaseService {

  constructor(
    private MessageSocketRepositoryService: IMessageSocketRepository,
    private boldLocalRepository: IBoldLocalRepository,
    private http: HttpAdapter,
    private messageLocalRepository: IMessageLocalRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) {
    this.listenToIncomingMessage();
    // this.loadHistory()
    //this.onInsertToDB()
    this.listenToUpdateMessages();
    this.loadHistory()
  }

  /**
   *
   * @description listen to all incoming message and set bold to true
   */
  private listenToIncomingMessage() {
    return this.MessageSocketRepositoryService.listenToMessages().pipe(
      map(message => message.data),
      filter((message) => !message?.requestId?.startsWith(InstanceId)),
      map(message => Object.assign(new MessageEntity(), message)),
      filter((message) => !message.meSender())
    ).subscribe(async (message) => {

      const result = await this.boldLocalRepository.findOne({roomId: message.roomId})

      if(result.isOk() && !result.value) {
        const result = await this.boldLocalRepository.insert({roomId: message.roomId, bold: 1})
      } else if(result.isOk() && result.value.bold == 0) {
        const result = await this.boldLocalRepository.update(message.roomId, {bold: 1})
      }

    });
  }

  /**
   *
   * @description Listen to all update message. If the incoming updated message has readAt my me and is the last message then remove bold
   */
  private listenToUpdateMessages() {
    return this.MessageSocketRepositoryService.listenToUpdateMessages().pipe(
      filter((message) => !message?.requestId?.startsWith(InstanceId)),
      map(message => Object.assign(new MessageEntity(), message))
    ).subscribe(async (message) => {

      const haveSeen  = MessageEntity.haveSeen(message.info)

      if(haveSeen) {

        const result = await this.boldLocalRepository.findOne({roomId: message.roomId})

        if(result.isOk() && result.value?.bold == 1) {
          const lastMessage = await this.roomLocalDataSourceService.findOne({id: message.roomId})
          if(lastMessage.isOk()) {
            if(lastMessage.value?.messages[0]?.id == message.id) {
              const result = await this.boldLocalRepository.update(message.roomId, {bold: 0})
            }
          }

        }
      }

    });
  }

  /**
   * @description set bold base on the last message on load history. If the last message has readAt by me then remove bold in case not set bold
   */
  @XTracerAsync({name:'RoomBoldSyncUseCaseService/loadHistory', module:'chat',  bugPrint: true})
  private loadHistory() {
    const regex = new RegExp("Room\\/([0-9a-fA-F]{8})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{12})\\/Messages");

    return this.http.listen().pipe(
      filter((response)=> {
        return response?.isOk() && regex.test(response.value.url)
      }),
      map((response: any) => response.value.data as IMessageGetAllByRoomIdOutPut)
    ).subscribe(async (data)=> {
      const loadHistoryFirstMessage = data.data[0]
      if(loadHistoryFirstMessage) {

        const roomId = loadHistoryFirstMessage.roomId

        const room = await this.roomLocalDataSourceService.findOne({id: roomId})

        const message = Object.assign(new MessageEntity(), loadHistoryFirstMessage)
        const haveSeen  = message.haveSeen()

        if(haveSeen ===false && message.meSender() == false) {
          await this.boldLocalRepository.open()
          const result = await this.boldLocalRepository.findOne({roomId: roomId})

          if(result.isOk() && !result.value) {
            const result = await this.boldLocalRepository.insert({roomId: roomId, bold: 1})
          } else if(result.isOk() && result.value.bold == 0) {
            const result = await this.boldLocalRepository.update(roomId, {bold: 1})
          } else {
            // tracing.hasError("failed to set bold",{})
          }
        } else {
          const result = await this.boldLocalRepository.update(roomId, {bold: 0})
        }


      }
    })
  }
}
