export let versionData = {
  "shortSHA": "5a1bbe610",
  "SHA": "5a1bbe6103c983534e6a66d69a7344a8520d67f5",
  "branch": "feature/login-v2",
  "lastCommitAuthor": "'Peter Maquiran'",
  "lastCommitTime": "'Thu Dec 5 19:14:11 2024 +0100'",
  "lastCommitMessage": "fix issues",
  "lastCommitNumber": "6134",
  "changeStatus": "On branch feature/login-v2\nYour branch is ahead of 'origin/feature/login-v2' by 1 commit.\n  (use \"git push\" to publish your local commits)\n\nChanges to be committed:\n  (use \"git restore --staged <file>...\" to unstage)\n\tnew file:   src/app/core/notification/repository/notification-remote-repository.ts\n\tnew file:   src/app/core/notification/use-case/notification-delete-all-use-case.service.ts\n\tmodified:   src/app/module/notification/data/datasource/remote-notification.service.ts\n\tdeleted:    src/app/module/notification/data/notification-repository.service.spec.ts\n\tnew file:   src/app/module/notification/domain/notification.service.ts\n\tnew file:   src/app/module/notification/notification.module.ts\n\tmodified:   src/app/pages/gabinete-digital/gabinete-digital.page.html\n\tmodified:   src/app/ui/chat/chat.page.ts\n\tmodified:   src/environments/suport/dev.ts\n\tmodified:   version/git-version.ts",
  "changeAuthor": "peter.maquiran"
}