import { Injectable, ErrorHandler } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { UserForm, UserSession } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, of } from 'rxjs';
import { AlertController, Platform } from '@ionic/angular';
import { SessionStore } from '../store/session.service';
import { AESEncrypt } from '../services/aesencrypt.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { InitialsService } from './functions/initials.service';
import { PermissionService } from './permission.service';
// import { ChatSystemService } from 'src/app/services/chat/chat-system.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { captureException } from '@sentry/angular';
import { catchError, tap } from 'rxjs/operators';
import { UserLoginOutput } from '../core/user/use-case/user-login-use-case.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData$ = new BehaviorSubject<any>('');
  userId$ = new BehaviorSubject<any>('');
  headers: HttpHeaders = new HttpHeaders();
  public wsValidatedUserChat: any;
  public isWsAuthenticated: boolean = false;
  opts: any;

  tabIsActive = true

  constructor(
    private http: HttpClient,
    public alertController: AlertController,
    private aesencrypt: AESEncrypt,
    // private RochetChatConnectorService: RochetChatConnectorService,
    private router: Router,
    // private NfService: NfService,
    private initialsService: InitialsService,
    public p: PermissionService,
    // public ChatSystemService: ChatSystemService,
    private httpErroHandle: HttpErrorHandle,
    private errorHandler: ErrorHandler,
    private platform: Platform,) {

  }

  async login(user: UserForm, { saveSession = true }): Promise<UserSession> {
    user.BasicAuthKey = btoa(user.username + ':' + this.aesencrypt.encrypt(user.password, user.username));

    this.headers = this.headers.set('Authorization', user.BasicAuthKey);

    this.opts = {
      /* headers: this.headers, */
      "Content-Type": "application/json",
      "Accept": "application/json",
    }
    let channelId;
    if ( this.platform.is('desktop') || this.platform.is("mobileweb")){
      channelId = 2
    } else {
      channelId = 1
    }

    let body = {
      "Auth": user.BasicAuthKey,
      "ChannelId": channelId
    }

    let response: any;

    try {
      response = await this.http.post<UserSession>(environment.apiURL + "UserAuthentication/Login", body, this.opts).toPromise();


      if (saveSession) {
        // this.SetSession(response, user)
      }
    } catch (error) {
      this.errorHandler.handleError(error);
      this.httpErroHandle.loginHttpStatusHandle(error)
      captureException(error);

      if(error?.status == 403) {
        console.log('error?.status == 403')
      }

    } finally {
      return response
    }

  }

  // async loginContenteProduction(user: UserForm, { saveSession = true }): Promise<UserSession> {
  //   user.BasicAuthKey = 'Basic ' + btoa(user.username + ':' + this.aesencrypt.encrypt(user.password, user.username));

  //   this.headers = this.headers.set('Authorization', user.BasicAuthKey);
  //   this.opts = {
  //     headers: this.headers,
  //   }

  //   let response: any;

  //   try {
  //     response = await this.http.post<UserSession>(environment.apiURL + "UserAuthentication/Login", '', this.opts).toPromise();
  //     console.log('JWT', response)

  //     if (saveSession) {
  //       /* this.SetSession(response, user) */
  //       console.log('teste', response);

  //       return response

  //     }
  //   } catch (error) {
  //     this.httpErroHandle.httpStatusHandle(error)
  //   } finally {
  //     return response
  //   }

  // }

  // async UpdateLogin() {}

  autologout(expirationDate: number) {
    setTimeout(() => {
      this.logout();
    }, expirationDate)
  }

  logout() {

    SessionStore.setInativity(false)
    SessionStore.setUrlBeforeInactivity(this.router.url);
    setTimeout(() => {
      this.router.navigateByUrl('/', { replaceUrl: true });
    }, 100)

  }

  logoutChat() {
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensagem do sistema',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  // async logoutUser() {

  //   this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);

  //   this.opts = {
  //     headers: this.headers,
  //   }

  //   let response: any;
  //   try {
  //     response = await this.http.delete<UserSession>(environment.apiURL + "userauthentication/Logout", this.opts).toPromise();
  //     SessionStore.user.Authorization = "";
  //     SessionStore.user.RefreshToken = "";
  //   } catch (error) {
  //     this.errorHandler.handleError(error);
  //     this.httpErroHandle.loginHttpStatusHandle(error)
  //     captureException(error);

  //     if(error?.status == 403) {
  //       console.log('error?.status == 403')
  //     }

  //   } finally {
  //     return response
  //   }
  // }

  // refreshToken() {

  //   return this.http
  //     .put<any>(environment.apiURL + "UserAuthentication/RefreshToken", {
  //       refreshToken: SessionStore.user.RefreshToken,
  //     },)
  //     .pipe(
  //       tap((tokens) => {
  //         console.log(tokens)
  //         SessionStore.user.Authorization = tokens.Authorization;
  //         SessionStore.user.RefreshToken = tokens.refreshToken;

  //       }),
  //       catchError((error) => {
  //         this.logoutUser();
  //         return of(false);
  //       })
  //     );
  // }
}
