import { Injectable } from '@angular/core';
import { messageListDetermineChanges } from '../../../../module/chat/data/async/list/rooms/messageListChangedetector';
import { MessageRemoteDataSourceService } from '../../../../module/chat/data/repository/message/message-remote-data-source.service';
import { MessageSocketRepositoryService } from '../../../../module/chat/data/repository/message/message-live-signalr-data-source.service';
import { ok } from 'neverthrow';
import { RoomLocalRepository } from '../../../../module/chat/data/repository/room/room-local-repository.service';
import { Logger } from 'src/app/services/logger/main/service';
import { XTracerAsync, TracingType } from 'src/app/services/monitoring/opentelemetry/tracer';
import { MessageTable } from 'src/app/infra/database/dexie/instance/chat/schema/message';
import { IDBoolean } from 'src/app/infra/database/dexie/type';
import { IMessageLocalRepository } from '../../repository/message/message-local-repository';
import { IMessageRemoteRepository } from '../../repository/message/message-remote-repository';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';
import { IRoomLocalRepository } from '../../repository/room/room-local-repository';

@Injectable({
  providedIn: 'root'
})
export class SyncAllRoomMessagesService {

  constructor(
    private messageLocalDataSourceService: IMessageLocalRepository,
    private messageRemoteDataSourceService: IMessageRemoteRepository,
    private MessageSocketRepositoryService: IMessageSocketRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) { }

  @XTracerAsync({name:'SyncAllRoomMessagesService', module:'chat',  bugPrint: true})
  async execute(tracing?: TracingType) {

    const allRooms = await this.roomLocalDataSourceService.findAll()

    if(allRooms.isOk()) {
      if(allRooms.value.length == 0) {
        tracing.addEvent('no need to sync')
      } else {
        tracing.addEvent('total sync '+ allRooms.value.length)
      }

      let n =0

      const removeLocalRooms = allRooms.value.filter((e)=> e.local != IDBoolean.true)

      const roomPromises = removeLocalRooms.map(async (room) => {
        const lastMessage = room?.messages?.find(e => e.sentAt && e.id)

        const [result, localResult] = await Promise.all([
          this.messageRemoteDataSourceService.getMessagesFromRoom({roomId:room.id, lastMessageDate: lastMessage?.sentAt || null}),
          this.messageLocalDataSourceService.getItems(room.id)
        ]);

        tracing.addEvent('async n ' + n);
        n++;

        if (result.isOk()) {
          const { addedItems, changedItems, deletedItems } = messageListDetermineChanges(result.value.data, localResult);

          for (const message of changedItems) {

            let clone: MessageTable = { ...message, roomId: room.id };
            this.messageLocalDataSourceService.update(clone.$id, clone);
          }

          for (const message of addedItems) {
            let clone: MessageTable = { ...message, roomId: room.id };
            // You can perform operations with addedItems here if needed
          }

          this.messageLocalDataSourceService.insertMany(addedItems.reverse().map(e => {
            e.origin = 'history'
            return e
          }));
        } else {
          Logger.error('failed to get room message ' + room.id);
        }
      });

      // Wait for all the promises to resolve
      await Promise.all(roomPromises);

      return ok(true)
    } else {
      console.log('sync all error', allRooms)
    }

  }
}
