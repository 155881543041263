import { Injectable } from '@angular/core';
import { z } from 'zod';
import { ValidateSchema } from 'src/app/services/decorators/validate-schema.decorator';
import { MessageRemoteDataSourceService } from '../../../../module/chat/data/repository/message/message-remote-data-source.service';
import { MessageSocketRepositoryService } from '../../../../module/chat/data/repository/message/message-live-signalr-data-source.service';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';


const MessageUpdateInputDTOSchema = z.object({
  memberId: z.number(),
  messageId: z.string(),
  roomId: z.string(),
  message: z.string().optional().nullable(),
  requestId: z.string().optional()
})

export type MessageUpdateInput = z.infer< typeof MessageUpdateInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class MessageUpdateUseCaseService {

  constructor(
    public repository: IMessageSocketRepository
  ) { }

  @ValidateSchema(MessageUpdateInputDTOSchema)
  execute(input: MessageUpdateInput) {
    return this.repository.updateMessage(input);
  }

}
