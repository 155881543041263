import { Injectable } from '@angular/core';
import { isHttpResponse } from 'src/app/infra/http/http.service';
import { captureAndReraiseAsync } from 'src/app/services/decorators/captureAndReraiseAsync';
import { z } from 'zod';
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';
import { IMemberLocalRepository } from 'src/app/core/chat/repository/member/member-local-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { GetRoomByIdMapper } from 'src/app/core/chat/mapper/getRoomByIdMapper';
import { RoomEntity, RoomType } from 'src/app/core/chat/entity/group';
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';
import { zodSafeValidation } from 'src/app/utils/zodValidation';
import { CronJobService } from 'src/app/utils/task-scheduler'

const UserSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string().nullable(),
});

const MemberSchema = z.object({
  id: z.string(),
  user: UserSchema,
  joinAt: z.string(),
  isAdmin: z.boolean()
});

export const RoomByIdOutputDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.object({
    id: z.string(),
    roomName: z.string(),
    createdBy: UserSchema,
    createdAt: z.string(),
    expirationDate: z.string().nullable(),
    roomType: z.number(),
    members: z.array(MemberSchema),
  }),
})

export type RoomByIdMemberItemOutputDTO = z.infer<typeof MemberSchema>
export type RoomByIdOutputDTO = z.infer<typeof RoomByIdOutputDTOSchema>



export const RoomByIdInputDTOSchema =  z.string()
export type RoomByIdInputDTO = z.infer<typeof RoomByIdInputDTOSchema>

@Injectable({
  providedIn: 'root'
})
export class GetRoomByIdUseCaseService {

  constructor(
    private roomRemoteDataSourceService: IRoomRemoteRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
    private MemberListLocalRepository: IMemberLocalRepository,
    private CronJobService: CronJobService
  ) { }

  @captureAndReraiseAsync('RoomRepositoryService/getRoomById')
  async execute(id: RoomByIdInputDTO) {

    const result = await this.roomRemoteDataSourceService.getRoom(id)

    if(result.isOk()) {
      const validData = zodSafeValidation<RoomByIdOutputDTO>(RoomByIdOutputDTOSchema, result.value)

      if(validData.isOk()) {
        const localListRoom = await this.roomLocalDataSourceService.findAll()
        if(localListRoom.isOk()) {

          const getRoomById = await this.roomLocalDataSourceService.findOne({id:validData.value.data.id})
          if(getRoomById.isOk() && getRoomById.value) {
            const room  = GetRoomByIdMapper.toDomain(validData.value)

            const added: Partial<RoomEntity> = addedDiff(getRoomById.value, room);
            const deleted: Partial<RoomEntity> = deletedDiff(getRoomById.value, room);
            const updated: Partial<RoomEntity> = updatedDiff(getRoomById.value, room);

            delete added.members
            if(room.roomType == RoomType.Direct) {
              delete updated.roomName
            }

            if(Object.keys(added).length >= 1 || Object.keys(updated).length >= 1) {
              console.log('added', added);
              console.log('deleted', deleted);
              console.log('updated', updated);
              this.roomLocalDataSourceService.update(room.id, room)
            }

          } else if (getRoomById.isOk() && !getRoomById.value) {
            const room  = GetRoomByIdMapper.toDomain(validData.value)
            const createResult = await this.roomLocalDataSourceService.insert(room)

            if(createResult.isErr()) {
              console.error('createResultErr', createResult.error);
            }

          }

        }


        if(validData.value.data.expirationDate) {
          this.CronJobService.createCronJob(`remove expired room ${id}`, new Date(validData.value.data.expirationDate), () => {
            setTimeout(() => {
              this.execute(id);
            }, 60000);
          })
        }


      } else {

      }

    } else if (isHttpResponse(result.error) ) {
      if(result.error.status == 404) {
        await this.roomLocalDataSourceService.delete(id)
      }
      // this.httpErrorHandle.httpStatusHandle(result.error)
    }

    return result
  }
}
