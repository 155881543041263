import { Injectable } from '@angular/core';
import { AttachmentRemoteDataSourceService } from 'src/app/module/chat/data/repository/attachment/attachment-remote-repository.service'
import { Logger } from 'src/app/services/logger/main/service';
import { convertBlobToDataURL, createBlobUrl } from 'src/app/utils/ToBase64';
import { AttachmentLocalDataSource } from 'src/app/module/chat/data/repository/attachment/attachment-local-repository.service'
import { z } from 'zod';
import { zodSafeValidation } from 'src/app/utils/zodValidation';
import { IMessage, MessageEntitySchema } from 'src/app/core/chat/entity/message';
import { IAttachmentRemoteRepository } from '../../repository/attachment/attachment-remote-repository';
import { IAttachmentLocalRepository } from '../../repository/typing/typing-local-repository';

const DownloadMessageAttachmentByMessageIdSchema = z.object({
  $messageId: z.string(),
  id: z.string()
})

export type DownloadMessageAttachmentByMessageId = z.infer<typeof DownloadMessageAttachmentByMessageIdSchema>


@Injectable({
  providedIn: 'root'
})
export class DownloadMessageAttachmentUserCaseService {

  constructor(
    private AttachmentRemoteDataSourceService: IAttachmentRemoteRepository,
    private AttachmentLocalDataSource: IAttachmentLocalRepository
  ) { }

  async execute(input: DownloadMessageAttachmentByMessageId) {

    const validation = zodSafeValidation<IMessage>(DownloadMessageAttachmentByMessageIdSchema, input)

    if(validation.isOk()) {

      const httpResult = await this.AttachmentRemoteDataSourceService.getAttachment(input.id)
      return httpResult.asyncMap(async (response) => {

        const dataUrl = await createBlobUrl(response.data)

        if(dataUrl.isOk()) {

          Logger.info('downloaded file #1', {
            // data: dataUrl.slice(0, 100)+'...',
            context: 'DownloadMessageAttachmentUserCaseService'
          })

          this.AttachmentLocalDataSource.insert({
            $messageId: input.$messageId,
            id: input.id,
            file: response.data,
          })

          return dataUrl.value
        } else {

        }
      })
    } else {

      Logger.error('failed to download message doe to invalid attachment', {
        zodErrorList: validation.error.errors,
        data: input
      })

      return validation
    }

  }
}
