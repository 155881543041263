import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { MessageEntity } from 'src/app/core/chat/entity/message';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';
import { InstanceId } from '../chat-service.service';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';

@Injectable({
  providedIn: 'root'
})
export class RoomLastMessageService {

  constructor(
    private MessageSocketRepositoryService: IMessageSocketRepository,
    private roomLocalRepository: IRoomLocalRepository
  ) {
    // this.listenToIncomingMessage()
  }

  listenToIncomingMessage() {
    return this.MessageSocketRepositoryService.listenToMessages().pipe(
      map(message => message.data),
      filter((message) => !message?.requestId?.startsWith(InstanceId)),
      map(message => Object.assign(new MessageEntity(), message))
    ).subscribe(async (message) => {
      this.roomLocalRepository.update(message.roomId, {
        messages: [message]
      })

    });

  }
}
