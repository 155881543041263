import { EntityTable } from 'Dexie';
import { MessageAttachmentFileType, MessageAttachmentSource } from 'src/app/core/chat/entity/message';
import { z } from 'zod';

export const MessageTableSchema = z.object({
  $id: z.string().optional(),
  $createAt: z.number().optional(),
  id: z.string().uuid().optional(),
  roomId: z.string().uuid().optional(),
  message: z.string().nullable().optional(),
  requestId: z.string().nullable().optional(),
  messageType: z.number(),
  canEdit: z.boolean(),
  oneShot: z.boolean(),
  sentAt: z.string().optional(),
  editedAt: z.string().nullable().optional(),
  isDeleted: z.boolean().optional(),
  requireUnlock: z.boolean(),
  sender: z.object({
    wxUserId: z.number(),
    wxFullName: z.string(),
    wxeMail: z.string(),
    userPhoto: z.string().nullable().optional(),
  }),
  receiverId: z.number().optional(),
  sending: z.boolean().optional(),
  reactions: z.object({
    id: z.string(),
    reactedAt: z.string(),
    reaction: z.string(),
    sender: z.object({}),
  }).array().optional(),
  info: z.array(z.object({
    memberId: z.number(),
    readAt: z.string().nullable(),
    deliverAt: z.string().nullable()
  })).optional(),
  attachments: z.array(z.object({
    fileType: z.nativeEnum(MessageAttachmentFileType),
    source: z.nativeEnum(MessageAttachmentSource),
    fileName: z.string().optional(),
    applicationId: z.number().optional(),
    docId: z.number().optional(),
    id: z.string().optional(),
    description: z.string().nullable().optional(),
    mimeType: z.string().nullable().optional()
  })).optional(),
  origin: z.enum(['history', 'local', 'incoming']).optional()
})

export type MessageTable = z.infer<typeof MessageTableSchema>
export type DexieMessageTable = EntityTable<MessageTable, '$id'>;
export const messageTableColumn = '$id, id, roomId, senderId, receiverId, message, messageType, canEdit, oneShot, requireUnlock, sending'

