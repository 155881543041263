import { Injectable } from '@angular/core';
import { liveQuery, Observable } from 'Dexie';
import { DexieRepository } from 'src/app/infra/repository/dexie/dexie-repository.service';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { RoomTable, RoomTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/room';
import { chatDatabase } from 'src/app/infra/database/dexie/instance/chat/service';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { IDBoolean } from 'src/app/infra/database/dexie/type';
import { RoomType } from 'src/app/core/chat/entity/group';

@Injectable({
  providedIn: 'root'
})
export class RoomLocalRepository extends DexieRepository<RoomTable, RoomTable> implements IRoomLocalRepository  {

  private listenCreateSyncSubject : Subject<RoomTable> = new Subject<RoomTable>();

  constructor() {
    super(chatDatabase.room, RoomTableSchema)

    chatDatabase.room.hook('creating', (primaryKey, obj, transaction) => {

      if(obj.id && !obj.$id && obj.roomType == RoomType.Group) {
        obj.$id = obj.id
      }

      if(obj.id) {
        obj.local = IDBoolean.false
      } else {
        obj.local = IDBoolean.true
      }

    });


    chatDatabase.room.hook('updating', (modifications, primKey, oldValue, transaction) => {

      // if((modifications as Partial<RoomTable>).messages?.[0].requestId == oldValue.messages?.[0].requestId) {
      //   (modifications as Partial<RoomTable>).messages[0].sentAt = oldValue.messages?.[0]?.sentAt;
      // } else if ((modifications as Partial<RoomTable>).messages?.[0].id == oldValue.messages?.[0].id) {
      //   (modifications as Partial<RoomTable>).messages[0].sentAt = oldValue.messages?.[0]?.sentAt
      // }

      if((modifications as Partial<RoomTable>).id || oldValue.id) {
        (modifications as Partial<RoomTable>).local = IDBoolean.false
      } else {
        (modifications as Partial<RoomTable>).local = IDBoolean.true
      }

      if((modifications as Partial<RoomTable>).id && !oldValue.id && oldValue.roomType == RoomType.Direct) {
        return this.listenCreateSyncSubject.next({...oldValue, ...modifications})
      }

      return modifications
    });

  }

  getItemsLive() {
    return from (liveQuery(() => chatDatabase.room.toArray()));
  }

  getRoomByIdLive(id: any) {
    return from(liveQuery(() => chatDatabase.room.get(id)));
  }


  OnSetIdToDirectRoom() {
    return this.listenCreateSyncSubject.asObservable()
  }

}

