import { Injectable } from '@angular/core';
import { IMemberSocketRepository } from 'src/app/core/chat/repository/member/member-socket-repository';
import { z } from 'zod';

export const RemoveRoomMemberInputSchema = z.object({
  roomId: z.string(),
  members: z.number().array()
})

export type RemoveRoomMemberInput = z.infer<typeof RemoveRoomMemberInputSchema>



export const RemoveRoomMemberOutputSchema = z.object({
  userPhoto: z.string().nullable(),
  wxFullName: z.string().optional(),
  wxUserId: z.number(),
  wxeMail: z.string(),
}).array()

export type IRemoveRoomMemberOutput = z.infer<typeof RemoveRoomMemberOutputSchema>

@Injectable({
  providedIn: 'root'
})
export class MemberRemoveSocketUseCaseService {

  constructor(
    private socketRepository: IMemberSocketRepository
  ) { }

  async execute(input: RemoveRoomMemberInput) {

    return this.socketRepository.removeMember(input)
  }
}
