import { Injectable } from '@angular/core';
import { Dexie, EntityTable, liveQuery, Observable } from 'Dexie';
import { z } from 'zod';
import { DexieRepository } from 'src/app/infra/repository/dexie/dexie-repository.service';
import { ok } from 'neverthrow';
import { err, Result } from 'neverthrow';
import { MemberListUPdateStatusInputDTO } from '../../../../../core/chat/usecase/socket/member-list-update-status-use-case.service';
import { from } from 'rxjs';
import { MemberTable, MemberTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/members';
import { chatDatabase } from 'src/app/infra/database/dexie/instance/chat/service';
import { IDirectMemberInput, IGetMemberLive, IMemberLocalRepository } from 'src/app/core/chat/repository/member/member-local-repository';

@Injectable({
  providedIn: 'root'
})
export class MemberListLocalRepository extends DexieRepository<MemberTable, MemberTable> implements IMemberLocalRepository {

  constructor() {
    super(chatDatabase.members, MemberTableSchema)
  }


  async directMember({roomId, currentUserId}:IDirectMemberInput) {
    try {
      let a = await chatDatabase.members.where('roomId')
      .equals(roomId)
      .and(message => message.wxUserId !== currentUserId)
      .first()

      return ok(a as MemberTable)
    } catch (e) {
      return err(e)
    }
  }

  async addMember(data: MemberTable) {
    data.$roomIdUserId = data.roomId + data.wxUserId
    return this.insert(data)
  }

  async updateMemberRole(data: MemberTable) {
    try {
      const result = await chatDatabase.members.where({
        wxUserId: data.wxUserId,
        roomId: data.roomId,
      }).modify(data);

      return ok(result)
    } catch (e) {
      return err(false)
    }
  }

  async updateMembersStatus(data: MemberListUPdateStatusInputDTO):  Promise<Result<true, any>> {
    try {
      await chatDatabase.members.toCollection().modify({ status: 'offline' });
      for (const item of data) {
        const wxUserId = item.value.userId; // Extract wxUserId
        await chatDatabase.members.where('wxUserId').equals(wxUserId).modify({ status: 'online' });
      }
      return ok(true)
    } catch (error) {
      console.error("Error updating user statuses:", error);
      return err(error)
    }
  }

  allMemberOnline(roomId:string) {
    return liveQuery(async () => {

      const allMessages = await chatDatabase.members
        .where('roomId')
        .equals(roomId)
        .toArray();

      return allMessages.every(message => message?.status === "online");
    });
  }

  async removeMemberFromRoom($roomIdUserId): Promise<Result<any ,any>> {
    try {
      const member = await chatDatabase.members.where({ $roomIdUserId: $roomIdUserId }).first();
      if (member) {
        const result =  ok(await chatDatabase.members.delete($roomIdUserId));
        return result
      } else {
        return err('not Found')
      }

    } catch (e) {
      return err(false)
    }
  }


  getMemberLive(data: IGetMemberLive) {
    const $roomIdUserId = data.roomId + data.wxUserId
    return liveQuery(() => chatDatabase.members.get($roomIdUserId));
  }

  async getRoomMemberById(roomId: any) {
    return await  chatDatabase.members.where({roomId}).toArray()
  }
  getRoomMemberByIdLive(roomId: any) {
    return from (liveQuery(() => chatDatabase.members.where({roomId}).toArray()))
  }

  getRoomMemberNoneAdminByIdLive(roomId: any) {
    return liveQuery(async() => {
      const members = await chatDatabase.members.where({roomId}).toArray()
      return members.filter(e => e.isAdmin != true)
    })
  }
}
