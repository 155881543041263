import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import "hammerjs"; // HAMMER TIME
import { SendIntent } from "send-intent";
import { Filesystem } from '@capacitor/filesystem';
import 'src/app/services/shareIntent'
import 'src/app/services/monitoring/opentelemetry/matrix';
// import { SourceMapConsumer } from 'source-map';
//import { SourceMapConsumer } from 'source-map-js';
if (environment.production) {
  enableProdMode();
}
/*  Sentry.init(
  {
    dsn: 'https://5b345a3ae70b4e4da463da65881b4aaa@o4504340905525248.ingest.sentry.io/4504345615794176',
    // To set your release and dist versions
    release: 'gabinetedigital@1.0.0',
    dist: '1',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', 'https://gd-api.oapr.gov.ao/api/'],
      }) as Integration,
    ]
  },
  // Forward the init method to the sibling Framework.
  SentrySibling.init
); */


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

  // Call the element loader after the platform has been bootstrapped

defineCustomElements(window);


// const trace = `
// Error: Uncaught (in promise): TypeError: Cannot set properties of undefined (setting 'Oficial')
// TypeError: Cannot set properties of undefined (setting 'Oficial')
//     at main-es2017.251dcec9a0796e6dfef5.js:1:2853300
//     at Generator.next (<anonymous>)
//     at r (main-es2017.251dcec9a0796e6dfef5.js:1:5152059)
//     at s (main-es2017.251dcec9a0796e6dfef5.js:1:5152261)
//     at l.invoke (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:6540)
//     at Object.onInvoke (main-es2017.251dcec9a0796e6dfef5.js:1:439735)
//     at l.invoke (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:6480)
//     at t.run (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:1939)
//     at polyfills-es2017.7eddec01cc10d4e8cd34.js:1:16627
//     at l.invokeTask (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:7158)
//     at Object.onInvokeTask (main-es2017.251dcec9a0796e6dfef5.js:1:439551)
//     at l.invokeTask (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:7079)
//     at t.runTask (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:2554)
//     at m (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:9152)
//     at u.invokeTask [as invoke] (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:8237)
//     at _ (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:20025)
//     at XMLHttpRequest.k (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:20346)
//     at Z (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:15821)
//     at polyfills-es2017.7eddec01cc10d4e8cd34.js:1:14908
//     at r (main-es2017.251dcec9a0796e6dfef5.js:1:5152094)
//     at s (main-es2017.251dcec9a0796e6dfef5.js:1:5152261)
//     at l.invoke (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:6540)
//     at Object.onInvoke (main-es2017.251dcec9a0796e6dfef5.js:1:439735)
//     at l.invoke (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:6480)
//     at t.run (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:1939)
//     at polyfills-es2017.7eddec01cc10d4e8cd34.js:1:16627
//     at l.invokeTask (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:7158)
//     at Object.onInvokeTask (main-es2017.251dcec9a0796e6dfef5.js:1:439551)
//     at l.invokeTask (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:7079)
//     at t.runTask (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:2554)
//     at m (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:9152)
//     at u.invokeTask [as invoke] (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:8237)
//     at _ (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:20025)
//     at XMLHttpRequest.k (polyfills-es2017.7eddec01cc10d4e8cd34.js:1:20346)
// `;

// const regex = /([a-zA-Z0-9._-]+\.js):(\d+):(\d+)/g;
// const matches = [];
// let match;

// while ((match = regex.exec(trace)) !== null) {
//   matches.push({
//     file: match[1],
//     line: parseInt(match[2], 10),
//     column: parseInt(match[3], 10)
//   });
// }

// function parseErrorStack(stack) {
//   const lines = stack.split('\n');

//   const errorMessage = lines.slice(0, 2).join('\n').trim();
//   const fileLocation = lines.find(line => line.includes('.js'));

//   if (fileLocation) {
//     const locationMatch = fileLocation.match(/(.*\.js):(\d+):(\d+)/);
//     if (locationMatch) {
//       const [, filePath, lineNumber, columnNumber] = locationMatch;
//       return {
//         errorMessage,
//         filePath,
//         lineNumber,
//         columnNumber
//       };
//     }
//   }

//   return { errorMessage, filePath: null, lineNumber: null, columnNumber: null };
// }

// async function fetchAndHandleSourceMap(matches) {
//   try {
//     const mapResponse = await fetch('https://gd-fo-dev.dyndns.info/main-es2017.7f4c6682bbbe369ef4d8.js.map');
//     const mapContent = await mapResponse.json();

//     // Create SourceMapConsumer
//     const consumer = await new SourceMapConsumer(mapContent);

//     console.log({matches})
//     for (const match of matches) {
//       // Use the source map to find the original position
//       let originalPosition = consumer.originalPositionFor({
//         line: match.line,
//         column: match.column
//       });

//       if (originalPosition.source && originalPosition.source.includes('src')) {
//         console.log(`Original file: ${originalPosition.source}, Line: ${originalPosition.line}, Column: ${originalPosition.column}`);

//         // Extract the source content for the original file
//         const code = consumer.sourceContentFor(originalPosition.source);

//         // Output the specific line from the source
//         const lines = code.split('\n');
//         const lineNumber = originalPosition.line;
//         if (lineNumber >= 1 && lineNumber <= lines.length) {
//           console.log(`Line ${lineNumber}: ${lines[lineNumber - 1]}`);
//         } else {
//           console.log(`Line ${lineNumber} is out of range. The code has ${lines.length} lines.`);
//         }
//       } else {
//         console.log(originalPosition)
//       }

//     }


//   } catch (error) {
//     console.error('Error handling source map:', error);
//   }
// }

// const parsedError = parseErrorStack(trace);
// console.log('Error Message:', parsedError.errorMessage);
// console.log('File Path:', parsedError.filePath);
// console.log('Line:', parsedError.lineNumber);
// console.log('Column:', parsedError.columnNumber);

// fetchAndHandleSourceMap(matches);

