import { Injectable } from '@angular/core';
import { RoomType } from 'src/app/core/chat/entity/group';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { IDBoolean } from 'src/app/infra/database/dexie/type';
import { ContactRepositoryService } from 'src/app/services/Repositorys/contacts/repository/contacts-repository.service';
import { SessionStore } from 'src/app/store/session.service';

@Injectable({
  providedIn: 'root'
})
export class ContactListService {

  constructor(
    private contactsRepositoryService: ContactRepositoryService,
    private roomLocalRepository: IRoomLocalRepository
  ) {}

  async execute() {
    const [userContact, localDirectRooms] = await Promise.all([
      this.contactsRepositoryService.getUsers(),
      this.roomLocalRepository.find({roomType: RoomType.Direct})
    ])

    if(userContact.isOk() && localDirectRooms.isOk()) {
      const existNames = localDirectRooms.value.filter(e => e.local == IDBoolean.false).map(e => e.roomName);
      //const existNames = localDirectRooms.value.map(e => e.roomName);

      return userContact.map((list) => {

        return list.data.result.filter((e) => {
          return !existNames.includes(e.wxFullName) && e.wxUserId != SessionStore.user.UserId
        })
      })
    } else if (userContact.isErr()) {
      return userContact
    }  else if (localDirectRooms.isErr()) {
      return localDirectRooms
    }
  }
}
