import { MessageTable } from "src/app/infra/database/dexie/instance/chat/schema/message";

export function messageListDetermineChanges(serverList: MessageTable[], localList: MessageTable[]) {

  localList = localList.filter(e => e.id)
  // Convert lists to dictionaries for easier comparison
  const localDict = localList.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const serverDict = serverList.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  // Identify added and deleted items
  const addedItems = serverList.filter(item => !localDict[item.id]);
  const deletedItems = localList.filter(item => !serverDict[item.id]);

  // Identify changed items
  const changedItems = serverList.filter(item => {
    const localItem = localDict[item.id];

    if(localItem?.$id) {
      item.$id = localItem.$id
    }

    return localItem && (item.editedAt !== localItem.editedAt || item.isDeleted !== localItem.isDeleted || item.sentAt != item.sentAt  || item.reactions.some((r, index) => {
      const localReaction = localItem.reactions[index];
      return !localReaction || r.reactedAt !== localReaction.reactedAt;
    }));
  });

  return {
    addedItems,
    deletedItems,
    changedItems
  }
}
