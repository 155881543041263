import { RoomByIdOutputDTO } from "src/app/core/chat/usecase/room/room-get-by-id-use-case.service";
import { RoomEntity } from "../entity/group";

export class GetRoomByIdMapper {
  static toDomain(input: RoomByIdOutputDTO): RoomEntity {

    return new RoomEntity({
      createdAt: input.data.createdAt,
      createdBy: input.data.createdBy,
      expirationDate: input.data.expirationDate,
      id: input.data.id,
      members: input.data.members,
      roomName: input.data.roomName,
      roomType: input.data.roomType,
    })
  }
}
