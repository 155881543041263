
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class WebSocketGraylogService {
  private adminSocketGlobal!: WebSocket;
  connected = false

  constructor() { }

  connect(): void {
    // this.adminSocketGlobal = new WebSocket('wss://5-180-182-151.cloud-xip.com:85/ws/');
    // this.adminSocketGlobal = new WebSocket('wss://185-229-224-75.cloud-xip.com:85/ws/');

    // this.adminSocketGlobal.onopen = () => {
    //   console.log('Admin WebSocket is open now.');
    //   this.adminSocketGlobal.send("uuid");
    //   this.connected = true
    // };

    // this.adminSocketGlobal.onmessage = async (event: MessageEvent) => {
    //   // Handle incoming messages here
    // };

    // this.adminSocketGlobal.onclose = () => {
    //   // console.log('Admin WebSocket is closed now.');
    //   this.connect();
    // };

    // this.adminSocketGlobal.onerror = (error: Event) => {
    //   // console.error('Admin WebSocket error:', error);
    // };
  }

  send(data: Object & { type: string; payload: Object , requestId: string, spanContext:any }): void {
    if (this.adminSocketGlobal.readyState === WebSocket.OPEN) {

      this.adminSocketGlobal.send(JSON.stringify({
        type: data.type ,
        payload: data.payload,
        requestId: data.requestId,
        parentSpan: {
          traceId: data?.spanContext?.spanContext().traceId,
          spanId: data?.spanContext?.spanContext().spanId,
        }
      }));

      // data.spanContext.end()
    }
  }

  start(): void {
    this.connect();
  }
}
