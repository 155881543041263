import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { Attachment } from 'src/app/models/attachment.model';
import { EventBody } from 'src/app/models/eventbody.model';
import { EventPerson } from 'src/app/models/eventperson.model';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { Event } from '../../../../models/event.model';
import { AttendeesPageModal } from '../../../../pages/events/attendees/attendees.page';
import { SearchPage } from '../../../../pages/search/search.page';
import { ThemeService } from 'src/app/services/theme.service';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service'
import { environment } from 'src/environments/environment';
import { AgendaDataRepositoryService } from 'src/app/module/agenda/data/repository/agenda-data-repository.service';
import { TableSharedCalendar } from 'src/app/module/agenda/data/data-source/agenda-local-data-source.service';
import { Observable } from 'rxjs';
import { RoleIdService } from 'src/app/services/role-id.service'
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { EventRecurrencePage, EventRecurrenceComponentReturn } from 'src/app/modals/event-recurrence/event-recurrence.page';
import { Utils } from 'src/app/module/agenda/utils';
import { AttendeesLIstChangeDetector } from 'src/app/module/agenda/data/async/change/attendeesLIstChangeDetector';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}


export interface EditEventPageParams {
  eventId: string
  caller?: string
  closeModal: Boolean
}

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.page.html',
  styleUrls: ['./edit-event.page.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class EditEventPage implements OnInit {

  static params: EditEventPageParams

  Form: FormGroup;
  validateFrom = false

  postEvent: Event;
  isRecurring: string;
  isEventEdited: boolean;
  loadedEvent: Event;
  eventBody: EventBody;
  segment: string = "true";
  profile: string;
  eventAttendees: EventPerson[];
  selectedSegment: string;
  selectedDate: Date;
  initCalendarName: string;
  caller: string;
  selectedRecurringType: any;


  public date: any;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public endMinDate = new Date(new Date().getTime() + 15 * 60000).toISOString().slice(0, 10)
  public maxDate: any;
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 15;

  loadedEventAttachments: Attachment[] = [];
  taskParticipants: any = [];
  taskParticipantsCc: any = [];
  adding: "intervenient" | "CC" = "intervenient";

  showAttendees = false;

  public listColors = ['primary', 'accent', 'warn'];
  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  sesseionStora = SessionStore
  environment = environment
  allDayCheck: boolean = false;
  deletedAttachmentsList = [];
  addedAttachmentsList = [];

  sharedCalendar: Observable<TableSharedCalendar[]>
  hasChangeCalendar = false
  selectedUserCalendar:any;
  SessionStore = SessionStore
  serverCurrentList = []

  CalendarNamesOptions = ['Oficial', 'Pessoal']
  editAllEvent = false
  closeModal = false

  daysOfWeek = {
    sun: false,
    mon: false,
    tues: false,
    wed: false,
    thurs: false,
    fri: false,
    sat: false,
  };

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public alertController: AlertController,
    private attachmentsService: AttachmentsService,
    public ThemeService: ThemeService,
    private httpErrorHandle: HttpErrorHandle,
    private agendaDataRepository: AgendaDataRepositoryService,
    private utils: Utils,
    public RoleIdService: RoleIdService,
  ) {

    /* this.postEvent = new Event(); */
    this.isEventEdited = false;
    /* this.postEvent.EventRecurrence = { Type:'-1', LastOccurrence:''}; */
    this.postEvent = this.navParams.get('event');
    this.serverCurrentList = this.postEvent.Attendees
    console.log('this.postEvent', this.postEvent)

    this.caller = this.navParams.get('caller');
    this.closeModal = this.navParams.get('closeModal');

    this.initCalendarName = this.postEvent.CalendarName;
    this.allDayCheck = this.postEvent.IsAllDayEvent;
    this.validationEditAllEvent()


    if (this.postEvent) {
      if (this.postEvent.Body) {
        if (typeof (this.postEvent?.Body?.Text) == 'string') {
          this.postEvent.Body.Text = this.postEvent.Body.Text.replace(/<[^>]+>/g, '');
        }
      }
    }

    if (this.postEvent.Attendees == null) {
      this.taskParticipants = []
    } else {

      this.postEvent.Attendees.forEach(e => {
        if (e.IsRequired) {
          this.taskParticipants.push(e);
        } else {
          this.taskParticipantsCc.push(e);
        }
      })
    }

    if (this.postEvent.EventRecurrence.frequency == 'never') {
      this.isRecurring = "Não se repete";
    }
    else {
      this.isRecurring = "Repete";
    }

    this.loadedEventAttachments = this.postEvent?.Attachments

    this.agendaDataRepository.getSharedCalendar()

    this.changeAgenda()
    this.sharedCalendar = this.agendaDataRepository.getShareCalendarItemsLiveWithOrder()

  }


  checkForWeekDaysSelect() {

    if(!this.postEvent.EventRecurrence?.daysOfWeek) {
      this.postEvent.EventRecurrence.daysOfWeek = []
    }

    if(this.postEvent.EventRecurrence?.daysOfWeek.includes(0)) {
      this.daysOfWeek.sun = true
    }
    if (this.postEvent.EventRecurrence?.daysOfWeek.includes(1)) {
      this.daysOfWeek.mon = true
    }
    if (this.postEvent.EventRecurrence?.daysOfWeek.includes(2)) {
      this.daysOfWeek.tues = true
    }
    if (this.postEvent.EventRecurrence?.daysOfWeek.includes(3)) {
      this.daysOfWeek.wed = true
    }
    if (this.postEvent.EventRecurrence?.daysOfWeek.includes(4)) {
      this.daysOfWeek.thurs = true
    }
    if (this.postEvent.EventRecurrence?.daysOfWeek.includes(5)) {
      this.daysOfWeek.fri = true
    }
    if (this.postEvent.EventRecurrence?.daysOfWeek.includes(6)) {
      this.daysOfWeek.sat = true
    }
  }



  hasPrCalendar(data: TableSharedCalendar[]) {
    for(const e of data) {
      if(e.roleId == this.RoleIdService.PRES) {
        return true
      }
    }

    return false
  }
  changeSegmentCalendar() {
    this.hasChangeCalendar = true
  }

  ngOnInit() {
    this.selectedUserCalendar = this.postEvent.owner.wxUserId

    if (!this.postEvent.IsRecurring) {
      this.postEvent.EventRecurrence.frequency = 'never'
    } else {
      this.postEvent.EventRecurrence.frequency = this.utils.recurenceTypeSeleted(this.postEvent.EventRecurrence.frequency)
    }
    this.postEvent.Category = this.setEventType(this.postEvent.Category)

    window.onresize = (event) => {
      // if not mobile remove all component
      if (window.innerWidth >= 1024) {
        this.modalController.dismiss();
      }
    }
    if (window.innerWidth > 800) {
      this.showAttendees = true;
    }

    setTimeout(() => {
      this.selectedRecurringType = this.postEvent.EventRecurrence.Type.toString();
    }, 500);

    this.checkForWeekDaysSelect()

  }

  ngOnDestroy() {
    clearInterval(this.myInterval)
  }

  myInterval = setInterval(() => {
    document.querySelectorAll('.ngx-mat-timepicker input').forEach((e: any) => {
      if (e) {
        e.disabled = true;
      }
    })
  }, 1000);

  ngOnChanges(changes: any): void {
    this.loadedEventAttachments = this.postEvent?.Attachments
  }

  close() {
    this.modalController.dismiss();
  }

  cancel() {
    this.modalController.dismiss({ action: 'cancel' });
  }


  roundTimeQuarterHour(timeToReturn = new Date()) {
    let date = timeToReturn || new Date();
    const minutes = date.getMinutes();
    date.setSeconds(0);

    if (minutes % 15 != 0) {

      if (minutes > 45) {
        date.setMinutes(60)
      } else if (minutes > 30) {
        date.setMinutes(45)
      } else if (minutes > 15) {
        date.setMinutes(30)
      } else if (minutes > 0) {
        date.setMinutes(15)
      }

    }

    return date
  }

  roundTimeQuarterHourPlus15(date: Date) {
    const _date = new Date(date);
    const minutes = _date.getMinutes();
    _date.setMinutes(minutes + 15)
    return _date
  }

  calculetedLastOccurrence(type: number) {
    // console.log(type);
    var valor;
    var opcao: boolean;
    if (type == 0) {
      valor = 7;
      opcao = true;
    } else if (type == 1) {
      valor = 30;
      opcao = true;
    } else if (type == 2) {
      valor = 1;
      opcao = false;
    } else if (type == 3) {
      valor = 5;
      opcao = false;
    }
    this.defineLastOccurrence(valor, opcao);
  }


  defineLastOccurrence(valor: number, opcao: boolean) {
    var time = new Date(this.postEvent.EndDate);
    if (opcao == true) {
      time.setDate(time.getDate() + valor);
      this.postEvent.EventRecurrence.LastOccurrence = time;
    } else {
      time = new Date(
        time.getFullYear() + valor,
        time.getMonth(),
        time.getDate(),
        time.getHours(),
        time.getMinutes()
      );
      this.postEvent.EventRecurrence.LastOccurrence = time;
    }

  }


  runValidation() {
    this.validateFrom = true
  }

  injectValidation() {

    this.Form = new FormGroup({
      Subject: new FormControl(this.postEvent.Subject, [
        Validators.required,
        // Validators.minLength(4)
      ]),
      Location: new FormControl(this.postEvent.Location, [
        Validators.required,
      ]),
      CalendarName: new FormControl(this.postEvent.CalendarName, [
        Validators.required
      ]),
      Categories: new FormControl(this.postEvent.Category, [
        Validators.required
      ]),
      IsRecurring: new FormControl(this.postEvent.IsRecurring, [
        Validators.required
      ]),
      dateOccurrence: new FormControl(this.postEvent.EventRecurrence.frequency != 'never' ? this.postEvent.EventRecurrence.until : true, [
        Validators.required
      ]),
      Date: new FormControl(new Date(this.postEvent.StartDate).getTime() <= new Date(this.postEvent.EndDate).getTime() ? 'ok' : null, [
        Validators.required
      ]),

      participantes: new FormControl(this.taskParticipants, [
        Validators.required
      ]),

    })
  }

  openInicio() {
    let input: any = document.querySelector('#new-inicio')
    if (input) {

      input.click()
    }
  }

  openFim() {
    let input: any = document.querySelector('#new-fim')
    if (input) {
      input.click()
    }
  }

  openLastOccurrence() {
    let input: any = document.querySelector('#last-occurrence')
    if (input) {
      input.click()
    }
  }

  async validationEditAllEvent() {
    if (this.postEvent.IsRecurring) {

      const modal = await this.modalController.create({
        component: EventRecurrencePage,
        componentProps: {},
        cssClass: 'event-recurrence-modal',

      });

      modal.onDidDismiss().then((res) => {

        const data: EventRecurrenceComponentReturn = res.data
        if(data =='EditAll') {
          this.editAllEvent = true
        } else if (data == 'EditOne') {
          this.editAllEvent = false
        } else if(data == 'Cancel') {
          this.cancel()
        } else {
          this.cancel()
        }
      });

      await modal.present();
    }
  }


  onDateChange(e) {

    const cloneDateStartDate = new Date(this.postEvent.StartDate);
    const cloneDateEndDate = new Date(this.postEvent.EndDate);

    if(cloneDateStartDate.getTime() >= cloneDateEndDate.getTime()) {
      cloneDateStartDate.setHours(cloneDateStartDate.getHours() + 1);
      this.postEvent.EndDate = cloneDateStartDate
    }

  }

  @XTracerAsync({name:'mobile/create-event', bugPrint: true, daley: 4000})
  async save_v2(tracing?: TracingType) {
    this.injectValidation()
    this.runValidation()


    let i =0;
    this.postEvent.EventRecurrence.daysOfWeek = []
    for(const [key, value] of Object.entries(this.daysOfWeek)) {
      if(value) {
        if(!this.postEvent.EventRecurrence.daysOfWeek.includes(i)) {
          this.postEvent.EventRecurrence.daysOfWeek.push(i);
        }
      }
      i++
    }



    if (this.Form.invalid) {
      return false
    }
    this.postEvent.Attendees = this.taskParticipants.concat(this.taskParticipantsCc);
    try {

      const calendar = await this.agendaDataRepository.getCalendarByUserId(this.selectedUserCalendar)

      if(calendar.isOk()) {
        const _value =  await this.agendaDataRepository.updateEvent(this.postEvent.EventId, this.postEvent, this.editAllEvent, calendar.value, tracing)//.subscribe((value) => {



        // _value.then((value) => {
        //   if(value.isOk()) {
        //     console.log(value.value)
        //     this.close();
        //     this.httpErrorHandle.httpsSucessMessagge('Editar evento')
        //     tracing.setAttribute('outcome', 'success')
        //    } else {

        //     tracing.setAttribute('outcome', 'failed')
        //     console.log('edit event error: ', value.error)
        //    }
        // })

        console.log({serverCurrentList: this.serverCurrentList, Attendees: this.postEvent.Attendees})

        const { insert, remove } = AttendeesLIstChangeDetector(this.serverCurrentList as any, this.postEvent.Attendees as any)

        console.log({insert, remove })
        if(insert.length >= 1) {

          try {
            await this.agendaDataRepository.addEventAttendee(this.postEvent.EventId, insert, tracing).toPromise()
          } catch (error) {
            tracing.setAttribute('failed.attendees', 'true')
            console.log('add Attendee error: ', error)
          }

          // this.agendaDataRepository.addEventAttendee(this.postEvent.EventId, insert, tracing).subscribe((value) => {
          //   console.log(value)
          // }, ((error) => {
          //   tracing.setAttribute('failed.attendees', 'true')
          //   console.log('add Attendee error: ', error)
          // }));

        }

        if(remove.length >= 1) {
          try {
            await this.agendaDataRepository.removeEventAttendee(this.postEvent.EventId, remove).toPromise();
          } catch (error) {
            tracing.setAttribute('failed.attendees', 'true')
            console.log('add Attendee error: ', error)
          }

          // this.agendaDataRepository.removeEventAttendee(this.postEvent.EventId, remove).subscribe((value) => {
          //   console.log(value)
          // }, ((error) => {
          //   tracing.setAttribute('failed.attendees', 'true')
          //   console.log('add Attendee error: ', error)
          // }));

        }


        if (this.addedAttachmentsList.length > 0) {
          try {
            await this.agendaDataRepository.addEventAttachment(this.postEvent.EventId, this.loadedEventAttachments, tracing).toPromise();
          } catch (error) {
            console.log('add attachment error: ', error)
          }

          // this.agendaDataRepository.addEventAttachment(this.postEvent.EventId, this.loadedEventAttachments, tracing).subscribe((value) => {
          //   console.log(value)
          // }, ((error) => {
          //   console.log('add attachment error: ', error)
          // }));
        }

        if (this.deletedAttachmentsList.length > 0) {
          try {
            await this.agendaDataRepository.removeEventAttachment(this.postEvent.EventId, { attachments: this.deletedAttachmentsList }).toPromise()
          } catch (error) {
            console.log('remove attachment error: ', error)
            tracing.setAttribute('failed.remove.attachment', 'true')
          }

          // this.agendaDataRepository.removeEventAttachment(this.postEvent.EventId, { attachments: this.deletedAttachmentsList }).subscribe((value) => {
          //   console.log(value)
          // }, ((error) => {
          //   console.log('remove attachment error: ', error)
          //   tracing.setAttribute('failed.remove.attachment', 'true')
          // }));
        }

        if(_value.isOk()) {
          console.log(_value.value)
          this.close();
          this.httpErrorHandle.httpsSucessMessagge('Editar evento')
          tracing.setAttribute('outcome', 'success')
        } else {
          tracing.setAttribute('outcome', 'failed')
          console.log('edit event error: ', _value.error)
        }


        this.isEventEdited = true;
      } else {
        console.log(calendar.error)
      }



    } catch (error) {
      this.httpErrorHandle.httpStatusHandle(error)
      console.log('edit: ', error)
    }

  }



  async openAttendees() {
    if (window.innerWidth > 801) {
      this.showAttendees = true;
    }
    else {
      const modal = await this.modalController.create({
        component: AttendeesPageModal,
        componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc
        },
        cssClass: 'modal attendee modal-desktop',
        backdropDismiss: false
      });

      await modal.present();

      modal.onDidDismiss().then((data) => {

        if (data) {
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          if (newAttendees.length) {
            this.setIntervenient(newAttendees);
          } else {
            this.setIntervenient([]);
          }
          if (newAttendeesCC) {
            this.setIntervenientCC(newAttendeesCC);
          } else {
            this.setIntervenientCC([]);
          }

        }
      });
    }


  }

  setIntervenient(data) {
    this.taskParticipants = data;
    this.postEvent.Attendees = data;
  }

  setIntervenientCC(data) {
    this.taskParticipantsCc = data;
  }

  addParticipants() {
    this.adding = 'intervenient'
    this.openAttendees();
  }

  addParticipantsCC() {
    this.adding = 'CC';
    this.openAttendees();
  }

  dynamicSetIntervenient({ taskParticipants, taskParticipantsCc }) {
    this.taskParticipants = taskParticipants;
    this.taskParticipantsCc = taskParticipantsCc;
  }


  getAttachments(eventId: string) {

    if (this.postEvent.HasAttachments) {
      this.attachmentsService.getAttachmentsById(eventId).subscribe(res => {
        // this.loadedEventAttachments = res;

      }, ((erro) => {
        console.error('editgetAttchament', erro)
      }));
    }


  }

  deleteAttachment(attachmentID: string, index) {
    const indexToRemove = index; // Assuming you already know the index you want to remove

    const DocumentId: any = this.loadedEventAttachments[index].Id
    if (indexToRemove > -1 && indexToRemove < this.loadedEventAttachments.length) {
      this.loadedEventAttachments.splice(indexToRemove, 1);
    }

    this.deletedAttachmentsList.push(DocumentId)

  }

  removeItemById(array, id) {
    return array.filter(item => item.docId !== id);
  }

  async getDoc() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        showSearchInput: true,
        eventAgenda: true,
        select: true,
      }
    });

    modal.onDidDismiss().then(async (res) => {
      if (res) {
        const data = res.data;

        /* const ApplicationIdDocumentToSave: any = {
          SourceName: data.selected.Assunto,
          ParentId: this.postEvent.EventId,
          SourceId: data.selected.Id,
          Stakeholders: false,
          ApplicationId: data.selected.ApplicationType.toString(),
          CreateDate: false,
          // needed to attach this document
          Id: 'add',
          SourceTitle: data.selected.Assunto,
          Source: '1',
          Link: '',
          SerialNumber: '',
        } */


        this.loadedEventAttachments.push(data.selected)
        this.addedAttachmentsList.push(data.selected)

      }
    })

    await modal.present();
  }


  async changeAgenda() {

    const result = await this.agendaDataRepository.geCalendars()

    const selectedCalendar = result.find(e => e.wxUserId == this.selectedUserCalendar)

    if(selectedCalendar) {
      if(selectedCalendar.shareType == 1) {
        this.CalendarNamesOptions = ['Oficial']
      } else if(selectedCalendar.shareType == 2) {
        this.CalendarNamesOptions = ['Pessoal']
      } else if (selectedCalendar.shareType == 3) {
        this.CalendarNamesOptions = ['Oficial', 'Pessoal']
      }
    }

  }

  onCheckboxChange(event: any) {

    if (this.allDayCheck) {
      this.postEvent.IsAllDayEvent = this.allDayCheck;
      this.postEvent.StartDate = this.setAlldayTime(this.postEvent.StartDate)
      this.postEvent.EndDate = this.setAlldayTimeEndDate(this.postEvent.EndDate)

      console.log('Recurso ativado!!');
    } else {
      this.postEvent.IsAllDayEvent = this.allDayCheck;
      this.postEvent.EndDate = this.setAlldayTimeEndDateNotAlday(this.postEvent.EndDate)
      console.log('Recurso desativado');

    }
  }

  setAlldayTime(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDate(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDateNotAlday(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }

  setEventType(eventType) {
    var selectedEventType = {
      1: 'Meeting',
      2: 'Travel',
      3: 'Conference',
      4: 'Encontro'
    }
    return selectedEventType[eventType];
  }
}
