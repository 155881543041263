import { Injectable } from '@angular/core';
import { IUserRemoteRepository } from '../repository/user-remote-repository';
import { XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { SessionStore } from 'src/app/store/session.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class UserLogOutUseCaseService {

  constructor(
    private userRemoteRepository: IUserRemoteRepository,
    private router: Router,
    private notificationService: NotificationsService,
  ) { }

  @XTracerAsync({name:'UserLogOutUseCaseService', module:'user',  bugPrint: true})
  async execute() {
    let logoutOut = false

    let subscription = this.router.events.subscribe((val) => {
      logoutOut = true
    });

    const result =  await this.userRemoteRepository.logout()

    SessionStore.setInativity(false)
    SessionStore.setUrlBeforeInactivity(this.router.url);
    logoutOut == false

    if (environment.production) {
      window.location.pathname = '/auth'
      this.notificationService.DeletePostToken()
      subscription.unsubscribe()
    } else {
      const pathBeforeGoOut = window.location.pathname
      this.router.navigateByUrl('/auth', { replaceUrl: true });
      this.notificationService.DeletePostToken()

      setTimeout(() => {
        if (logoutOut == false || pathBeforeGoOut == window.location.pathname) {
          window.location.pathname = '/auth'
          this.notificationService.DeletePostToken()
        } else {

        }
        subscription.unsubscribe()
      }, 500)
    }

  }
}
