import { Injectable } from '@angular/core';
import { MessageLocalDataSourceService } from '../../../../module/chat/data/repository/message/message-local-data-source.service';
import { MessageEntitySchema } from 'src/app/core/chat/entity/message';
import { z } from 'zod';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { IMessageLocalRepository } from '../../repository/message/message-local-repository';

const SocketMessageDeleteOutputSchema = MessageEntitySchema.pick({
  id: true,
  attachments: true,
  canEdit: true,
  editedAt: true,
  info: true,
  isDeleted: true,
  message: true,
  messageType: true,
  oneShot: true,
  reactions: true,
  receiverId: true,
  requireUnlock: true,
  roomId: true,
  sender: true,
  sending: true,
  sentAt: true,
})


export type ISocketMessageDeleteOutput = z.infer<typeof SocketMessageDeleteOutputSchema>

@Injectable({
  providedIn: 'root'
})
export class SocketMessageDeleteUseCaseService {

  constructor(
    private messageLocalDataSourceService: IMessageLocalRepository
  ) { }


  @XTracerAsync({name:'Socket-Message-Delete-UseCase', module:'chat',  bugPrint: true})
  async execute(input: ISocketMessageDeleteOutput, tracing?: TracingType) {
    const result = await this.messageLocalDataSourceService.findOne({id: input.id})

    if(result.isOk() && result.value) {

      tracing?.addEvent("Message found")
      const updateResult = await this.messageLocalDataSourceService.update(result.value.$id, { isDeleted: true })
      return updateResult
    }else {
      tracing.hasError("failed to delete message")
    }
  }
}
