import { Component, OnInit } from '@angular/core';
import { ProcessesService } from 'src/app/services/processes.service';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from '../../../../models/event.model';
import { ModalController, PopoverController } from '@ionic/angular';
import { ExpedientTaskModalPage } from '../expedient-task-modal/expedient-task-modal.page';
import { BookMeetingModalPage } from '../book-meeting-modal/book-meeting-modal.page';
import { ViewEventPage } from 'src/app/ui/agenda/modal/view-event/view-event.page';
import { momentG } from 'src/plugin/momentG'
import { DiscartExpedientModalPage } from '../../discart-expedient-modal/discart-expedient-modal.page';
import { ToastService } from 'src/app/services/toast.service';
import { SearchPage } from 'src/app/pages/search/search.page';
import { SearchList } from 'src/app/models/search-document';
import { AddNotePage } from 'src/app/modals/add-note/add-note.page';
import { SearchDocumentPipe } from 'src/app/pipes/search-document.pipe';
import { ExpedienteService } from 'src/app/Rules/expediente.service';
import { expedienteTask } from 'src/app/models/dailyworktask.model';
import { PermissionService } from 'src/app/services/permission.service';
import { ViewDocumentPage } from 'src/app/modals/view-document/view-document.page';
import { ThemeService } from 'src/app/services/theme.service'
import { EventTrigger } from 'src/app/services/eventTrigger.service';
import { Platform } from '@ionic/angular';
import { BackgroundService } from 'src/app/services/background.service';
import { NewGroupPage } from 'src/app/ui/chat/modal/new-group/new-group.page';
import { DataService } from 'src/app/services/data.service';
import { RouteService } from 'src/app/services/route.service';
import { Storage } from '@ionic/storage';
import { UserSession } from 'src/app/models/user.model';
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { TaskService } from 'src/app/services/task.service';
import { OptsExpedientePage } from 'src/app/shared/popover/opts-expediente/opts-expediente.page';


@Component({
  selector: 'app-expediente-detail',
  templateUrl: './expediente-detail.page.html',
  styleUrls: ['./expediente-detail.page.scss'],
})
export class ExpedienteDetailPage implements OnInit {

  months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  days = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];

  customDate: any;

  task: expedienteTask;
  fulltask: any;
  eventsList: Event[];
  serialNumber: string;
  caller: string;
  profile: string;
  intervenientes: any = []
  cc: any = [];
  documents: SearchList[] = [];
  attachments: any;

  hideSendToPendentes = true
  searchDocumentPipe = new SearchDocumentPipe()

  onlinecheck: boolean;

  loggeduser: UserSession;

  showOptions = false
  constructor(
    private processes: ProcessesService,
    private router: Router,
    private modalController: ModalController,
    public popoverController: PopoverController,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private RouteService: RouteService,
    private attachmentsService: AttachmentsService,
    public p: PermissionService,
    private expedienteService: ExpedienteService,
    public platform: Platform,
    private backgroundservice: BackgroundService,
    public ThemeService: ThemeService,
    private dataService: DataService,
    private httpErroHandle: HttpErrorHandle,
    private eventTriger: EventTrigger,
    public TaskService: TaskService
  ) {
    this.loggeduser = SessionStore.user
    // console.log('SessionStore', SessionStore)

    this.activatedRoute.paramMap.subscribe(params => {
      if (params["params"].SerialNumber) {
        this.serialNumber = params["params"].SerialNumber;
      }
      if (params["params"].caller) {
        this.caller = params["params"].caller;
      }
    });

    window['attachments-expediente-update'] = () => {
      this.LoadTaskDetail(this.serialNumber);
    }
  }

  ngOnInit() {
    this.LoadTaskDetail(this.serialNumber);
    this.backgroundservice.registerBackService('Online', () => {
      this.LoadTaskDetail(this.serialNumber);
    });

  }

  close() {
    this.modalController.dismiss();
  }

  showToast() {
    this.toastService.presentToast('Não foi possível fazer login');
  }

  async approve(note: string, documents: any) {
    let body = {
      "serialNumber": this.serialNumber,
      "action": "Aprovar",
      "ActionTypeId": 100000004,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }



    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise();
      this.close();
      this.httpErroHandle.httpsSucessMessagge('Avaliação Superiror');
      this.TaskService.loadExpedientes()
    } catch (error) {
      this.httpErroHandle.httpStatusHandle(error)
    } finally {
      loader.remove()
    }

  }

  async sendToReview(note: string, documents: any) {
    let body = {
      "serialNumber": this.serialNumber,
      "action": "Retificar",
      "ActionTypeId": 99999877,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise()
      this.close();
      this.httpErroHandle.httpsSucessMessagge('Rever')
      this.TaskService.loadExpedientes()
    } catch (error) {
      this.httpErroHandle.httpStatusHandle(error)
    } finally {
      loader.remove()
    }
  }


  async openAddNoteModal(actionName: string) {
    let classs;
    if (window.innerWidth < 701) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'add-note-modal'
    }
    const modal = await this.modalController.create({
      component: AddNotePage,
      componentProps: {
        showAttachmentBtn: false,
        actionName: actionName
      },
      cssClass: classs,
      //backdropDismiss: true
    });



    modal.onDidDismiss().then(res => {

      if (res.data) {
        const DocumentToSave = res.data.documents.map((e) => {
          return {
            ApplicationId: e.ApplicationType,
            SourceId: e.Id,
          }
        });

        let docs = {
          ProcessInstanceID: "",
          Attachments: DocumentToSave,
        }

        if (actionName == 'Aprovar') {
          this.approve(res.data.note, docs);
          this.subscribeEventTrigger('update')

        }
        else if (actionName == 'Revisão') {
          this.sendToReview(res.data.note, docs);
        }
        this.goBack();
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  subscribeEventTrigger(value) {
    this.eventTriger.publishSomeData({
      expedienteDetail: value
    })
  }

  goBack() {
    this.TaskService.loadExpedientes()
    this.RouteService.goBack();
  }

  sendExpedienteToPending() {
    const loader = this.toastService.loading()
    this.processes.SetTaskToPending(this.serialNumber).subscribe(res => {

      this.httpErroHandle.httpsSucessMessagge('Enviar para Pendentes')
      this.TaskService.loadExpedientes()
      this.goBack();
      loader.remove()
    },
      (error) => {
        loader.remove()
        this.httpErroHandle.httpStatusHandle(error)
      });
  }

  async LoadTaskDetail(serial: string) {

    this.processes.GetTask(serial).subscribe(res => {

      console.log('LoadTaskDetail')

      this.TaskService.loadExpedientes();

      console.log("res========", res)

      this.task = {
        "SerialNumber": res.serialNumber,
        "Folio": res.workflowInstanceDataFields.Subject,
        "Senders": res.originator.email,
        "CreateDate": momentG(new Date(res.taskStartDate), 'yyyy-MM-dd HH:mm:ss'),
        "DocumentURL": res.workflowInstanceDataFields.ViewerRequest,
        "Remetente": res.workflowInstanceDataFields.Sender,
        "Note": res.workflowInstanceDataFields.TaskMessage || res.workflowInstanceDataFields.Note,
        "FolderId": res.workflowInstanceDataFields.FolderID,
        "FsId": res.workflowInstanceDataFields.FsId || res.workflowInstanceDataFields.ApplicationID,
        "DocId": res.workflowInstanceDataFields.DocID,
        "WorkflowName": res.workflowDisplayName,
        "Status": res.workflowInstanceDataFields.Status,
        "DispatchNumber": res.workflowInstanceDataFields.DispatchNumber,
        "AttachmentsProcessLastInstanceID": res.workflowInstanceDataFields.AttachmentsProcessLastInstanceID,
        "InstanceID": res.workflowInstanceDataFields.InstanceID,
        "DraftIds": res.workflowInstanceDataFields?.DraftIds,
        "activityInstanceName": res.activityInstanceName,
      }

      this.fulltask = res;
      // this.updateProcessDB(res)

      let thedate = new Date(this.task.CreateDate);
      this.customDate = this.days[thedate.getDay()] + ", " + thedate.getDate() + " de " + (this.months[thedate.getMonth()]);


      this.processes.GetTaskParticipants(this.task.FolderId).subscribe(users => {
        this.intervenientes = users.filter(user => {
          return user.Type == 'I';
        });
        this.cc = users.filter(user => {
          return user.Type == 'CC';
        });
      })


    }, (error) => {

      if (error.status == 0) {
        // this.getFromDB();
      } else {
        try {
          this.httpErroHandle.httpStatusHandle(error)
          this.goBack()
        } catch (e) {
          window.history.back();
        }
        this.httpErroHandle.httpStatusHandle(error)
      }
    });



  }

  async LoadRelatedEvents(serial: string) {

  }

  async viewDocument(DocId: string, Document) {

    // this.expedienteService.viewDocument({ApplicationId:'361', DocId})

    const modal = await this.modalController.create({
      component: ViewDocumentPage,
      componentProps: {
        trustedUrl: '',
        file: {
          title: Document.Assunto,
          url: '',
          title_link: '',
        },
        Document,
        applicationId: Document.ApplicationId,
        docId: Document.DocId || Document.SourceId,
        folderId: this.task.FolderId,
        task: this.fulltask
      },
      cssClass: 'modal modal-desktop'
    });
    await modal.present();

  }

  // viewDocument(DocId:string) {

  //   this.expedienteService.viewDocument({ApplicationId:'361', DocId})
  // }

  getAttachments(serialNumber) {


    this.attachmentsService.getAttachmentsBySerial(serialNumber).subscribe(res => {
      this.attachments = res;

    });
  }

  attachDocument() {
    this.getDoc();
  }

  async getDoc() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'Correspondencia',
        showSearchInput: true,
        select: true,
        findOnly: [8]
      }
    });

    modal.onDidDismiss().then(async (res) => {
      if (res) {
        console.log('attachment to add ==', res)
        const data = res.data;
        this.documents.push(data.selected);
        let body
        await this.documents.forEach(async (element: any) => {

          body = {
            "InstanceID": this.task.InstanceID,
            "WorkflowDisplayName": this.task.WorkflowName,
            "FolderID": this.task.FolderId,
            "DispatchNumber": this.task.DispatchNumber,
            "AttachmentsProcessLastInstanceID": this.task.AttachmentsProcessLastInstanceID,
            "Attachments": []
          }

          const Attachments = this.searchDocumentPipe.transformToAttachment(element)
          body.Attachments = Attachments;
        });

        const loader = this.toastService.loading()

        try {
          loader.remove()
          await this.attachmentsService.AddAttachment(body).toPromise()
          console.log('attachment post')
          setTimeout(() => {
            this.LoadTaskDetail(this.serialNumber);
          }, 1000);

          setTimeout(() => {
            this.LoadTaskDetail(this.serialNumber);
          }, 4000);

          this.toastService._successMessage()
        } catch (error) {
          if (error.status == 0) {
            this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
          } else {

            this.toastService._badRequest()
          }
        }
      }

    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async openExpedientActionsModal(taskAction: any, task: any) {
    let classs;
    if (window.innerWidth <= 800) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: ExpedientTaskModalPage,
      componentProps: {
        taskAction: taskAction,
        task: task,
        profile: this.profile,
      },
      cssClass: classs,
    });

    modal.onDidDismiss().then(async (res) => {
      let body = res['data'];
      if (res['data']) {
        const loader = this.toastService.loading()
        try {
          await this.processes.CompleteTask(body).toPromise();
          this.goBack();
        } catch (error) {
          this.httpErroHandle.httpStatusHandle(error)
        } finally {
          loader.remove()
        }
      }
      else {
        this.close();
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async distartExpedientModal(body: any) {
    const modal = await this.modalController.create({
      component: DiscartExpedientModalPage,
      componentProps: {
      },
      cssClass: 'discart-expedient-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then(async (res) => {


      if (body == 'descartar') {

        this.TaskService.loadExpedientes()
        if (res['data'] == 'Yes') {

          const loader = this.toastService.loading()

          try {
            await this.expedienteService.discard({ SerialNumber: this.task.SerialNumber }).toPromise()
            this.toastService._successMessage('Processo descartado');
            this.goBack();
          } catch (error) {
            if (error.status == 0) {
              this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
            } else {

              this.toastService._badRequest('Processo não descartado')
            }
          } finally {
            loader.remove()
          }

        }
        else if (res['data'] == 'No') {
          //Do nothing
        }
      }
      else {
        if (res['data'] == 'Yes') {

          const loader = this.toastService.loading()
          try {
            await this.expedienteService.CompleteTask(body).toPromise();
            //this.toastService._successMessage('Processo descartado');
            this.goBack();
          } catch (error) {
            if (error.status == 0) {
              this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
            } else {

              this.toastService._badRequest('Processo não descartado')
            }
          } finally {
            loader.remove()
          }

        }
        else if (res['data'] == 'No') {

          const loader = this.toastService.loading()
          try {
            await this.processes.UpdateTaskStatus(this.task.FolderId).toPromise();
            this.toastService._successMessage();
            this.goBack();
          } catch (error) {
            if (error.status == 0) {
              this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
            } else {

              this.toastService._badRequest()
            }
          } finally {
            loader.remove()
          }
        }
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async openBookMeetingModal(task: any) {


    let classs;
    if (window.innerWidth < 701) {
      classs = 'book-meeting-modal modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: BookMeetingModalPage,
      componentProps: {
        task: this.task,
        fulltask: this.fulltask
      },
      cssClass: classs,
      backdropDismiss: false
    });

    modal.onDidDismiss().then(res => {

    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  goToEvent(eventId: any) {
    this.router.navigate(['/home/events', eventId, 'expediente']);
  }

  async viewEventDetail(eventId: any) {


    const modal = await this.modalController.create({
      component: ViewEventPage,
      componentProps: {
        eventId: eventId,
        profile: this.profile,
      },
      cssClass: 'modal modal-desktop',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((res) => {
      if (res) {
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  // mobile
  async openOptions(taskAction?: any) {
    if (window.innerWidth > 500) {

      this.showOptions = true
    } else {
      const popover = await this.popoverController.create({
        component: OptsExpedientePage,
        cssClass: 'exp-options',
        componentProps: {
          task: this.task,
          fulltask: this.fulltask,
          taskAction: taskAction,
        },
        translucent: true
      });
      return await popover.present().then(() => {
        this.TaskService.loadExpedientes()

        this.LoadTaskDetail(this.serialNumber);

        setTimeout(()=> {
          this.LoadTaskDetail(this.serialNumber);
        }, 1000)

        setTimeout(()=> {
          this.LoadTaskDetail(this.serialNumber);
        }, 4000)
      }, (error) => {
        console.log(error)
      })

    }

  }

  openNewGroupPage() {
    if (window.innerWidth < 701) {
      this.newGroup();
    }
    else {
      this.dataService.set("newGroup", true);
      this.dataService.set("task", this.task);
      this.dataService.set("newGroupName", this.task.Folio);
      this.dataService.set("documents", this.fulltask.Documents);
      this.dataService.set("link", window.location.pathname);

      console.log('First ,', this.task)
      setTimeout(() => {
        this.router.navigate(['/home/chat']);
      }, 100)
    }
  }

  async newGroup() {
    const modal = await this.modalController.create({
      component: NewGroupPage,
      cssClass: 'modal modal-desktop',
      componentProps: {
        name: this.task.Folio,
        task: this.task
      },
    });

    modal.onDidDismiss().then(
      () => { },
      (error) => {
        console.log(error)
      })

    await modal.present();
  }

}
