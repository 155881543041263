import { Injectable } from '@angular/core';
import { z, ZodError, ZodSchema } from 'zod';
import { IUserRemoteRepository } from '../repository/user-remote-repository';
import { XTracerAsync, TracingType } from 'src/app/services/monitoring/opentelemetry/tracer';
import { zodSafeValidation } from 'src/app/utils/zodValidation';
import { Platform } from '@ionic/angular';
import { AESEncrypt } from 'src/app/services/aesencrypt.service';
import { UserLoginMapper } from '../mapper/user-login';
import { UserSession } from 'src/app/models/user.model';
import { SessionStore } from 'src/app/store/session.service';
import { err, ok, Result } from 'neverthrow';
import { error } from '../../../services/Either/index';

const UserLoginInputSchema = z.object({
  username: z.string(),
  password: z.string()
})

export type UserLoginInput = z.infer<typeof UserLoginInputSchema>



// Define the schema for the main response object
const UserLoginOutputSchema = z.object({
  UserId: z.number(),
  Authorization: z.string(),
  RefreshToken: z.string(),
  Email: z.string().email(),
  FullName: z.string(),
  RoleDescription: z.string(),
  RoleID: z.number(),
  Profile: z.string(), // You can further define the Profile if you have more details
  UserPermissions: z.array(z.number()), // Same as above, you can define more details if needed
  UserPhoto:  z.string().optional()
});



export type UserLoginOutput = z.infer<typeof UserLoginOutputSchema>

export enum LoginError  {
  userNotFound = 401,

}

@Injectable({
  providedIn: 'root'
})
export class UserLoginUseCaseService {

  constructor(
    private userRemoteRepository: IUserRemoteRepository,
    private aesencrypt: AESEncrypt,
    private platform: Platform
  ) { }

  @XTracerAsync({name:'UserLoginUseCaseService', module:'user',  bugPrint: true})
  async execute(input: UserLoginInput, tracing?: TracingType): Promise<Result<UserLoginOutput, LoginError | ZodError<UserLoginInput>>> {
    const validation = zodSafeValidation<UserLoginInput>(UserLoginInputSchema, input)

    if(validation.isOk()) {

      let channelId;
      if ( this.platform.is('desktop') || this.platform.is("mobileweb")) {
        channelId = 2
      } else {
        channelId = 1
      }

      const auth = btoa(input.username + ':' + this.aesencrypt.encrypt(input.password, input.username))

      const result =  await this.userRemoteRepository.login({
        Auth: auth,
        ChannelId: channelId
      })

      if(result.isOk() && result.value.data.data) {

        const data = UserLoginMapper.toDomainData(result.value.data);
        const session: UserSession = Object.assign(SessionStore.user, data);
        SessionStore.reset(session);

        return ok(data)

      } else if (result.isOk() && !result.value.data.data) {
        return err(LoginError.userNotFound)
      }

      if(result.isErr() && result.error.status) {
        return err(result.error.status as LoginError)
      }

    } else {
      tracing.setAttribute('parameter error','true')
      // Logger.error('failed to send message doe to invalid attachment', {
      //   zodErrorList: validation.error.errors,
      //   data: data
      // })

      return validation
    }

  }
}
