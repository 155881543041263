import { Injectable } from '@angular/core';
import { MessageSocketRepositoryService } from 'src/app/module/chat/data/repository/message/message-live-signalr-data-source.service'
import { InstanceId } from '../../../../module/chat/domain/chat-service.service';
import { filter, map } from 'rxjs/operators';
import { z } from 'zod';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';

export const ListenSendMessageInputDTOSchema = z.object({
  roomId: z.string(),
});
export type ListenSendMessageInputDTO = z.infer<typeof ListenSendMessageInputDTOSchema>



@Injectable({
  providedIn: 'root'
})
export class ListenSendMessageUseCase {

  constructor(
    private MessageSocketRepositoryService: IMessageSocketRepository
  ) { }

  execute({roomId}: {roomId: string}) {

    return this.MessageSocketRepositoryService.listenToMessages().pipe(
      map(message => message.data),
      filter((message) => {

        return message?.requestId?.startsWith(InstanceId) && message?.roomId == roomId
      }),
      map(message => message)
    )

  }
}
