import { Injectable } from '@angular/core';
import { IMessageLocalRepository } from 'src/app/core/chat/repository/message/message-local-repository';
import { z } from 'zod';

const messageLocalGetByIdServiceInputSchema = z.object({
  roomId: z.string().uuid().optional(),
  receiverId: z.string().optional(),
})

export type IMessageLocalGetByIdServiceInput = z.infer<typeof messageLocalGetByIdServiceInputSchema>

@Injectable({
  providedIn: 'root'
})
export class MessageLocalGetByIdService {

  constructor(
    private messageLocalRepository: IMessageLocalRepository
  ) { }

  execute(input: IMessageLocalGetByIdServiceInput) {
    if(input.roomId && input.receiverId) {

      return this.messageLocalRepository.getDirectMessages({
        roomId: input.roomId,
        receiverId: input.receiverId
      })
    }
    else if(input.roomId) {
      return this.messageLocalRepository.find({roomId: input.roomId})
    } else if (input.receiverId) {
      return this.messageLocalRepository.find({receiverId: parseInt(input.receiverId)})
    }

  }
}
