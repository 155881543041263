import { Injectable } from '@angular/core';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { IMemberSocketRepository } from 'src/app/core/chat/repository/member/member-socket-repository';
import { InstanceId } from '../../../domain/chat-service.service';
import { v4 as uuidv4 } from 'uuid'
import { filter, map, tap } from 'rxjs/operators';
import { SocketMessage } from 'src/app/infra/socket/signalR/signalR';
import { RemoveRoomMemberInput, IRemoveRoomMemberOutput } from 'src/app/core/chat/usecase/member/member-remove-socket-use-case.service';

@Injectable({
  providedIn: 'root'
})
export class MemberSocketRepositoryService implements IMemberSocketRepository {

  constructor(
    private socket: SignalRService
  ) {
    // this.listenToRemoveMember()
  }
  async removeMember(data: RemoveRoomMemberInput) {

    const result =  await this.socket.sendData<any>({
      method: 'RemoveRoomMember',
      data:  {
        ...data,
        requestId: InstanceId +'@'+ uuidv4()
      },
    })

    return result;
  }

  listenToRemoveMember() {
    return this.socket.getData().pipe(
      filter((e) : e is SocketMessage<IRemoveRoomMemberOutput>=> e?.method == 'GroupDeletedMembers'),
      map((e)=> e.data),
    )
  }

}
