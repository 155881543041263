import { Injectable } from '@angular/core';
import { z } from 'zod';
import { IUserRemoteRepository } from '../repository/user-remote-repository';
import { SessionStore } from 'src/app/store/session.service';
import { Platform } from '@ionic/angular';
import { XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';


@Injectable({
  providedIn: 'root'
})
export class UserRefreshTokenService {

  constructor(
    private userRemoteRepository: IUserRemoteRepository,
    private platform: Platform
  ) { }

  @XTracerAsync({name:'UserRefreshTokenService', module:'user',  bugPrint: true})
  async execute() {
    let channelId;
    if ( this.platform.is('desktop') || this.platform.is("mobileweb")){
      channelId = 2
    } else {
      channelId = 1
    }

    return await this.userRemoteRepository.refreshToken({
      authorization: SessionStore.user.Authorization,
      refreshToken: SessionStore.user.RefreshToken,
      channelId
    })
  }
}
