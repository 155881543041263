import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';
import { ThemeService } from 'src/app/services/theme.service'
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { ContactRepositoryService } from 'src/app/services/Repositorys/contacts/repository/contacts-repository.service';
import { MemberListLocalRepository } from 'src/app/module/chat/data/repository/member/member-list-local-repository.service'
import { UserContacts } from 'src/app/services/Repositorys/contacts/data-source/contacts-data-source.service';
import { ToastService } from 'src/app/services/toast.service';
import { ChatServiceService } from 'src/app/module/chat/domain/chat-service.service';
import { z, ZodError } from 'zod';

const GroupContactsPageOutPutSchema = z.object({
  roomId: z.string().optional()
})
export type IGroupContactsPageOutPutSchema = z.infer<typeof GroupContactsPageOutPutSchema>


@Component({
  selector: 'app-group-contacts',
  templateUrl: './group-contacts.page.html',
  styleUrls: ['./group-contacts.page.scss'],
})
export class GroupContactsPage implements OnInit {
  showLoader: boolean;
  users = [];

  contact: string[] = [" Ana M.", "Andre F.", "Bruno G.", "Catarina T", "Tiago"];

  headers: HttpHeaders;
  options:any;
  listContacts: any[];
  contacts: any;
  textSearch:string;
  room:any;
  members:any;
  dm:any;
  isGroupCreated:boolean;
  groupName:string;
  selectedUserList:any;
  sessionStore = SessionStore


  objectUserSingleStone = []
  userContainer = {}
  roomId:string;
  allChatUsers: UserContacts[]  = [];
  currentMembers:UserContacts[];
  selectedUsers: number[] =[]
  get hasMemberToUpload() {
    return this.selectedUsers.length >= 1
  }

  constructor(
    private modalController: ModalController,
    public ThemeService: ThemeService,
    public chatServiceService: ChatServiceService,
    private navParams: NavParams,
    private httpErrorHandle: HttpErrorHandle,
    private contactsRepositoryService: ContactRepositoryService,
    private MemberListLocalRepository: MemberListLocalRepository,
    private toastService: ToastService,
  )
  {

    // this.textSearch="";
    // this.dm=null;
    // this.room=null;
    // this.isGroupCreated = this.navParams.get('isCreated');
    // this.groupName = this.navParams.get('name');
    this.room = this.navParams.get('roomId');
    // this.members = this.navParams.get('members');
  }

  ngOnInit() {
    // this.chatService.refreshtoken();
    this.loadUsers();
    // this.getMembers();


  }

  async loadUsers() {

    const getallChatUsers = await this.contactsRepositoryService.getUsers()

    if(getallChatUsers.isOk())  {

      this.allChatUsers = getallChatUsers.value.data.result.sort((a,b) => {
        if(a.wxFullName < b.wxFullName) {
          return -1;
        }
        if(a.wxFullName > b.wxFullName) {
          return 1;
        }
        return 0;
      });

      const currentMemberToMap =  await this.MemberListLocalRepository.getRoomMemberById(this.roomId)

      console.log({currentMemberToMap})

      this.currentMembers =  currentMemberToMap.map((e)=> ({
        userPhoto: e.userPhoto,
        wxeMail: e.wxeMail,
        wxFullName: e.wxFullName,
        wxUserId: e.wxUserId
      }))


      const currentMemberIds = this.currentMembers.map(e => e.wxUserId)

      const allSelectableUsers = this.allChatUsers.filter(e =>  !currentMemberIds.includes(e.wxUserId))

      for(const user of allSelectableUsers) {
        const firstLetter = user.wxFullName.charAt(0)

        if(!this.userContainer[firstLetter]) {
          user['isChecked'] = false
          this.userContainer[firstLetter] = [user as any]
        } else {
          const userIds = this.userContainer[firstLetter].map( e => e.wxUserId)
          if(!userIds.includes(user.wxUserId)) {
            user['isChecked'] = false
            this.userContainer[firstLetter].push(user as any)
          }
        }
      }


    } else if (getallChatUsers.isErr() && getallChatUsers.error instanceof HttpResponse) {
      this.httpErrorHandle.httpStatusHandle(getallChatUsers.error)
    } else {
      this.toastService._badRequest("Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico.")
    }


    this.showLoader = false;
  }


  async updateGroup() {

    if(this.hasMemberToUpload) {
      this.showLoader = true;
      const addMembers = await this.chatServiceService.addMemberToRoom({
        id: this.roomId,
        members: this.selectedUsers
      })


      if(addMembers.isOk()) {
        // this.addContacts(this.roomId);
        //this.openGroupMessage.emit(this.roomId);
        await this.chatServiceService.getRoomById(this.roomId);
        this.modalController.dismiss({roomId: this.roomId})
      } else if(addMembers.error instanceof HttpRequest) {
        this.httpErrorHandle.httpStatusHandle(addMembers.error)
      }
    } else {
      // this.openGroupMessage.emit(this.roomId);
      console.log('nothoing to add')
      this.modalController.dismiss({roomId: this.roomId})
    }

    this.showLoader = false;
  }

  getMembers(){

  }

  separateLetter(record, recordIndex, records){
    if(recordIndex == 0){
      return record.name[0];
    }

    let first_prev = records[recordIndex - 1].name[0];
    let first_current = record.name[0];

    if(first_prev != first_current){
      return first_current;
    }
    return null;
  }



  doRefresh(ev){
    this.loadUsers();
    //this.getMembers();
    ev.target.complete();
  }

  async close(){
    this.modalController.dismiss();
  }

  FilterUserListedByTextSearch() {
    const currentMemberIds = this.currentMembers.map(e => e.wxUserId)
    const allSelectableUsers = this.allChatUsers.filter(e =>  !currentMemberIds.includes(e.wxUserId))
    return allSelectableUsers.filter( e => e.wxFullName.toLowerCase().includes(this.textSearch.toLowerCase())).sort((a,b) => {
      if(a.wxFullName < b.wxFullName) {
        return -1;
      }
      if(a.wxFullName > b.wxFullName) {
        return 1;
      }
      return 0;
    });
  }


  onChangeCheckBox(user: UserContacts & {isChecked: boolean}) {
    console.log(user)

    if(user.isChecked) {

      this.selectedUsers.push(user.wxUserId)
    } else {
      this.selectedUsers = this.selectedUsers.filter(e => e!= user.wxUserId)
    }
  }

  onChange(event) {
    this.textSearch = event.detail.value;

    const filteredUserList: (UserContacts & {isChecked: boolean})[] = this.FilterUserListedByTextSearch() as any

    const userContainer = {}
    for(const user of filteredUserList) {
      const firstLetter = user.wxFullName.charAt(0)

      if(!userContainer[firstLetter]) {
        user.isChecked = this.selectedUsers.includes(user.wxUserId)
        userContainer[firstLetter] = [user]
      } else {
        user.isChecked = this.selectedUsers.includes(user.wxUserId)
        userContainer[firstLetter].push(user)
      }

    }
    this.userContainer = userContainer
  }


  async deleteMember(user: UserContacts) {
    this.showLoader = true;

    const result = await this.chatServiceService.removeMemberToRoom({
      id: this.roomId,
      members: [ user.wxUserId]
    })

    if(result.isOk()) {
      this.currentMembers = this.currentMembers.filter( e => e.wxUserId != user.wxUserId)

      const firstLetter = user.wxFullName.charAt(0)

      if(!this.userContainer[firstLetter]) {
        user['isChecked'] = false
        this.userContainer[firstLetter] = [user as any]
      } else {
        user['isChecked'] = false
        this.userContainer[firstLetter].push(user as any)
      }

    } else if(result.error instanceof HttpRequest) {
      this.httpErrorHandle.httpStatusHandle(result.error)
    } else if(result.error instanceof ZodError) {
      console.log(result.error.issues)
    }

    this.showLoader = false;

  }



}
