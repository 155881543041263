import { Injectable } from '@angular/core';
import { captureAndReraiseAsync } from 'src/app/services/decorators/captureAndReraiseAsync';
import { z } from "zod";
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { isHttpResponse } from 'src/app/infra/http/http.service';

export const DeleteRoomByIdInputDTOSchema =  z.string()
export type DeleteRoomByIdInputDTO = z.infer<typeof DeleteRoomByIdInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class DeleteRoomUseCaseService {

  constructor(
    private roomRemoteDataSourceService: IRoomRemoteRepository,
    // private roomMemoryDataSourceService: Store<RoomRemoteDataSourceState>,
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) { }


  @captureAndReraiseAsync('RoomRepositoryService/deleteRoomById')
  async execute(id: DeleteRoomByIdInputDTO) {
    const result = await this.roomRemoteDataSourceService.deleteRoom(id)

    if(result.isOk()) {

      const room = await this.roomLocalDataSourceService.findOne({
        id: id
      })

      if(room.isOk()) {
        const  result =  await this.roomLocalDataSourceService.delete(room.value.$id)
      }

      return result
    } else if (isHttpResponse(result.error)) {
      if(result.error.status == 404) {
        const room = await this.roomLocalDataSourceService.findOne({
          id: id
        })

        if(room.isOk()) {
          const  result =  await this.roomLocalDataSourceService.delete(room.value.$id)
        }
      }
      // this.httpErrorHandle.httpStatusHandle(result.error)
    }

    return result
  }
}
