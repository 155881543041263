import { Injectable } from '@angular/core';
import { MessageLocalDataSourceService } from '../../../../module/chat/data/repository/message/message-local-data-source.service';
import { MessageSocketRepositoryService } from '../../../../module/chat/data/repository/message/message-live-signalr-data-source.service';
import { InstanceId } from '../../../../module/chat/domain/chat-service.service';
import { MessageMapper } from '../../mapper/messageMapper';
import { v4 as uuidv4 } from 'uuid'
import { AttachmentLocalDataSource } from '../../../../module/chat/data/repository/attachment/attachment-local-repository.service';
import { RoomLocalRepository } from '../../../../module/chat/data/repository/room/room-local-repository.service';
import { MemberListLocalRepository } from 'src/app/module/chat/data/repository/member/member-list-local-repository.service'
import { Result } from 'neverthrow';
import { RoomType } from 'src/app/core/chat/entity/group';
import { MessageTable } from 'src/app/infra/database/dexie/instance/chat/schema/message';
import { MessageOutPutDataDTO } from 'src/app/core/chat/repository/dto/messageOutputDTO';
import { IDBoolean } from 'src/app/infra/database/dexie/type';
import { IMemberLocalRepository } from '../../repository/member/member-local-repository';
import { IMessageLocalRepository } from '../../repository/message/message-local-repository';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';
import { IRoomLocalRepository } from '../../repository/room/room-local-repository';
import { IAttachmentLocalRepository } from '../../repository/typing/typing-local-repository';

@Injectable({
  providedIn: 'root'
})
export class SendLocalMessagesUseCaseService {

  constructor(
    private MessageSocketRepositoryService: IMessageSocketRepository,
    private messageLocalDataSourceService: IMessageLocalRepository,
    private AttachmentRepositoryService: IAttachmentLocalRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
    private MemberListLocalRepository: IMemberLocalRepository,
    private messageSocketRepositoryService: IMessageSocketRepository,
  ) { }

  async execute() {

    const allRooms = await this.roomLocalDataSourceService.findAll()
    const messages = await this.messageLocalDataSourceService.getOfflineMessages()

    if(allRooms.isOk()) {
      if(allRooms.value.length == 0) {

      } else {
        //const removeLocalRooms = allRooms.value.filter((e)=> e.local == IDBoolean.false)

        // console.log({localM: messages});

        for(const message of messages) {
          const room = allRooms.value.find(e => e.id == message.roomId || e.$id == message.roomId)

          if(room) {

            const attachments =  await this.AttachmentRepositoryService.find({$messageId: message.$id})

            if(attachments.isOk()) {

              message.attachments = attachments.value.map(e => ({
                fileType: e.fileType,
                source: e.source,
                fileName: e.fileName,
                applicationId: e.applicationId,
                docId: e.docId,
                id: e.id,
                mimeType: e.mimeType,
                description: e.description,
                file: e.base64.split(',')[1]
              }))
              console.log('to upload', messages)
              const requestId = InstanceId +'@'+ uuidv4();

              await this.messageLocalDataSourceService.update(message.$id, { sending: true })

              let sendMessageResult: Result<MessageOutPutDataDTO, any>
              if(room.roomType == RoomType.Group) {
                const DTO = MessageMapper.fromDomain(message, message.requestId)
                sendMessageResult = await this.MessageSocketRepositoryService.sendGroupMessage(DTO)
              } else {

                const DTO = MessageMapper.fromDomain(message, message.requestId)
                sendMessageResult = await this.messageSocketRepositoryService.sendDirectMessage(DTO)

                if(sendMessageResult.isOk() && room.local == IDBoolean.true) {

                  this.roomLocalDataSourceService.update(room.$id, {
                    local: IDBoolean.false,
                    id: sendMessageResult.value.roomId
                  })
                  room.local = IDBoolean.false
                }

              }

              if(sendMessageResult?.isOk()) {

                if(sendMessageResult.value.sender == undefined || sendMessageResult.value.sender == null) {

                  delete sendMessageResult.value.sender
                }

                let clone: MessageTable = {
                  ...sendMessageResult.value,
                  id: sendMessageResult.value.id,
                  $id : message.$id
                }

                clone.sending = false
                console.log({clone})
                console.log('send message local '+ messages.length)

                this.messageLocalDataSourceService.update(message.$id, {id: sendMessageResult.value.id, sending: false})
              } else {
                this.messageLocalDataSourceService.update(message.$id, {sending: false})
              }
            }
          }

        }
      }

    }
  }

}
