import { Histogram, metrics } from '@opentelemetry/api';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import { MeterProvider, PeriodicExportingMetricReader } from '@opentelemetry/sdk-metrics';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// Initialize OpenTelemetry metrics
const meterProvider = new MeterProvider();
metrics.setGlobalMeterProvider(meterProvider);

if (window.location.protocol !== 'https:' && environment.apiURL != 'https://gdqas-api.oapr.gov.ao/api/') {
  // const metricReader = new PeriodicExportingMetricReader({
  //   exporter: new OTLPMetricExporter({
  //     //url: 'https://5-180-182-151.cloud-xip.com:85/collector2/v1/metrics',
  //     // url: 'https://185-229-224-75.cloud-xip.com:85/collector2/v1/metrics',
  //     //url: 'http://5-180-182-151.cloud-xip.com:4318/v1/metrics',
  //     //url: 'http://185-229-224-75.cloud-xip.com:4318/v1/metrics'
  //     // headers: {
  //     //   'Authorization': 'Basic ' + btoa('tabteste@006:tabteste@006'),
  //     // }
  //   }),
  //   exportIntervalMillis: 30000,
  // });

  // meterProvider.addMetricReader(metricReader);
}

export const meter = meterProvider.getMeter('example-exporter-collector');
export const RequestCounter = meter.createCounter('post_requests', {
  description: 'Example of a Counter',
});

export const UseCaseCounter = meter.createCounter('use_case', {
  description: 'use case counter',
});


// Assuming `meter` is your initialized Meter instance
export const useCaseDurationHistogram: Histogram = meter.createHistogram('use_case_duration', {
  description: 'Duration of use cases',
  unit: 'milliseconds',
});
